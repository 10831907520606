import { ROUTE_PATHS, SUPPORT_EMAIL } from '@tapestry/shared/constants';
import { Button } from '@tapestry/weave';
import Link from 'next/link';
import React, { Dispatch, FC, SetStateAction } from 'react';

export const CodeScannerProductNotFound: FC<{
  setProductNotFound: Dispatch<SetStateAction<boolean>>;
  handleGoBack: () => void;
}> = ({ setProductNotFound, handleGoBack }) => {
  return (
    <div className="space-y-12 mt-32 max-w-md">
      <div className="space-y-2">
        <h1 className="font bold text-lg">Your product could not be found</h1>

        <div>
          <p>
            Scan a different product or go to the search page to look it up by
            name, etc..
          </p>
        </div>
      </div>

      <div className="space-y-6">
        <div className="flex flex-col items-center space-y-2">
          <Button
            fullWidth
            status="primary"
            onClick={() => setProductNotFound(false)}
          >
            Restart scanner
          </Button>

          <Link href={ROUTE_PATHS.search} className="w-full">
            <Button fullWidth status="primary">
              Go to Search page
            </Button>
          </Link>

          <Link href={ROUTE_PATHS.heartbeat} className="w-full">
            <Button fullWidth status="secondary">
              Go to Real-time Analytics
            </Button>
          </Link>
          <Button fullWidth status="secondary" onClick={handleGoBack}>
            Return to previous page
          </Button>
        </div>
        <p>
          If you think this is a error, feel free to contact{' '}
          <a
            href={`mailto:${SUPPORT_EMAIL}`}
            className="text-primary underline"
          >
            support
          </a>
        </p>
      </div>
    </div>
  );
};
