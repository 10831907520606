import { ROUTE_PATHS, SHORTCUT } from '@tapestry/shared/constants';
import { useKeyboardShortcut } from '@tapestry/shared/hooks';
import { useRouter } from 'next/router';
import { FC } from 'react';

export const KeyboardShortcutsAppWide: FC<
  React.PropsWithChildren<{ children: any }>
> = ({ children }) => {
  const router = useRouter();

  const keyboardShorcutsHandlers = {
    [SHORTCUT.APP.VIEWS.HEARTBEAT]: () => router.push(ROUTE_PATHS.heartbeat),
    [SHORTCUT.APP.VIEWS.TASKS]: () => router.push(ROUTE_PATHS.tasks),
    [SHORTCUT.APP.VIEWS.PRODUCTS]: () => router.push(ROUTE_PATHS.products),
    [SHORTCUT.APP.VIEWS.LISTS]: () => router.push(ROUTE_PATHS.lists),
    [SHORTCUT.APP.VIEWS.DEPARTMENTS]: () =>
      router.push(ROUTE_PATHS.departments),
    [SHORTCUT.APP.VIEWS.MANUFACTURERS]: () =>
      router.push(ROUTE_PATHS.manufacturers),
    [SHORTCUT.APP.VIEWS.SPACES]: () => router.push(ROUTE_PATHS.spaces),
    [SHORTCUT.APP.VIEWS.CATEGORIES]: () => router.push(ROUTE_PATHS.categories),
    [SHORTCUT.APP.VIEWS.SUPPLIERS]: () => router.push(ROUTE_PATHS.suppliers),
  };

  useKeyboardShortcut(keyboardShorcutsHandlers);

  return children;
};
