import { ListItemShell } from '@tapestry/weave';
import { Transition } from '@headlessui/react';
import Skeleton from 'react-loading-skeleton';

interface ISkeletonProps {
  count?: number;
}

export const TaskListItemSkeleton = ({ count = 1 }: ISkeletonProps) => {
  const nOfSkeleton = Array(count).fill('skeleton');

  return (
    <Transition
      appear={true}
      show={true}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      as="ul"
      aria-label="loading"
    >
      {nOfSkeleton.map((each, idx) => (
        <ListItemShell key={`${each}-${idx}`}>
          <div className="mt-4 sm:mt-0 sm:flex sm:items-center">
            <div className="hidden sm:flex sm:items-center">
              <Skeleton circle={true} height={50} width={50} className="mr-4" />
              <Skeleton circle={true} height={50} width={50} className="mr-4" />
            </div>

            <h1 className="">
              <Skeleton height={30} width={300} />
            </h1>
          </div>

          <div className="mt-4 flex items-center justify-end sm:mt-0 sm:justify-between">
            <Skeleton circle={true} height={50} width={50} className="mr-4" />
            <Skeleton circle={true} height={50} width={50} className="mr-4" />
            <Skeleton height={50} width={20} />
          </div>
        </ListItemShell>
      ))}
    </Transition>
  );
};

export default TaskListItemSkeleton;
