import React from 'react';
import MoreButtonEllipsis from '../more-button-ellipsis/MoreButtonEllipsis';
import {
  AnalyseButton,
  Circle,
  Currency,
  InfoPill,
  Number as NumberComponent,
  useAppMediaQuery,
} from '@tapestry/weave';
import {
  DealsIcon,
  DollarIcon,
  ImageIcon,
  MessageIcon,
  ShopIcon,
  WarehouseIcon,
} from '@tapestry/shared/icons';
import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { THREAD_TYPE, Twist } from '@tapestry/types';
import { dateTime, getTwistByKey } from '@tapestry/shared/utils';
import { useModal, useURIReturnPath } from '@tapestry/shared/hooks';
import Skeleton from 'react-loading-skeleton';
import { ProductInShopsModal } from './ProductInShopsModal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  calculateTotalQuantityInGroup,
  concatUnitSizeAndMeasure,
  filterByCurrentlyStocking,
  highestPriceInGroup,
  IProductListItemProps,
} from './product-list-item-utils';
import Link from 'next/link';

const ProductListItem: React.FC<
  React.PropsWithChildren<IProductListItemProps>
> = ({ item, isLoading = false, asDiv = false, onMessageButtonClick }) => {
  const { isPhone } = useAppMediaQuery();
  const returnToPath = useURIReturnPath();
  const {
    threadSummary: hasThreadSummaryFlag,
    tempRetailProductListItemDeal: hasRetailProductListItemDealFlag,
  } = useFlags();
  const shouldShowShopsModal = useModal();

  if (!item) return null;

  const { title, id } = item;
  const ELLIPSIS_MENU_ITEMS = [];

  const sku = getTwistByKey(Twist.SKU, item)?.value ?? null;
  const quantity = getTwistByKey(Twist.StockOnHand, item)?.value ?? null;
  const sellPrice = getTwistByKey(Twist.UnitPrice, item)?.value ?? null;

  const measuringUnit = concatUnitSizeAndMeasure(item);

  const productInShopsList =
    'inShops' in item ? filterByCurrentlyStocking(item?.inShops) : null;

  const groupQuantity = productInShopsList
    ? calculateTotalQuantityInGroup(productInShopsList)
    : null;

  const groupSellPrice = productInShopsList?.length
    ? highestPriceInGroup(productInShopsList)
    : null;

  const shouldDisableLink =
    hasThreadSummaryFlag && !asDiv ? '' : 'pointer-events-none cursor';

  const currentWeek = dateTime.getCurrentIsoWeek();
  const qpDateString = `startDate=${encodeURIComponent(
    currentWeek.startDate
  )}&endDate=${encodeURIComponent(currentWeek.endDate)}`;

  return (
    <>
      <li
        className={`focus-within:ring-primary flex list-none overflow-hidden rounded-md bg-white focus-within:ring-1`}
      >
        <Link
          href={ROUTE_PATHS.build.threadSummary(id)}
          aria-label={title || 'N/A'}
          aria-describedby="sr-product-link-description"
          className="h-auto w-16 flex-shrink-0 md:w-32"
        >
          <div className="flex h-full items-center justify-center bg-gray-200">
            <ImageIcon className="h-8 w-8 md:h-12 md:w-12" fillColor="#ddd" />
          </div>
        </Link>
        <div className="flex-grow text-left sm:flex sm:items-center sm:justify-between">
          <div className="flex">
            <Link
              href={ROUTE_PATHS.build.threadSummary(id)}
              aria-label={title || 'N/A'}
              aria-describedby="sr-product-link-description"
              className={`block flex-auto p-4 ${shouldDisableLink}`}
            >
              <p id="sr-product-link-description" className="sr-only">
                If clicked, you will be redirected to the thread view for this
                product
              </p>
              {/* add  pt-1 to compensate the offset of the AnalyseButton  */}
              <h6 className="flex items-center justify-between text-base font-bold md:text-lg pt-1 md:pt-0">
                {isLoading ? (
                  <Skeleton width={isPhone ? 100 : 300} />
                ) : (
                  title || 'N/A'
                )}
                <span className="sm:hidden">
                  <MoreButtonEllipsis items={ELLIPSIS_MENU_ITEMS} />
                </span>
              </h6>
              <p className="text-gray-text mt-2 text-sm sm:mt-1">
                {measuringUnit}
                {sku ? ` - SKU: ${sku}` : ''}
              </p>
            </Link>
            <Link
              title="View this product in Heartbeat"
              href={ROUTE_PATHS.build.heartbeat({
                threadType: THREAD_TYPE.PRODUCT,
                qp: `productId=${id}&${qpDateString}&returnTo=${returnToPath}`,
              })}
              className="pl-0 p-4 sm:hidden"
            >
              <AnalyseButton variant="icon" />
            </Link>
          </div>

          <div className="mt-4 ml-2 flex flex-shrink-0 flex-wrap items-center justify-start md:justify-end gap-2 p-4 pl-0 sm:mt-0">
            {isLoading ? (
              <Skeleton circle height={40} width={40} />
            ) : (
              <>
                {productInShopsList?.length ? (
                  <button
                    type="button"
                    onClick={shouldShowShopsModal.open}
                    onKeyDown={({ key }) => {
                      if (key === 'Enter') {
                        shouldShowShopsModal.open();
                      }
                    }}
                  >
                    <InfoPill
                      icon={ShopIcon}
                      title="Shop availability"
                      tooltipMessage="Shop availability"
                    >
                      {productInShopsList?.length}
                    </InfoPill>
                  </button>
                ) : null}

                {hasRetailProductListItemDealFlag ? (
                  <Link href={ROUTE_PATHS.build.deal(id)}>
                    <InfoPill
                      icon={DealsIcon}
                      title={`${title} - deal`}
                      tooltipMessage="On Deal (Click for more information)"
                      iconColor="text-white"
                      backgroundColor="bg-primary"
                    >
                      30%
                    </InfoPill>
                  </Link>
                ) : null}

                {groupSellPrice !== null && (
                  <InfoPill
                    icon={DollarIcon}
                    title="Group sell price"
                    tooltipMessage="Current sell price"
                  >
                    <Currency
                      value={groupSellPrice}
                      maximumFractionDigits={2}
                    />
                  </InfoPill>
                )}

                {sellPrice !== null && !groupSellPrice && (
                  <InfoPill
                    icon={DollarIcon}
                    title="Current sell price"
                    tooltipMessage="Current sell price"
                  >
                    <Currency
                      value={Number(sellPrice)}
                      maximumFractionDigits={2}
                    />
                  </InfoPill>
                )}

                {groupQuantity !== null && (
                  <InfoPill
                    icon={WarehouseIcon}
                    title="Group stock on hand"
                    tooltipMessage="Stock on hand"
                  >
                    <NumberComponent
                      value={groupQuantity}
                      maximumFractionDigits={0}
                    />
                  </InfoPill>
                )}

                {quantity !== null && groupQuantity === null && (
                  <InfoPill
                    icon={WarehouseIcon}
                    title="Stock on hand"
                    tooltipMessage="Stock on hand"
                  >
                    <NumberComponent
                      value={quantity}
                      maximumFractionDigits={0}
                    />
                  </InfoPill>
                )}

                {onMessageButtonClick ? (
                  <button className="group" onClick={onMessageButtonClick}>
                    <Circle
                      size="h-8 w-8 p-2"
                      className="border-none bg-gray-200 transition-colors duration-150 ease-out group-hover:group-focus:bg-gray-300"
                    >
                      <p className="sr-only">Open Message</p>
                      <MessageIcon />
                    </Circle>
                  </button>
                ) : null}
                <Link
                  title="View this product in Heartbeat"
                  href={ROUTE_PATHS.build.heartbeat({
                    threadType: THREAD_TYPE.PRODUCT,
                    qp: `productId=${id}&${qpDateString}&returnTo=${returnToPath}`,
                  })}
                >
                  <span className="hidden sm:inline">
                    <span className="sr-only">View in Heartbeat</span>
                    <AnalyseButton />
                  </span>
                </Link>
              </>
            )}
            <div className="hidden sm:block">
              <MoreButtonEllipsis items={ELLIPSIS_MENU_ITEMS} />
            </div>
          </div>
        </div>
      </li>

      {shouldShowShopsModal.isOpen && (
        <ProductInShopsModal
          product={item}
          shops={productInShopsList}
          groupSellPrice={groupSellPrice}
          groupQuantity={groupQuantity}
          modalState={shouldShowShopsModal}
          returnToPath={`&returnTo=${returnToPath}`}
          qpDateString={qpDateString}
        />
      )}
    </>
  );
};

export { ProductListItem };
