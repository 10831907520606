import * as React from 'react';
import { CloseIcon, TickIcon } from '@tapestry/shared/icons';
import { twMerge } from 'tailwind-merge';

interface ITaskCompletionRadioGroupProps {
  completionValue: boolean | null;
  onTaskStatusChange: (value: boolean | null) => void;
}

const TaskCompletionRadioGroup: React.FC<
  React.PropsWithChildren<ITaskCompletionRadioGroupProps>
> = ({ completionValue, onTaskStatusChange }) => {
  const handleTaskStatusChange = (statusValue: boolean) => () => {
    onTaskStatusChange(completionValue === statusValue ? null : statusValue);
  };

  return (
    <div className="flex-shrink-0">
      <button
        title="Toggle completion"
        aria-label="done"
        type="button"
        onClick={handleTaskStatusChange(true)}
        className={twMerge(
          'mr-4 inline-flex h-8 w-8 items-center justify-center rounded-full',
          'border-gray-border border p-1 text-white transition-colors duration-150 ease-in',
          'hover:border-green hover:text-green focus:border-green',
          'active:bg-green focus:outline-none sm:h-10 sm:w-10 sm:p-2',
          completionValue
            ? 'bg-green text-white focus:text-white'
            : 'focus:text-green bg-white text-gray-300'
        )}
      >
        <TickIcon fillColor="currentColor" light />
      </button>

      <button
        type="button"
        title="Toggle failure to complete"
        aria-label="Toggle failure to complete"
        className={twMerge(
          'inline-flex h-8 w-8 items-center justify-center rounded-full border',
          'border-gray-border p-1 transition-colors duration-150 ease-in',
          'hover:border-red hover:text-red focus:border-red focus:outline-none sm:h-10 sm:w-10 sm:p-2',
          completionValue === false
            ? 'bg-red text-white focus:text-white'
            : 'focus:text-red bg-white text-gray-300'
        )}
        onClick={handleTaskStatusChange(false)}
      >
        <CloseIcon fillColor="currentColor" light />
      </button>
    </div>
  );
};

export { TaskCompletionRadioGroup };
