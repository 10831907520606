export const USER_ERROR_MESSAGES = {
  heartbeat: {
    fetchShop: "Sorry, couldn't get your shops",
    fetchDepartments: "Sorry, couldn't get your shop's department",
    metrics: {
      fetchTopDepartments:
        'Sorry, something happened getting your top departments for this metric',
      fetchTopProducts:
        'Sorry, something happened getting your top products for this metric',
      fetchTopCategories:
        'Sorry, something happened getting your top categories for this metric',
      fetchTopSpaces:
        'Sorry, something happened getting your top spaces for this metric',
      fetchBestTimes:
        'Sorry, something happened getting your best times for this metric',
      fetchAllCategories:
        "Sorry, something happened getting your shop's top departments",
      fetchAllProducts:
        "Sorry, something happened getting your shop's top departments",
    },
  },
};
