export enum Contract {
  DueDate = 'due_date',
  Shop = 'shop',
  Department = 'department',
  Assignable = 'assignable',
  CompletedTask = 'completed_task',
  Reminder = 'reminder',
  Products = 'products',
  Tags = 'tags',
  Attachment = 'attachments',
  Owner = 'owner',
}
