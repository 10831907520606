import { ReactNode } from 'react';
import { Url } from 'url';
import { Visualization } from './visualizations';
import { AppName } from '../shared/app-config';
import { THREAD_TYPE } from '../thread/thread-type';
import { IsoString } from '../utils-type/iso-string';

export enum AGGREGATION_TYPE {
  SUM = 'sum',
  AVERAGE = 'average',
}

export type MetricUnit = 'AUD$' | '%' | '' | '$';
export type MetricUnitPosition = 'left' | 'right';

export enum MeasureSlug {
  TotalSales = 'total_sales',
  Transactions = 'transactions',
  UnitsSold = 'units_sold',
  Margin = 'margin',
  Profitability = 'profitability',
  BasketValue = 'basket_value',
  BasketProfit = 'basket_profit',
  BasketMargin = 'basket_margin',
  AvgBasketContribution = 'avg_basket_contribution',
  AvgBasketContributionValue = 'avg_basket_contribution_value',
  OnPromotion = 'on_promotion',
  PromotionalMixOfSales = 'promotional_mix_of_sales',
  CostPrice = 'cost_price',
  TotalCostPrice = 'total_cost_price',
  SalesPerSq = 'sales_per_sq',
  TotalWageCost = 'total_wage_cost',
  TotalWageHours = 'total_wage_hours',
  SalesPerLabourHour = 'sales_per_labour',
  Satisfaction = 'satisfaction',
  StockLevel = 'stock_level',
  ZeroStockItems = 'zero_stock_items',
  ReturnInvestment = 'return_investment',
  AvgInventoryLvl = 'avg_inventory_lvl',
  AvgDaysToSell = 'avg_days_to_sell',
  LostSales = 'lost_sales',
  StockTurn = 'stock_turn',
  SellThroughRate = 'sell_through_rate',
  Shrinkage = 'shrinkage',
  StockProfitOffset = 'stock_profit_offset',
  Volume = 'volume',
  SeasonalityIdx = 'seasonality_idx',
  RetailSalesValue = 'retail_sales_value',
  WeeksOnHand = 'weeks_on_hand',
  WeightedAvgMarketPrice = 'weighted_avg_market_price',
  PriceElasticityIdx = 'price_elasticity_idx',
  RecommendedPrice = 'recommended_price',
  RecommendedOrderQty = 'recommended_order_qty',
  CaptureRate = 'capture_rate',
  ReturningVisitor = 'returning_visitor',
  AvgWeeklySales = 'avg_weekly_sales',
  PercentageOfContribution = 'percent_of_contribution',
  SupplierMarketShare = 'supplier_market_share',
  // ...
}
export const MEASURE_SLUGS: MeasureSlug[] = [
  MeasureSlug.TotalSales,
  MeasureSlug.Transactions,
  MeasureSlug.UnitsSold,
  MeasureSlug.Margin,
  MeasureSlug.Profitability,
  MeasureSlug.BasketValue,
  MeasureSlug.BasketProfit,
  MeasureSlug.BasketMargin,
  MeasureSlug.AvgBasketContribution,
  MeasureSlug.AvgBasketContributionValue,
  MeasureSlug.OnPromotion,
  MeasureSlug.PromotionalMixOfSales,
  MeasureSlug.CostPrice,
  MeasureSlug.TotalCostPrice,
  MeasureSlug.SalesPerSq,
  MeasureSlug.TotalWageCost,
  MeasureSlug.TotalWageHours,
  MeasureSlug.SalesPerLabourHour,
  MeasureSlug.Satisfaction,
  MeasureSlug.StockLevel,
  MeasureSlug.ZeroStockItems,
  MeasureSlug.ReturnInvestment,
  MeasureSlug.AvgInventoryLvl,
  MeasureSlug.AvgDaysToSell,
  MeasureSlug.LostSales,
  MeasureSlug.StockTurn,
  MeasureSlug.SellThroughRate,
  MeasureSlug.Shrinkage,
  MeasureSlug.StockProfitOffset,
  MeasureSlug.Volume,
  MeasureSlug.SeasonalityIdx,
  MeasureSlug.RetailSalesValue,
  MeasureSlug.WeeksOnHand,
  MeasureSlug.WeightedAvgMarketPrice,
  MeasureSlug.PriceElasticityIdx,
  MeasureSlug.RecommendedPrice,
  MeasureSlug.RecommendedOrderQty,
  MeasureSlug.CaptureRate,
  MeasureSlug.ReturningVisitor,
  MeasureSlug.AvgWeeklySales,
  MeasureSlug.PercentageOfContribution,
  MeasureSlug.SupplierMarketShare,
];

export interface IMetric {
  key: MeasureSlug;
  title: string;
  altTitle?: string;
  altTitleCondition?: (
    activeThread: THREAD_TYPE,
    activeMeasure: IMetric['key']
  ) => boolean;
  mainLabel: string;
  unit?: { symbol: MetricUnit; position?: MetricUnitPosition };
  helperText?: string;
  icon: ReactNode;
  aggregationType: AGGREGATION_TYPE;
  variants?: { label: string; mobileLabel?: string; slug: string }[];
  enableFor?:
    | THREAD_TYPE[]
    | ((
        thread: THREAD_TYPE,
        Visualization: Visualization | null,
        variables?: { isGroupView?: boolean; app?: AppName } // any future variable
      ) => Visualization[] | null | boolean);
  disabled?: boolean;
  /**
   * Disables clicking on the metric but still enables the metric summary to be shown on the tile
   */
  readonly?: boolean;
  /**
   * `true` will available for all thread types. Otherwise specify which thread type
   */
  isExportable?: boolean | THREAD_TYPE[];
  hankUniversityLink?: Url | string;
  dimensionsLabels?: {
    topsSpaces?: string;
    topDepartments?: string;
    topProducts?: string;
    topCategories?: string;
    bestTimes?: string;
  };
  zeroDataBeforeDate?: IsoString | string;
}
