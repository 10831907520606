import {
  Button,
  FormTextArea,
  FormTextInput,
  FormInputBase,
  ModalState,
  Modal,
  Stack,
} from '@tapestry/weave';
import { FC, useRef, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { IUserProfile } from '@tapestry/shared/graphql';
import { ErrorBoundary, captureMessage, captureFeedback } from '@sentry/nextjs';
import { SendFeedbackParams } from '@sentry/types';
import isEmpty from 'lodash/isEmpty';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Transition } from '@headlessui/react';
import {
  handleAddAttachmentToScope,
  processFileToUint8Array,
} from './user-feedback-utils';
import { FileList } from './FileList';

interface UserFeedbackModalProps {
  modalState: ModalState;
  profile: Maybe<IUserProfile>;
  makeThemPop: () => void;
}

type UserFeedbackForm = Omit<SendFeedbackParams, 'associatedEventId'>;

export const UserFeedbackModal: FC<UserFeedbackModalProps> = ({
  modalState,
  profile,
  makeThemPop,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isScreenshooting, setIsScreenshooting] = useState(false);
  const [fileList, setFileList] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { register, handleSubmit } = useForm<UserFeedbackForm>({
    defaultValues: {
      name: profile?.first_name
        ? `${profile?.first_name} ${profile?.last_name}`
        : '',
      email: profile?.email ?? '',
      message: '',
    },
  });

  const onSubmit: SubmitHandler<UserFeedbackForm> = async (data) => {
    setIsLoading(true);

    const promisedUIntArray = fileList.map((file) => {
      return processFileToUint8Array(file);
    });

    const UInt8Array = await Promise.all(promisedUIntArray);

    const eventId = captureMessage('User Feedback', (scope) =>
      handleAddAttachmentToScope(scope, fileList, UInt8Array)
    );

    captureFeedback({
      associatedEventId: eventId,
      ...data,
    });

    setIsSubmitted(true);
    setIsLoading(false);
    makeThemPop();
  };

  const handleClickAsFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSetFiles = (event) => {
    const selectedfiles = event.target.files;
    const fileAsArray = Object.values(selectedfiles) as File[];

    setFileList((fileList) => [...fileAsArray, ...fileList]);
  };

  const handleRemoveFileFromList = (file) => {
    setFileList((fileList) =>
      fileList.filter((f) => f.lastModified !== file.lastModified)
    );
  };

  const handleCaptureScreenshot = async () => {
    setIsScreenshooting(true);
    const html2canvas = (await import('html2canvas')).default;

    // * By using the `_next` id, we can bypass the modal and only capture what is below it
    const screenshotTarget = document.getElementById('__next') || document.body;

    html2canvas(screenshotTarget).then((canvas) => {
      canvas.toBlob((blob) => {
        if (!blob) return;

        const file = new File([blob], 'screenshot.jpg', {
          type: 'image/jpeg',
          lastModified: new Date().getTime(),
        });

        setFileList((fileList) => [...fileList, file]);
        setIsScreenshooting(false);
      }, 'image/jpeg');
    });
  };

  return (
    <ErrorBoundary>
      <Modal state={modalState}>
        <Modal.Dialog>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Content>
              <Modal.Title>Submit Feedback</Modal.Title>

              {isSubmitted ? (
                <Transition
                  as="div"
                  appear
                  show={isSubmitted}
                  enter="transition-opacity duration-1000"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Modal.Description>
                    Feedback received! Muchos Gracias amigo.
                    <br /> We will be in touch shortly{' '}
                    <span role="img" aria-label="Smiley face">
                      😊
                    </span>
                  </Modal.Description>
                </Transition>
              ) : (
                <>
                  <Modal.Description>
                    We are always looking to improve. Please let us know what
                    you would like to see in this app.
                  </Modal.Description>

                  <Stack spacing="small">
                    <FormTextInput
                      label="name"
                      {...register('name')}
                      placeholder="John Elmant"
                    />
                    <FormTextInput
                      label="email"
                      {...register('email')}
                      placeholder="john@email.com"
                    />
                    <FormTextArea
                      label="comments"
                      {...register('message', { required: true })}
                      placeholder="I'd love to see a option to do..."
                    />
                    <FormInputBase name="image_uploads" label="Upload files">
                      <div className="flex flex-col items-center space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0">
                        <Button
                          spacing="small"
                          status="basic"
                          disabled={isScreenshooting}
                          onClick={handleClickAsFileInput}
                        >
                          Pick files to upload
                        </Button>
                        <span>or</span>
                        <Button
                          spacing="small"
                          status="basic"
                          loading={isScreenshooting}
                          onClick={handleCaptureScreenshot}
                        >
                          Take a screenshot
                        </Button>
                      </div>
                      {/* input hidden as hard to style */}
                      <input
                        ref={fileInputRef}
                        type="file"
                        id="image_uploads"
                        name="image_uploads"
                        accept="image/*"
                        multiple
                        className="opacity-0"
                        onChange={handleSetFiles}
                      />
                      {!isEmpty(fileList) && (
                        <FileList
                          fileList={fileList}
                          handleRemoveFileFromList={handleRemoveFileFromList}
                        />
                      )}
                    </FormInputBase>
                  </Stack>
                </>
              )}
            </Modal.Content>

            <Modal.Footer>
              {isSubmitted ? (
                <Modal.Button type="button" onClick={modalState.close}>
                  Close
                </Modal.Button>
              ) : (
                <Modal.Button
                  disabled={isScreenshooting}
                  loading={isLoading}
                  type="submit"
                  status="primary"
                >
                  Submit Feedback
                </Modal.Button>
              )}
            </Modal.Footer>
          </form>
        </Modal.Dialog>
      </Modal>
    </ErrorBoundary>
  );
};
