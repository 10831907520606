import React, { FC, ReactNode } from 'react';
import Stack from '../stack/Stack';
import { Label } from '../label/Label';
import { Transition } from '@headlessui/react';

export interface IFormInputBaseProps {
  name: string;
  label: string;
  sublabel?: string;
  error?: string;
  labelSROnly?: boolean;
  icon?: ReactNode;
  // Used with Downshift library
  getLabelProps?: (options?: React.HTMLProps<HTMLLabelElement>) => any;
}

export const FormInputBase: FC<
  React.PropsWithChildren<IFormInputBaseProps>
> = ({
  name,
  label,
  sublabel,
  error,
  children,
  labelSROnly = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getLabelProps = () => {},
  icon,
}) => {
  return (
    <div className="text-left">
      {labelSROnly ? (
        <label className="sr-only" htmlFor={name}>
          {label}
        </label>
      ) : (
        <Stack spacing="xsmall">
          <Label htmlFor={name} {...getLabelProps()}>
            <span className="flex items-center">
              {icon ? (
                <span className="mr-2 flex size-6 flex-shrink-0 place-content-center">
                  {icon}
                </span>
              ) : null}

              {label}
            </span>
          </Label>

          {sublabel && (
            <p className="text-gray-text text-left text-sm">{sublabel}</p>
          )}
        </Stack>
      )}

      <div className={labelSROnly ? '' : 'mt-4'}>{children}</div>

      {error ? (
        <Transition
          appear
          show={!!error}
          enter="transition-opacity duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className=" text-red mt-2 text-sm"
          as="p"
          role="alert"
        >
          {error}
        </Transition>
      ) : null}
    </div>
  );
};
