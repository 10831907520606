import { useCallback, useReducer, Dispatch, KeyboardEvent } from 'react';

type ISortMenuItemType = {
  id: string;
  label: string;
  shorthand: string;
  onclick?: () => void;
};

export enum DefaultSortingKey {
  Az = 'a_z',
  Za = 'z_a',
  ValueAsc = 'value_asc',
  ValueDesc = 'value_desc',
}

export type UseSortMenuSortAction<type = DefaultSortingKey> = { type: type };
export type UseSortMenuOpenCloseAction = {
  type: 'UPDATE_SORT_ORDER';
  payload: string;
};

type SortMenuState = {
  sortBy: ISortMenuItemType['id'];
};

const initialState: SortMenuState = {
  sortBy: DefaultSortingKey.ValueDesc,
};

const getInitialState = (
  defaultItem: string = DefaultSortingKey.ValueDesc
): SortMenuState => ({
  sortBy: defaultItem,
});

const sortReducer = (
  state = initialState,
  action: UseSortMenuSortAction | UseSortMenuOpenCloseAction
): SortMenuState => {
  const { type } = action;

  switch (type) {
    case 'UPDATE_SORT_ORDER':
      return {
        ...state,
        sortBy: action.payload,
      };

    case DefaultSortingKey.Az:
      return {
        ...state,
        sortBy: DefaultSortingKey.Az,
      };

    case DefaultSortingKey.Za:
      return {
        ...state,
        sortBy: DefaultSortingKey.Za,
      };

    case DefaultSortingKey.ValueAsc:
      return {
        ...state,
        sortBy: DefaultSortingKey.ValueAsc,
      };

    case DefaultSortingKey.ValueDesc:
      return {
        ...state,
        sortBy: DefaultSortingKey.ValueDesc,
      };

    default:
      return state;
  }
};

const makeSortMenuItems = (
  dispatch: Dispatch<UseSortMenuSortAction>,
  onChange?: (value: DefaultSortingKey) => void
) => {
  const onClickHandler = (sortingKey: DefaultSortingKey) => () => {
    if (onChange) {
      onChange(sortingKey);
    }

    dispatch({ type: sortingKey });
  };

  return [
    {
      label: 'Highest Value First',
      shorthand: 'Highest First',
      onclick: onClickHandler(DefaultSortingKey.ValueDesc),
      id: DefaultSortingKey.ValueDesc,
    },
    {
      label: 'Lowest Value First',
      shorthand: 'Lowest First',
      onclick: onClickHandler(DefaultSortingKey.ValueAsc),
      id: DefaultSortingKey.ValueAsc,
    },
    {
      label: 'Alphabetical A > Z',
      shorthand: 'A > Z',
      onclick: onClickHandler(DefaultSortingKey.Az),
      id: DefaultSortingKey.Az,
    },
    {
      label: 'Alphabetical Z > A',
      shorthand: 'Z > A',
      onclick: onClickHandler(DefaultSortingKey.Za),
      id: DefaultSortingKey.Za,
    },
  ];
};

interface IUseSortMenuProps {
  items?: ISortMenuItemType[];
  defaultItem?: string;
  sortReducer?;
  itemsMergePolicy?: 'override' | 'extend';
  onChange?: (value: ISortMenuItemType['id']) => void;
}

interface IUseSortMenuReturnType {
  items: ISortMenuItemType[];
  getItemProps: (itemConfig: { item: ISortMenuItemType }) => {
    onClick: () => void;
    onKeyDown: (e: KeyboardEvent<HTMLButtonElement>) => void;
  };
  state: SortMenuState & { activeItem: ISortMenuItemType | undefined };
}

/**
 * Provides the logic to create a sort menu
//  * TODO WIP
 */
export const useSortMenu = (
  config?: IUseSortMenuProps
): IUseSortMenuReturnType => {
  const [state, dispatch] = useReducer(
    sortReducer,
    getInitialState(config?.defaultItem)
  );

  const SORT_MENU_ITEMS =
    config?.items || makeSortMenuItems(dispatch, config?.onChange);
  const activeItem = SORT_MENU_ITEMS.find((item) => item.id === state.sortBy);

  const getItemProps = useCallback(
    (itemConfig: { item: ISortMenuItemType }) => {
      const { item } = itemConfig;

      return {
        onClick: () => {
          if (config?.onChange) {
            config.onChange(item.id);
          }

          dispatch({ type: 'UPDATE_SORT_ORDER', payload: item.id });
        },
        onKeyDown: ({ key }) => {
          if (key === 'Enter') {
            dispatch({ type: 'UPDATE_SORT_ORDER', payload: item.id });
          }
        },
      };
    },
    []
  );

  return {
    items: SORT_MENU_ITEMS,
    state: { ...state, activeItem },
    getItemProps,
  };
};
