import { IPermissionGateProps } from '@tapestry/types';
import { hasPermissions } from '@tapestry/shared/utils';
import useDevMode from '../use-dev-mode/use-dev-mode';

/**
 * Hook to check permissions (use generally with feature flag)
 *
 * @param {Array} scopes - an array of scope for a feature
 * @return {Array} an array of booleans for each scope
 */

const useHasPermissions = (
  ...arg: [
    IPermissionGateProps['scopes'] | null,
    ...Array<IPermissionGateProps['scopes'] | null>
  ]
): Array<boolean> => {
  const [isDevMode] = useDevMode();

  return arg.map((scopes) => hasPermissions(scopes, isDevMode));
};

export { useHasPermissions };
