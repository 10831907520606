import { IsoStringWithTZOffset } from '../utils-type/iso-string';

export interface IComparisonQueryVariable {
  measureCompare: string | null;
  startDateCompare: string;
  endDateCompare: string;
  compareFilter?:
    | {
        shopId: string;
      }
    | {
        groupId: string;
      }
    | {
        listId: string;
      };
}
export enum HearbeatComparisonKey {
  Period = 'period',
  Measure = 'measure',
  ShopId = 'shopId',
  GroupId = 'groupId',
  ListId = 'listId',
}

export enum HeartbeatComparisonPeriod {
  Year = 'year',
  Week = 'week',
  UserDefined = 'userDefined',
}

export type HeartbeatComparisonQueriesVariable = {
  key: HearbeatComparisonKey;
  value: string;
  meta?: undefined | [IsoStringWithTZOffset, IsoStringWithTZOffset];
};
