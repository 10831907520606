import React, { ReactNode } from 'react';
import { Transition } from '@headlessui/react';
import Stack from '../stack/Stack';
import { twMerge } from 'tailwind-merge';
import { useOnClickOutside } from '../hooks/useOnClickOutside';

type Position = 'top-right' | 'top-center' | 'top-left';
interface IDropdownMenuProps {
  show: boolean;
  menuClassName?: string;
  children: ReactNode;
  position?: Position;
  fullWidth?: boolean;
  noTopMargin?: boolean;
  clickOutsideRef?: any;
  anchorElementRef: React.MutableRefObject<HTMLElement | null>;
  onClickOutside?: (event: Event) => void;
}

const getDropdownPosition = (position: Position | undefined) => {
  switch (position) {
    case 'top-right':
      return 'origin-top-right right-0';

    case 'top-left':
      return 'origin-top-left left-0';

    default:
      return 'origin-top-right right-0';
  }
};

/**
 * Renders UI for a floating dropdown panel
 *
 * @param show - `boolean` shows the menu or not
 * @param position - `top-right` - gets the hanging position of the menu (add more if needed)
 * @param menuClassName - tailwind class string to further style the menu
 * @param fullWidth - makes the menu full-width, generally used for mobile
 * @param noTopMargin - reduce default top margin to 0, effectively stucking the menu under it's sibling element
 * @param clickOutsideRef - pass a ref to close the menu;
 * @param onClickOutside - pass the click outside click function
 * @param anchorElement - a refence to an element which open a dropdown menu ;
 *
 */
export const DropdownMenu: React.VFC<IDropdownMenuProps> = ({
  show = false,
  menuClassName,
  children,
  position = 'top-right',
  fullWidth,
  noTopMargin,
  clickOutsideRef = null,
  anchorElementRef: anchorElement = null,
  onClickOutside,
}) => {
  useOnClickOutside(clickOutsideRef, onClickOutside);

  const dropdownPosition = getDropdownPosition(position);
  const anchorElementHeight = anchorElement?.current?.offsetHeight || 0;

  return (
    <Transition
      as="div"
      show={show}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transition ease-out duration-200"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
      className={twMerge(
        dropdownPosition,
        fullWidth ? 'w-full' : 'xs:w-64 w-72',
        !noTopMargin && 'mt-2',
        'z-dropdown absolute hidden rounded-md bg-white shadow-lg sm:block',
        menuClassName
      )}
      style={{ top: `${anchorElementHeight}px` }}
    >
      <div className="rounded-md bg-white p-3" role="menu">
        <Stack spacing="xsmall">{children}</Stack>
      </div>
    </Transition>
  );
};

export default DropdownMenu;
