import { Contract, TapestryField } from '@tapestry/types';

export const THREAD_FIELDS_SORTING_ORDER = [
  Contract.Tags,
  TapestryField.MoreInformation,
  Contract.DueDate,
  TapestryField.Duration,
  Contract.Reminder,
  TapestryField.SubTask,
  TapestryField.Attachments,
  TapestryField.Links,
  Contract.Shop,
  Contract.Products,
  Contract.Department,
  Contract.Assignable,
  Contract.Owner,
];
