import React, { ReactElement } from 'react';
import { InfoCircleIcon } from '@tapestry/shared/icons';

interface IHankGuideInfoProps {
  title: string;
  link: string;
  className?: string;
}

/**
 * icon wrapped in a link that directs to hank university
 *
 * @param title - for hover and screen readers
 * @param link - where to go
 * @param className - `optional` use for positionning
 */
export const HankGuideInfo = ({
  title,
  link,
  className,
}: IHankGuideInfoProps): ReactElement => {
  return (
    <span className={`${className}`}>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        title={`Learn more about ${title}`}
      >
        <span className="sr-only">Learn more about {title}</span>
        <InfoCircleIcon fillColor="#666666" className="h-4 w-auto" />
      </a>
    </span>
  );
};
