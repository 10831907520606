import { CloseIcon } from '@tapestry/shared/icons';
import { FC, FocusEventHandler, useCallback, useMemo } from 'react';
import { FormInputBase } from '../FormInputBase';
import DatePicker from '../DatePickers/DatePicker/DatePicker';
import { TimePicker } from '../TimePicker';
import { IFormInputBaseProps } from '../FormInputBase/FormInputBase';
import { IsoString } from '@tapestry/types';
import { DateString } from '../Calendar/types';
import { dateTime } from '@tapestry/shared/utils';
import { useFocusWithin } from 'react-aria';
import { TimeString } from '../TimePicker/TimePicker';
import { separateDateFromTimeComponent } from './utils';

interface FormDateTimePickerProps extends IFormInputBaseProps {
  value: IsoString | string | null;
  onChange: (value: IsoString | string | null) => void;
  onBlur?: (value: IsoString | string | null) => void;
  onDateSelection?: (value: IsoString | null) => void;
  minDate?: Date;
  disabled?: boolean;
}

const DEFAULT_TIME = '17:00';

const FormDateTimePicker: FC<FormDateTimePickerProps> = (props) => {
  const {
    value,
    onChange,
    onBlur,
    onDateSelection,
    disabled,
    ...datePickerProps
  } = props;
  const [date, time] = useMemo(
    () => (value ? separateDateFromTimeComponent(value) : [null, null]),
    [value]
  );

  const handleOnChangeDate = (date: DateString | string | null) => {
    if (!date) {
      onChange(null);
      onDateSelection?.(null);

      return;
    }

    const parsedDate = dateTime.parse(
      `${date} ${time ?? DEFAULT_TIME}`,
      'YYYY-MM-DD HH:mm'
    );

    if (parsedDate.isValid()) {
      const isoString = parsedDate.toISOString() as IsoString;
      onChange(isoString);
      onDateSelection?.(isoString);
    } else {
      onChange(date);
    }
  };

  const handleOnDateBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    if (!onBlur) return;

    const typedDate = event.target.value;

    if (!typedDate) {
      onBlur(null);

      return;
    }

    const parsedDate = dateTime.parse(
      `${date} ${time ?? DEFAULT_TIME}`,
      'YYYY-MM-DD HH:mm'
    );

    if (parsedDate.isValid()) {
      const isoString = parsedDate.toISOString() as IsoString;
      onChange(isoString);
    } else {
      onChange(date);
    }
  };

  const transformTimeToISOString = useCallback(
    (time: TimeString) => {
      const parsedDate = date
        ? dateTime.parse(date, 'YYYY-MM-DD')
        : dateTime.now();

      const [hour, minute] = time.split(':').map((val) => Number(val));

      const ISOString = parsedDate
        .hour(hour)
        .minute(minute)
        .second(0)
        .millisecond(0)
        .toISOString() as IsoString;

      return ISOString;
    },
    [date]
  );

  const handlePickTime = useCallback(
    (time: TimeString | null) => {
      if (!time) return;

      const ISOString = transformTimeToISOString(time);
      onChange(ISOString);
    },
    [onChange, transformTimeToISOString]
  );

  const { focusWithinProps } = useFocusWithin({
    onBlurWithin: () => {
      onBlur && onBlur(value);
    },
  });

  const handleClear = () => {
    onChange(null);
    onDateSelection?.(null);
  };

  return (
    <FormInputBase {...props}>
      <div className=" border-gray-border flex items-stretch rounded-md border bg-white">
        <DatePicker
          {...datePickerProps}
          value={date}
          onChange={handleOnChangeDate}
          onBlur={handleOnDateBlur}
          clearable={false}
          wrapperClassName="flex-shrink-0 flex-grow basis-2/4"
          hasBorders={false}
          disabled={disabled}
        />

        <span {...focusWithinProps} className="flex-shrink">
          <TimePicker
            value={time}
            onChange={handlePickTime}
            clearable={false}
            hasBorders={false}
            disabled={disabled}
          />
        </span>

        {value && !disabled ? (
          <div className="flex items-center rounded-r-md">
            <button
              type="button"
              title="Clear"
              className="text-gray-text hover:bg-gray-hover focus:bg-gray-hover mr-0 flex size-7 items-center justify-center rounded-full p-2 transition-colors duration-150 focus:outline-none disabled:hover:bg-white sm:mr-3 sm:size-10 sm:p-3"
              onClick={handleClear}
              onKeyUp={({ key }) => {
                if (key === 'Enter') {
                  handleClear();
                }
              }}
            >
              <span className="sr-only">Clear</span>
              <CloseIcon light fillColor="currentColor" />
            </button>
          </div>
        ) : null}
      </div>
    </FormInputBase>
  );
};

export { FormDateTimePicker };
