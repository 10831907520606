export enum Visualization {
  Main = 'Main',
  TopProducts = 'topProducts',
  TopDepartments = 'topDepartments',
  TopCategories = 'topCategories',
  TopSpaces = 'topSpaces',
  BestTimes = 'bestTimes',
  TopCollections = 'collection',
  SellWellWith = 'sellWellWith',
  TopShops = 'topShops',
  TopSuppliers = 'topSuppliers',
  Demographics = 'demographics',
  Postcodes = 'postcodes',
  Staff = 'staff',
  ProductVariants = 'productVariants',
  Lists = 'lists',
  TasksList = 'tasksList',
}

export const ALL_VISUALIZATION = Object.values(Visualization);
