import { HANK_UNI_URLS } from '../../hank-university-links';
import { OnboardingSlide } from '@tapestry/types';
import retailOnboardingscreen1 from './retail-onboarding-1v3.jpeg';
import retailOnboardingscreen2 from './retail-onboarding-2v3.jpg';
import retailOnboardingscreen3 from './retail-onboarding-3v3.jpg';
import retailOnboardingscreen4 from './retail-onboarding-4v3.jpg';
import retailOnboardingscreen5 from './retail-onboarding-5v3.jpg';
import retailOnboardingscreen6 from './retail-onboarding-6v3.jpg';
import retailOnboardingscreen7 from './retail-onboarding-7v3.jpg';
import retailOnboardingscreen8 from './retail-onboarding-8v3.jpg';
import retailOnboardingscreen9 from './retail-onboarding-9v3.jpg';

export const RETAIL_SLIDE_CONTENT: OnboardingSlide[] = [
  {
    header: 'Smart tools just for you',
    text: 'The Retail+ toolkit works on mobile, tablet and web. It has a range of smart tools that help you manage staff, do more for your customers and even identify new opportunities!',
    image: retailOnboardingscreen1 as unknown as string,
  },
  {
    header: 'Retail+ keeps you in the know',
    text: `The Real-time analytics dashboard captures all sales and provides you with the insights you need to make decisions. Analyse real time insights on products, departments, categories, suppliers and much more`,
    image: retailOnboardingscreen2 as unknown as string,
    link: {
      label: 'Learn more',
      url: HANK_UNI_URLS.heartbeat.overview,
    },
  },
  {
    header: 'Find what you are looking for',
    text: `Utilise the search bar wherever you are in Retail+ to quickly find what you are looking for. Search for a particular product, supplier, category etc`,
    image: retailOnboardingscreen3 as unknown as string,
  },
  {
    header: 'Find and analyse manufacturers',
    text: `With 100s of different manufacturers under multiple big distributors sometimes founding a manufacturer of a certain product is not straightforward to find. We have done the hard work so you can easily search for an analyse for a specific manufacturer.`,
    image: retailOnboardingscreen4 as unknown as string,
  },
  {
    header: 'Analyse and export Supplier data',
    text: `Using the search bar or Suppliers applet, analyse and get all the important sales information for a particular supplier. Everything you see in Retail+ is also exportable.`,
    image: retailOnboardingscreen5 as unknown as string,
  },
  {
    header: 'Get more done with Tasks',
    text: 'Tasks is an easy to use team management tool. Use it to create and assign tasks to colleagues or anyone across your entire organisation. Simply manage all created and assigned tasks easily within Retail+.',
    image: retailOnboardingscreen6 as unknown as string,
    link: {
      label: 'Learn more',
      url: HANK_UNI_URLS.agenda.overview,
    },
  },
  {
    header: 'Stay in the loop',
    text: 'When you create a task you can set up optional notifications for either yourself and/or the person(s) assigned. You can also set up task reminders, and keep an eye on how tasks are progressing.',
    image: retailOnboardingscreen7 as unknown as string,
  },
  {
    header: 'Retail+ is always learning',
    text: 'Retail+ never stops learning about your business. As it learns it will develop to offer you new features, as well as better advise you with valuable insights and growth opportunities.',
    image: retailOnboardingscreen8 as unknown as string,
  },
  {
    header: 'We love feedback',
    text: 'Always looking to improve Retail+ not just welcomes your feedback but we demand it! 😀 You can do this within Retail+ itself by pressing the send feedback button or shaking your mobile device',
    image: retailOnboardingscreen9 as unknown as string,
  },
];
