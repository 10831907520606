import { useRouter } from 'next/router';

/**
 * URI encodes the browser location `pathname` and `search` property into a URI string
 *
 * @returns {string} - Encoded URI string like so: `%2Fproducts%3FgroupId%3D8114733b-10fc-4c6e-861e-dba80714607c%26sort%3Da_z`
 *
 * You can use in conjonction with `useQueryParam` on the page you wnat to use the return to like so
 * @example
 * const [returnTo] = useQueryParam('returnTo', ReturnToParam);
 *
 * <Link href={returnTo}>My link</Link>
 *
 */
export const useURIReturnPath = (): string => {
  const { asPath } = useRouter();

  return encodeURIComponent(asPath);
};
