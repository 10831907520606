import { Env } from '@tapestry/types';
import { hasPermissions } from '@tapestry/shared/utils';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useLocalStorage } from '../use-local-storage/use-local-storage';

type IUseDevModeReturn = [boolean, Dispatch<SetStateAction<boolean>>];

/**
 * check and set the app developer mode in local storage
 *
 * @example
 * const [isDevMode, setDevMode] = useDevMode()
 */
export function useDevMode(): IUseDevModeReturn {
  const [isDevMode, setDevMode] = useLocalStorage('devMode', false);

  // * auto-dispatch dev mode on mount if you are in your local dev environment
  useEffect(() => {
    if (process.env.NODE_ENV === 'test') return;

    if (hasPermissions([Env.Local])) {
      setDevMode(true);
    }
  }, [setDevMode]);

  return [isDevMode, setDevMode];
}

export default useDevMode;
