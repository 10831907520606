import { useEffect, useRef } from 'react';

/**
 * Use effect after component mount
 *
 * @param effect
 * @param deps
 *
 * @return {*}
 */
export function useEffectAfterMount(effect: () => void, deps: any[]) {
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (!isFirstRun.current) {
      return effect();
    }

    isFirstRun.current = false;
  }, deps);
}
