import { Children, FC, useEffect, useState } from 'react';
import { useMobileMenuContext } from './DropdownMobileContext';
import { a, useIsomorphicLayoutEffect } from '@react-spring/web';
import { twMerge } from 'tailwind-merge';
import {
  MENU_ITEM_HEIGHT,
  getDropdownPosition,
  getOverlayStyle,
} from './DropdownUtils';
import { useDesktopMenuContext } from './DropdownDesktopContext';
import { Overlay, usePreventScroll } from 'react-aria';
import { useAppMediaQuery } from '../hooks/useAppMediaQueries';

/**
 * Renders a sliding tray stye menu on mobile width screens
 */
const MobileMenu: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { bind, y, height, setHeight, close, shouldPreventScroll } =
    useMobileMenuContext();
  usePreventScroll({ isDisabled: !shouldPreventScroll });

  const display = y.to((py: number) => (py < height ? 'block' : 'none'));
  const numberOfChildren = Children.count(children);

  useIsomorphicLayoutEffect(
    function setMenuHeightFromChildren() {
      const SOME_SPACE = 6;
      const calculatedHeight =
        (numberOfChildren + SOME_SPACE) * MENU_ITEM_HEIGHT;
      setHeight(calculatedHeight);
    },
    [numberOfChildren]
  );

  return (
    <Overlay>
      {/* Overlay */}
      <a.div
        style={getOverlayStyle(y, height)}
        className="fixed inset-0 bg-black z-dropdown"
        onTouchEnd={() => close()}
      />

      {/* Tray */}
      <a.div
        className="bg-white z-modal fixed bottom-0 left-0 right-0 w-full rounded-t-lg touch-none h-full px-4 py-4 space-y-2"
        {...bind()}
        style={{
          display,
          y,
          bottom: `calc(-100vh + ${height}px)`,
        }}
      >
        {/* Notch */}
        <div className="bg-gray-200 h-[6px] w-12 mx-auto rounded-full mb-6" />
        {children}
      </a.div>
    </Overlay>
  );
};

type Position = 'top-right' | 'top-center' | 'top-left';
interface DropdownMenuDesktopProps {
  children: React.ReactNode;
  position?: Position;
  noTopMargin?: boolean;
}

/**
 * Classic dropdown on anything but phone screen widths
 */
const DesktopMenu: FC<DropdownMenuDesktopProps> = ({
  children,
  position = 'top-right',
  noTopMargin,
}) => {
  const { showDropdown, springs } = useDesktopMenuContext();
  const dropdownPosition = getDropdownPosition(position);
  if (!showDropdown) return null;

  return (
    <a.div
      style={springs}
      className={twMerge(
        dropdownPosition,
        !noTopMargin && 'mt-2',
        'xs:w-64 w-72 z-dropdown absolute rounded-md bg-white shadow-lg p-3 space-y-2'
      )}
    >
      {children}
    </a.div>
  );
};

interface DropdownMenuProps extends Omit<DropdownMenuDesktopProps, 'children'> {
  children: React.ReactNode;
}

// Distro which menu to render
export const Menu: FC<DropdownMenuProps> = ({ children, ...props }) => {
  const { isPhone } = useAppMediaQuery();
  const [shouldShowMobileMenu, setShouldShowMobileMenu] = useState(false); // Need this because the media query is not available on the server

  useEffect(
    function decideWhichMenuToRender() {
      if (isPhone) {
        setShouldShowMobileMenu(true);
      } else {
        setShouldShowMobileMenu(false);
      }
    },
    [isPhone]
  );

  return shouldShowMobileMenu ? (
    <MobileMenu {...props}>{children}</MobileMenu>
  ) : (
    <DesktopMenu {...props}>{children}</DesktopMenu>
  );
};
