import { Scope } from '@sentry/types';
import isEmpty from 'lodash/isEmpty';

/**
 * transforms bytes to a readable version
 */
export function returnFileSize(number) {
  if (number < 1024) {
    return `${number} bytes`;
  } else if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)} KB`;
  } else if (number >= 1048576) {
    return `${(number / 1048576).toFixed(1)} MB`;
  }
}

export const processFileToUint8Array = async (file: File) => {
  const buffer = await file.arrayBuffer();
  const fileAsUint8Array = new Uint8Array(buffer);

  return fileAsUint8Array;
};

export const handleAddAttachmentToScope = (
  scope: Scope,
  fileList: File[],
  convertedFiles: Uint8Array[]
) => {
  if (isEmpty(fileList)) return scope;

  fileList.forEach((file, index) => {
    scope.addAttachment({
      filename: file.name,
      contentType: file.type,
      data: convertedFiles[index],
    });
  });

  return scope;
};
