import { FC } from 'react';
import { useMobileMenuContext } from './DropdownMobileContext';
import { useDesktopMenuContext } from './DropdownDesktopContext';
import { useAppMediaQuery } from '../hooks/useAppMediaQueries';
import { twMerge } from 'tailwind-merge';

interface DropdownMenuButtonProps extends React.HTMLProps<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
}

export const Button: FC<DropdownMenuButtonProps> = ({
  children,
  ...buttonProps
}) => {
  const { open } = useMobileMenuContext();
  const { closeMenu, openMenu, showDropdown } = useDesktopMenuContext();
  const { isPhone } = useAppMediaQuery();

  const toggleDropdownMenu = () => {
    if (showDropdown) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  const handleAction = (e) => {
    if (isPhone) {
      open(e);
    } else {
      toggleDropdownMenu();
    }
  };

  return (
    <button
      {...buttonProps}
      className={twMerge('group focus:outline-none', buttonProps.className)}
      onClick={handleAction}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleAction(e);
        }
      }}
      type="button"
      aria-haspopup="true"
    >
      {children}
    </button>
  );
};
