import { Field, Label, Switch } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { useToggleState } from '@react-stately/toggle';
import { ToggleProps } from '@react-types/checkbox';
import { FC } from 'react';

type TogglePropsWithLabel = ToggleProps & { label?: string };

const Toggle: FC<TogglePropsWithLabel> = ({ label, ...props }) => {
  const { isSelected, setSelected } = useToggleState(props);

  return (
    <Field as="div" className="flex items-center">
      <Switch
        checked={isSelected}
        onChange={setSelected}
        className={twMerge(
          isSelected ? 'bg-primary' : 'bg-gray-200',
          'focus:ring-primary relative inline-flex h-6 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out'
        )}
      >
        <span
          aria-hidden="true"
          className={twMerge(
            isSelected ? 'translate-x-4' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
      {label ? (
        <Label as="span" className="ml-3 text-sm">
          {label}
        </Label>
      ) : null}
    </Field>
  );
};

export { Toggle };
