import React, { useMemo } from 'react';
import { ApolloProvider } from '@apollo/client';
import { OverlayProvider } from '@react-aria/overlays';
import { NextAdapter } from 'next-query-params';
import { QueryParamProvider } from 'use-query-params';
import 'react-loading-skeleton/dist/skeleton.css';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { apolloClient } from './apollo-client';
import { LocationContextProvider } from '../context/location-context';
import { ToastProvider } from '../context/toast-context';
import { SlideOverProvider } from '../context/slide-over-context';
import { UIContextProvider } from '../context/ui-context';
import { PrivateRouteProvider } from './PrivateRouteProvider';
import { ErrorBoundary } from '../components/ErrorBoundary';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    Cypress: any;
    dayjs: any; //dayjs;
  }
}

/**
 * Main NextJS App providers
 */
export const NextTapestryProviders: React.FC<
  React.PropsWithChildren<unknown & { public?: boolean }>
> = ({ children, public: isPublic }) => {
  const { demoMode } = useFlags();
  // Because `apolloClient` requires flag value and LD takes some time to return the flag value, the `apolloClient gets initiated 2-3 times which creates the first/second set of queries to be cancelled.
  // by memoizing we can cut one of those iteration. though the dependency on `isDemo` is still creating an additionnal iteration.
  const _apolloClient = useMemo(
    () => apolloClient({ isDemo: demoMode }),
    [demoMode]
  );

  // TODO Granularly split the providers to the page you need them in
  // This will make less upfront shared code having to be loaded
  return (
    <ErrorBoundary>
      <ApolloProvider client={_apolloClient}>
        <QueryParamProvider adapter={NextAdapter}>
          <PrivateRouteProvider isPublic={isPublic || false}>
            <LocationContextProvider>
              <ToastProvider>
                <SlideOverProvider>
                  <UIContextProvider>
                    <OverlayProvider>{children}</OverlayProvider>
                  </UIContextProvider>
                </SlideOverProvider>
              </ToastProvider>
            </LocationContextProvider>
          </PrivateRouteProvider>
        </QueryParamProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};
