import { FieldFunctionOptions, Reference, StoreObject } from '@apollo/client';

/**
 * Enables merge cache array of object by field
 *
 * NOTE: maybe typing could be improve to FieldMergeFunction<TExisting, TIncoming, TOptions> for the internal function;
 * But Kev has no idea how to pass a type then
 */
export const mergeArrayByField =
  <T extends StoreObject | Reference | undefined>(field: string) =>
  (
    existing: T[],
    incoming: T[],
    { readField, mergeObjects }: FieldFunctionOptions
  ) => {
    const merged = existing ? existing.slice(0) : [];
    const existingFieldToIndex: Record<string, number> = Object.create(null);

    if (existing) {
      existing.forEach((existingEntry, index) => {
        const fieldValue = readField<string>(field, existingEntry) as string;
        existingFieldToIndex[fieldValue] = index;
      });
    }

    incoming.forEach((incomingEntry) => {
      const incomingFieldVal = readField<string>(
        field,
        incomingEntry
      ) as string;
      const index = existingFieldToIndex[incomingFieldVal];

      if (typeof index === 'number') {
        // Merge the new incomingEntry data with the existing data.
        // @ts-expect-error - Unsure how to type this
        merged[index] = mergeObjects(merged[index], incomingEntry);
      } else {
        // First time we've seen this incomingEntry in this array.
        existingFieldToIndex[incomingFieldVal] = merged.length;
        merged.push(incomingEntry);
      }
    });

    return merged;
  };
