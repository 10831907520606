import { TWIST_SORTING_ORDER } from '@tapestry/shared/constants';
import { ITwistType } from '@tapestry/shared/graphql';
import { Twist } from '@tapestry/types';

export function byDefaultThreadSortingOrder(a: ITwistType, b: ITwistType) {
  if (
    TWIST_SORTING_ORDER.indexOf(a.key as Twist) <
    TWIST_SORTING_ORDER.indexOf(b.key as Twist)
  ) {
    return -1;
  }

  if (
    TWIST_SORTING_ORDER.indexOf(a.key as Twist) >
    TWIST_SORTING_ORDER.indexOf(b.key as Twist)
  ) {
    return 1;
  }

  return 0;
}
