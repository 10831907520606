import React, { FC } from 'react';
import { Pill, ResponsiveDropdownMenu } from '@tapestry/weave';
import { SortDownUpIcon } from '@tapestry/shared/icons';
import { useEffectAfterMount, useSortMenu } from '@tapestry/shared/hooks';

interface IHeaderSortFilterProps {
  sortOptions?: Array<{ label: string; id: string; shorthand: string }>;
  onChange?: (sort: string) => void;
}

const SORT_MENU_ITEMS = [
  {
    label: 'Alphabetical A > Z',
    shorthand: 'A > Z',
    id: 'a_z',
  },
  {
    label: 'Alphabetical Z > A',
    shorthand: 'Z > A',
    id: 'z_a',
  },
  {
    label: 'Newest First',
    shorthand: 'Newest First',
    id: 'newest',
  },
  {
    label: 'Oldest First',
    shorthand: 'Oldest First',
    id: 'oldest',
  },
];

export const HeaderSortFilter: FC<IHeaderSortFilterProps> = ({
  onChange,
  sortOptions = SORT_MENU_ITEMS,
}) => {
  const {
    items,
    getItemProps,
    state: { sortBy, activeItem },
  } = useSortMenu({
    items: sortOptions,
    defaultItem: sortOptions[0].id,
  });

  useEffectAfterMount(() => {
    onChange && onChange(sortBy);
  }, [sortBy]);

  return (
    <ResponsiveDropdownMenu>
      <ResponsiveDropdownMenu.Button>
        <span className="sr-only">Sort: {activeItem?.shorthand}</span>

        <span className="border-gray-border group-hover:border-primary group-focus:border-primary inline-flex size-14 items-center justify-center whitespace-nowrap rounded-full border-2 p-4 text-black shadow-sm md:hidden">
          <SortDownUpIcon />
        </span>

        <Pill
          iconLeft={<SortDownUpIcon />}
          label={activeItem?.shorthand || ''}
          size="xxl"
          textStyle="capitalize"
          bgColor="bg-white"
          className="border-gray-border group-hover:border-primary group-focus:border-primary hidden whitespace-nowrap border-2 text-black shadow-sm md:inline-flex"
        />
      </ResponsiveDropdownMenu.Button>

      <ResponsiveDropdownMenu.Menu>
        <h6 className="text-gray-text mt-1 pl-3 text-left text-sm">Sort by</h6>

        {items.map((item) => (
          <ResponsiveDropdownMenu.MenuItem
            key={item.id}
            className={`hover:bg-gray-hover
                  focus:bg-gray-hover active:bg-gray-active w-full rounded-md px-3 py-1 text-left text-base text-black focus:outline-none ${
                    item.id === sortBy ? 'text-primary' : ''
                  }`}
            {...getItemProps({ item: item })}
          >
            {item.label}
          </ResponsiveDropdownMenu.MenuItem>
        ))}
      </ResponsiveDropdownMenu.Menu>
    </ResponsiveDropdownMenu>
  );
};
