import React, { ElementType, FC } from 'react';
import { SpacingScale } from '@tapestry/types';
import { Overlay } from '../Overlay/Overlay';
import { twMerge } from 'tailwind-merge';

type CSSBackgroundGradients =
  | `linear-gradient(${string})`
  | `radial-gradient(${string})`
  | `conic-gradient(${string})`;

interface ICardProps {
  spacing?: SpacingScale;
  as?: ElementType;
  className?: string;
  bgColor?: `#${string}` | `bg-${string}`;
  minWidth?: SpacingScale;
  align?: 'top' | 'middle' | 'bottom' | 'space-between';
  justify?: 'left' | 'center' | 'right';
  backgroundImage?: string;
  backgroundGradient?: CSSBackgroundGradients;
  setHeight?: `h-${string}` | `min-h-${string}` | `max-h-${string}`;
  noOverlay?: boolean;
}

const getSpacing = (spacing: ICardProps['spacing']) => {
  const spacingOptions = {
    none: 'py-0 px-0',
    xxsmall: 'py-1 px-1',
    xsmall: 'py-2 px-2',
    small: 'py-4 px-4', //default, change the others when needed
    medium: 'py-6 px-6',
  };

  return spacingOptions[spacing as string] || spacingOptions.medium;
  // return resolveResponsiveProps(
  //   spacingOptions,
  //   spacing as ResponsiveBreakpoints | ResponsiveBreakpoints[]
  // );
};

const getMinWidth = (width: ICardProps['minWidth']) => {
  const minWidthOptions = {
    none: 'min-w-0',
    xsmall: 'min-w-32', // 128px
    small: 'min-w-32', //128px
    medium: 'min-w-56', //224px
    large: 'min-w-64', //256px
  };

  return minWidthOptions[width as string] || minWidthOptions.medium;
  // return resolveResponsiveProps(
  //   minWidthOptions,
  //   spacing as ResponsiveBreakpoints | ResponsiveBreakpoints[]
  // );
};

const getHorizontalAlign = (justify: ICardProps['justify']) => {
  switch (justify) {
    case 'left':
      return 'items-start text-left';

    case 'center':
      return 'items-center text-center';

    case 'right':
      return 'items-end text-right';

    default:
      return 'items-start text-left';
  }
};

const getVerticalAlign = (align: ICardProps['align']) => {
  switch (align) {
    case 'top':
      return 'justify-start';

    case 'middle':
      return 'justify-center';

    case 'bottom':
      return 'justify-end';

    case 'space-between':
      return 'justify-between';

    default:
      return 'justify-center';
  }
};

export const Card: FC<React.PropsWithChildren<ICardProps>> = ({
  spacing = 'small',
  as: Component = 'div',
  children,
  className,
  minWidth = 'none',
  bgColor,
  backgroundImage,
  backgroundGradient,
  align,
  justify,
  noOverlay = false,
}) => {
  const space = getSpacing(spacing);
  const _minWidth = getMinWidth(minWidth);
  const horizontalAlignment = getHorizontalAlign(justify);
  const verticalAlignment = getVerticalAlign(align);

  const tailwindBackgroundColor = bgColor?.startsWith('bg-')
    ? bgColor
    : 'bg-white';

  const cssBackgroundColor = bgColor?.startsWith('#') ? bgColor : '';

  return (
    <Component
      className={twMerge(
        `flex flex-col ${horizontalAlignment} ${verticalAlignment} ${space} ${_minWidth} ${tailwindBackgroundColor}  w-full rounded-lg bg-cover bg-center bg-no-repeat ${
          backgroundImage ? 'relative bg-cover bg-center bg-no-repeat ' : ''
        } ${className}`
      )}
      style={{
        background: cssBackgroundColor,
        backgroundImage: backgroundImage
          ? `url("${backgroundImage}")`
          : backgroundGradient,
      }}
    >
      {backgroundImage && !noOverlay ? <Overlay rounded="rounded-lg" /> : null}
      {children}
    </Component>
  );
};
