import { createContext, useContext, useReducer } from 'react';
import { SidebarView } from '@tapestry/shared/constants';
import { THREAD_TYPE } from '@tapestry/types';
import {
  ThreadTypeThemeColors,
  ThreadTypeToThemeColorMapper,
} from './thread-color-theme-map';

export type UIContextState = {
  threadTypeThemeColors: ThreadTypeThemeColors;
  sidebarIsOpen: SidebarView | null;
  shouldShowAnnouncement: boolean;
  hasTopBannersShowing: boolean;
  showSplashScreen: boolean;
  splashScreenProps?: {
    title?: string;
    color?: string;
    icon?: React.FC<any>;
  };
};

export type UIContextActions =
  | { type: 'UPDATE_THREAD_THEME_COLORS'; payload: THREAD_TYPE }
  | { type: 'OPEN_SIDEBAR'; payload: SidebarView }
  | { type: 'CLOSE_SIDEBAR' }
  | {
      type: 'UPDATE_SHOULD_SHOW_ANNOUNCEMENT';
      payload: boolean;
    }
  | {
      type: 'TOGGLE_TOP_BANNERS';
      payload: boolean;
    }
  | {
      type: 'SHOW_SPLASH_SCREEN';
      payload?: { props: UIContextState['splashScreenProps'] };
    }
  | { type: 'HIDE_SPLASH_SCREEN' };

export type UIContextDispatch = (action: UIContextActions) => void;
type UIContextValue = [UIContextState, UIContextDispatch];

const initialState: UIContextState = {
  threadTypeThemeColors: ThreadTypeToThemeColorMapper[THREAD_TYPE.SHOP] || {
    backgroundColor: 'bg-orange',
    altBackgroundColor: 'bg-tangerine',
    backgroundColorHover: 'bg-tangering-dark',
    textColor: 'text-orange',
  },
  sidebarIsOpen: null,
  shouldShowAnnouncement: false,
  hasTopBannersShowing: false,
  showSplashScreen: false,
  splashScreenProps: {},
};

const UIReducer = (
  state: UIContextState = initialState,
  action: UIContextActions
) => {
  switch (action.type) {
    case 'SHOW_SPLASH_SCREEN':
      return {
        ...state,
        showSplashScreen: true,
        splashScreenProps: action?.payload?.props || {},
      };

    case 'HIDE_SPLASH_SCREEN':
      return { ...state, showSplashScreen: false };

    case 'OPEN_SIDEBAR':
      return { ...state, sidebarIsOpen: action.payload };

    case 'CLOSE_SIDEBAR':
      return { ...state, sidebarIsOpen: null };

    case 'TOGGLE_TOP_BANNERS':
      return { ...state, hasTopBannersShowing: action.payload };

    case 'UPDATE_THREAD_THEME_COLORS': {
      let newThemeColors = state.threadTypeThemeColors;

      if (action.payload) {
        newThemeColors =
          ThreadTypeToThemeColorMapper[action.payload] ??
          state.threadTypeThemeColors;
      }

      return {
        ...state,
        threadTypeThemeColors: newThemeColors,
      };
    }

    case 'UPDATE_SHOULD_SHOW_ANNOUNCEMENT': {
      return {
        ...state,
        shouldShowAnnouncement: action.payload,
      };
    }

    default:
      throw new Error(`UIReducer: Invalid action type`);
  }
};

const UIContext = createContext<UIContextValue | undefined>(undefined);

export const UIContextProvider = ({ children }: any) => {
  const [UIState, dispatch] = useReducer(UIReducer, initialState);
  const value: UIContextValue = [UIState, dispatch as UIContextDispatch];

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

export const useUIContext = (): UIContextValue => {
  const context = useContext(UIContext);

  if (context === undefined) {
    throw new Error('useUIContext must be inside UIContextProvider');
  }

  return context;
};
