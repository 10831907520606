// const last3ZerosRegex = /0{3}(\.[0-9]*)?$/;
const last3IntegersRegex = /(,\d{3})(\.[0-9]*)?$/;
// REGEX: adds comma to number to make it "human-readable" such as 1,000,000
const groupsOfThreeDigits = /\B(?=(\d{3})+(?!\d))/g;

/**
 * parseToCurrencyString
 *
 * Creates a string from a value (number) and unit symbol and a possible possition
 *
 * @example
 * parseToCurrencyString(10000, '$', 'left') // '$10,000'
 *
 * @param number - the value (i.e. `1000`)
 * @param metric - a unit symbol such as '$' | '%'
 * @param metricPosition - 'left' or 'right'
 * @param options - config object like {
    noDecimals?: boolean;
    thousandsInKs?: boolean;
  }
 */
export const parseToCurrencyString = (
  number: number,
  metric?: string | '$' | '%',
  metricPosition?: 'left' | 'right' | string,
  options?: {
    noDecimals?: boolean;
    thousandsInKs?: boolean;
  }
): string => {
  if (typeof number === 'string') return number;

  const _metricPosition = metricPosition || 'left';
  const numberWithDecimalOrNot =
    !metric || options?.noDecimals
      ? Number(number).toFixed(0)
      : Number(number).toFixed(2);
  let stringifiedNumber: string;

  if (options?.thousandsInKs) {
    stringifiedNumber = numberWithDecimalOrNot
      .replace(groupsOfThreeDigits, ',')
      .replace(last3IntegersRegex, 'k');
  } else {
    stringifiedNumber = numberWithDecimalOrNot.replace(
      groupsOfThreeDigits,
      ','
    );
  }

  return _metricPosition === 'left'
    ? `${metric ? metric : ''}${stringifiedNumber}`
    : `${stringifiedNumber}${metric ? metric : ''}`;
};

export default parseToCurrencyString;
