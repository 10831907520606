export enum TapestryField {
  TaskName = 'taskName',
  MoreInformation = 'more-information',
  SubTask = 'sub_task',
  Attachments = 'attachments',
  Assignable = 'assignable',
  Shop = 'shop',
  Department = 'department',
  Product = 'products',
  DueDate = 'due_date',
  Reminder = 'reminder',
  Duration = 'estimated-duration',
  Tags = 'tags',
  Links = 'links',
  owner = 'owner',
  WetRate = 'wet_rate',
  UnitQuantity = 'unit_qty',
}
