import { CalendarIcon, CloseIcon } from '@tapestry/shared/icons';
import { type FC, PropsWithChildren, useRef, useState } from 'react';
import { Calendar, DateString, ICalProps } from '../../Calendar';
import { usePopper } from 'react-popper';
import { dateTime, extractDate } from '@tapestry/shared/utils';
import { IsoString } from '@tapestry/types';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import {
  IDatePickerProps,
  formattedOrPassedDate,
  parsedOrPassedDate,
  DISPLAY_FORMAT,
  InputProps,
  ToggleButtonProps,
} from './date-picker-utils';
import { twMerge } from 'tailwind-merge';
import { ResponsiveRenderingWrapper } from '../utils/ResponsiveRenderingWrapper';

/**
 * TODO
 * - Accessibility - https://www.w3.org/WAI/ARIA/apg/example-index/dialog-modal/datepicker-dialog
 */

/**
 * A Calendar picker
 *
 * Use to pick a single date
 *
 * If you are looking to pick a range of dates, use the `DateRangePicker`
 *
 * @return ISOstring - '2022-11-03'
 */
export const DatePicker: FC<PropsWithChildren<IDatePickerProps>> = ({
  children,
  disabled = false,
  wrapperClassName,
  hasBorders = true,
  ...calendarProps
}) => {
  const { value, onChange, onBlur, onDateSelection, clearable } = calendarProps;

  const inputValue = value ? formattedOrPassedDate(value) : '';
  const calendarValue = dateTime.parse(value, 'YYYY-MM-DD').isValid()
    ? (value as DateString)
    : null;
  const [shouldShowCalendar, setShouldShowCalendar] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(wrapperRef, () => setShouldShowCalendar(false));

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const popper = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });
  /**
   * Functions
   */

  const handleToggleCalendar = () => {
    setShouldShowCalendar((currState) => !currState);
  };

  const handleTypeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    // * [mobile input]: when used on the mobile input, the empty value is an empty string
    // * this guard condition would return `null` in this case
    if (e.target.value.length === 0) {
      onChange(null);

      return;
    }

    const parsedDate = parsedOrPassedDate(e.target.value);
    onChange(parsedDate);
  };

  const handleSelectDate = (date: IsoString | null) => {
    if (!date) return;

    onChange(extractDate(date));
    onDateSelection?.(extractDate(date));

    setShouldShowCalendar(false);
  };

  const handleClearDate = () => {
    if (!clearable) return;
    onChange(null);
    onDateSelection?.(null);
  };

  const inputProps: InputProps = {
    value: inputValue,
    onChange: handleTypeDate,
    onBlur,
    ref: setReferenceElement,
    disabled,
  };

  const toggleButtonProps: ToggleButtonProps = {
    'aria-label': `Pick Date, ${value ? new Date(value) : ''}`,
    title: `Pick Date, ${value ? new Date(value) : ''}`,
    type: 'button',
    disabled,
    onClick: handleToggleCalendar,
    onKeyPress({ key }) {
      if (key === 'Enter') {
        handleToggleCalendar();
      }
    },
  };

  return (
    <div ref={wrapperRef} className={wrapperClassName}>
      {/* If children is simple react node, render children */}
      {children && typeof children !== 'function' ? children : null}

      {/* If children are a render prop, call children with internal props */}
      {children && typeof children === 'function'
        ? children({ inputProps, toggleButtonProps })
        : null}

      {/* Else if no children, render default input */}
      {!children ? (
        <div className="relative">
          <button
            {...toggleButtonProps}
            className="bg-gray-hover hover:bg-gray-hover focus:bg-gray-hover absolute left-0 top-1/2 z-10 ml-2 flex size-7 -translate-y-1/2 items-center justify-center rounded-full p-2 text-black transition-colors duration-150 focus:outline-none disabled:hover:bg-white sm:ml-3 sm:size-10 sm:bg-white sm:p-3"
          >
            <span className="sr-only">Pick Date</span>
            <CalendarIcon fillColor="currentColor" />
          </button>

          <input
            type="text"
            placeholder={DISPLAY_FORMAT}
            className={twMerge(
              'sm:pl-15 text-gray-text placeholder-gray-text focus:border-orange-hank inline-flex w-full items-center rounded-md border p-3 pl-10 text-sm uppercase focus:outline-none disabled:bg-white sm:text-xl',
              clearable && 'sm:pr-15 pr-10',
              hasBorders ? 'border-gray-border' : 'border-transparent'
            )}
            {...inputProps}
          />

          {clearable && value ? (
            <button
              type="button"
              title="Clear Date"
              className="text-gray-text hover:bg-gray-hover focus:bg-gray-hover absolute right-0 top-1/2 mr-2 flex size-7 -translate-y-1/2 items-center justify-center rounded-full p-2 transition-colors duration-150 focus:outline-none disabled:hover:bg-white sm:mr-3 sm:size-10 sm:p-3"
              onClick={handleClearDate}
              onKeyUp={({ key }) => {
                if (key === 'Enter') {
                  handleClearDate();
                }
              }}
            >
              <span className="sr-only">Clear Date</span>
              <CloseIcon light fillColor="currentColor" />
            </button>
          ) : null}
        </div>
      ) : null}

      {/* Calendar Popover */}
      {shouldShowCalendar && (
        <ResponsiveRenderingWrapper
          shouldShowCalendar={shouldShowCalendar}
          popper={popper}
          onClose={() => setShouldShowCalendar(false)}
          ref={setPopperElement}
        >
          <Calendar
            {...calendarProps}
            value={calendarValue}
            onChange={handleSelectDate as ICalProps['onChange']} // * We need to cast the type here because the calendar library can deal with single date or date range BUT this component solely deals with single date
            containerClassName="rounded-lg rounded-t-none px-2 pb-4 xs:p-4"
          />
        </ResponsiveRenderingWrapper>
      )}
    </div>
  );
};

export default DatePicker;
