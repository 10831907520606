/**
 * Should follow a naming convention
 *
 * For example [type]_[name]_[setting]
 * i.e. applet_tasks_mySetting
 */
export enum UserSetting {
  applet_tasks_filters = 'applet_tasks_filters',
  applet_product_filters = 'applet_product_filters',
  applet_departments_filters = 'applet_departments_filters',
  applet_deals_filters = 'applet_deals_filters',
  applet_categories_filters = 'applet_categories_filters',
  applet_collections_filters = 'applet_collections_filters',
  applet_spaces_filters = 'applet_spaces_filters',
  applet_suppliers_filters = 'applet_suppliers_filters',
  applet_transaction_filters = 'applet_transaction_filters',
  applet_analytic_filters = 'applet_analytic_filters',
  applet_analytic_chart_default_comparison = 'applet_analytic_chart_default_comparison',
}
