// *******************************************************
// PaginationFooter
// -------------------------------------------------------
// Component Description
//
// -------------------------------------------
import { useAppMediaQuery } from '@tapestry/weave';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { FunctionComponent, VFC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { twMerge } from 'tailwind-merge';

// *******************************************
// Action / Utils / Functions Imports
// -------------------------------------------
const calculateIfShouldShowSomePageTiles = (
  currentPage: number,
  showBoundaryPages: boolean,
  boundaryPagesAmount: number,
  lastPage: Maybe<number>
) => {
  const startOffset: number = showBoundaryPages ? 1 + boundaryPagesAmount : 1;
  const shouldShowVeryFirstTile = showBoundaryPages
    ? currentPage > startOffset
    : currentPage > startOffset;
  const shouldShowBeginningEllipsis = showBoundaryPages
    ? currentPage > startOffset + 1
    : currentPage > startOffset + 1;

  let shouldShowLastTile = false;
  let shouldShowEndingEllipsis = false;

  if (lastPage) {
    const endingOffsetAmount = lastPage - boundaryPagesAmount;
    shouldShowLastTile = currentPage < endingOffsetAmount;
    shouldShowEndingEllipsis = currentPage < endingOffsetAmount - 1;
  }

  return {
    shouldShowVeryFirstTile,
    shouldShowBeginningEllipsis,
    shouldShowLastTile,
    shouldShowEndingEllipsis,
  };
};

// *******************************************
// Local Interface
// -------------------------------------------

interface IAppletPaginationButtonProps {
  page: number;
  place?: string;
  isActive?: boolean;
  onPrevWithPageNumber?: (page: number) => void;
  onNextWithPageNumber?: (page: number) => void;
}

const AppletPaginationButton: FunctionComponent<
  React.PropsWithChildren<IAppletPaginationButtonProps>
> = ({
  page,
  isActive = false,
  onPrevWithPageNumber,
  onNextWithPageNumber,
}) => {
  const handleFunctionChoice = () => {
    if (onPrevWithPageNumber) {
      onPrevWithPageNumber(page);

      return;
    }

    if (onNextWithPageNumber) {
      onNextWithPageNumber(page);

      return;
    }
  };

  return (
    <button
      className={twMerge(
        'relative -ml-px inline-flex items-center rounded-full px-3.5 text-sm font-bold',
        'leading-5 text-gray-700 transition duration-150 ease-in-out',
        'hover:bg-white hover:text-black focus:z-10 focus:bg-white focus:outline-none',
        isActive && 'cursor-auto bg-white text-black'
      )}
      onClick={handleFunctionChoice}
    >
      {page}
    </button>
  );
};

// *******************************************
// Main Component
// -------------------------------------------
export interface IPaginationFooterProps {
  className?: string;
  simple?: boolean;
  isLoading?: boolean;
  currentPage?: number;
  lastPage?: number | null;
  isLastPage?: boolean;
  showBoundaryPages?: boolean;
  boundaryPagesAmount?: number;
  showFirstAndLastPage?: boolean;
  onPrev: () => void;
  onNext: () => void;
  onPrevWithPageNumber?: (page: number) => void;
  onNextWithPageNumber?: (page: number) => void;
}

export const PaginationFooter: VFC<IPaginationFooterProps> = ({
  className = '',
  isLoading = false,
  simple,
  currentPage = 1,
  lastPage,
  isLastPage = false,
  showBoundaryPages = false,
  boundaryPagesAmount = 1,
  showFirstAndLastPage,
  onNext,
  onPrev,
  onPrevWithPageNumber,
  onNextWithPageNumber,
}) => {
  const { isPhone } = useAppMediaQuery();
  const {
    shouldShowVeryFirstTile,
    shouldShowBeginningEllipsis,
    shouldShowLastTile,
    shouldShowEndingEllipsis,
  } = calculateIfShouldShowSomePageTiles(
    currentPage,
    showBoundaryPages,
    boundaryPagesAmount,
    lastPage
  );
  const shouldShowLeftBoundaryTiles =
    showBoundaryPages && !isPhone && currentPage !== 1;
  const shouldShowRightBoundaryTiles =
    showBoundaryPages && !isPhone && !isLastPage;

  const boundaryPagesCreationArray = Array(boundaryPagesAmount).fill('tile');

  const LeftBoundaryTiles = () => {
    return (
      <>
        {boundaryPagesCreationArray.map((tile, index) => {
          const page = currentPage + (index - boundaryPagesAmount);

          return page > 0 ? (
            <AppletPaginationButton
              key={page}
              page={page}
              onPrevWithPageNumber={onPrevWithPageNumber}
            />
          ) : null;
        })}
      </>
    );
  };

  const RightBoundaryTiles = () => {
    return (
      <>
        {boundaryPagesCreationArray.map((tile, index) => {
          const page = currentPage + (index + 1);
          const shouldRenderTile = lastPage ? page <= lastPage : true;

          return shouldRenderTile ? (
            <AppletPaginationButton
              key={page}
              page={page}
              onNextWithPageNumber={onNextWithPageNumber}
            />
          ) : null;
        })}
      </>
    );
  };

  return (
    <nav className={`relative z-0 inline-flex ${className}`}>
      {isLoading ? (
        <Skeleton height="2.5rem" width={240} />
      ) : (
        <>
          {currentPage > 1 && (
            <button
              className="focus:border-blue-light focus:ring-blue relative inline-flex items-center rounded-full px-2 py-2 text-sm font-medium leading-5 text-black transition duration-150 ease-in-out hover:bg-white focus:z-10 focus:outline-none active:bg-gray-100 active:text-gray-500 disabled:cursor-auto"
              aria-label="Previous"
              onClick={() => onPrev()}
              onKeyPress={({ key }) => {
                if (key === 'Enter') {
                  onPrev();
                }
              }}
              disabled={currentPage <= 1}
            >
              {/* <!-- Heroicon name: chevron-left --> */}
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}

          {showFirstAndLastPage && shouldShowVeryFirstTile && (
            <>
              <AppletPaginationButton
                page={1}
                onNextWithPageNumber={onNextWithPageNumber}
              />

              {shouldShowBeginningEllipsis && (
                <span
                  className={`relative -ml-px inline-flex items-center rounded-full px-3.5 text-sm font-bold leading-5 text-gray-700 `}
                >
                  ...
                </span>
              )}
            </>
          )}

          {!simple && (
            <>
              {shouldShowLeftBoundaryTiles && <LeftBoundaryTiles />}

              <AppletPaginationButton page={currentPage} isActive />

              {shouldShowRightBoundaryTiles && <RightBoundaryTiles />}
            </>
          )}

          {showFirstAndLastPage && shouldShowLastTile && lastPage && (
            <>
              {shouldShowEndingEllipsis && (
                <span
                  className={`relative -ml-px inline-flex items-center rounded-full px-3.5 text-sm font-bold leading-5 text-gray-700 `}
                >
                  ...
                </span>
              )}

              <AppletPaginationButton
                page={lastPage}
                onNextWithPageNumber={onNextWithPageNumber}
              />
            </>
          )}

          {!isLastPage && (
            <button
              className="relative inline-flex items-center rounded-full px-2 py-2 text-sm font-medium leading-5 text-black transition duration-150 ease-in-out hover:bg-white focus:z-10 focus:bg-white focus:outline-none active:bg-gray-100 active:text-gray-500"
              aria-label="Next"
              onClick={() => onNext()}
              onKeyPress={({ key }) => {
                if (key === 'Enter') {
                  onNext();
                }
              }}
            >
              {/* <!-- Heroicon name: chevron-right --> */}
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
        </>
      )}
    </nav>
  );
};

export default PaginationFooter;
