export enum Contract_Id {
  DueDate = '56c76604-553d-4cdf-94c9-d19d2dedcd1d',
  Assignable = 'adf036f9-0959-49ae-befc-32601e291845',
  Reminder = 'e2b1b72b-9d58-4d4e-8a2a-0e45326e5cf0',
  CompletedTask = 'f89e6d3c-43c1-4191-9af6-d2bddb84a4f0',
  Shop = '8df8757e-cef6-4291-9344-2947b572bde2',
  Department = '417bd263-32e8-4838-a4d4-6c1a89405373',
  Product = '6de7370d-9422-4310-8e3d-38f53c5805f0',
  Tags = 'd0a82a6e-0402-410f-8abb-82b18729a239',
  Owner = 'fe6399e4-d3a6-4b5d-b483-ed5629bed55f',
}
