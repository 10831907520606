import { IGetTaskSortEnum } from '@tapestry/shared/graphql';
import {
  IsoString,
  MeasureSlug,
  MEASURE_SLUGS,
  THREAD_TYPE,
  THREAD_TYPES,
  HeartbeatComparisonQueriesVariable,
} from '@tapestry/types';
import isEmpty from 'lodash/isEmpty';
import {
  encodeString,
  decodeString,
  encodeJson,
  decodeJson,
} from 'use-query-params';

const TASKS_SORT_OPTIONS = Object.values(IGetTaskSortEnum);
/**
 * We are using `use-query-params` libray
 * @see https://github.com/pbeshai/use-query-params
 *
 * This module adds more utilities to the base library for our use-cases
 */

//
// Encoding / Decoding function
//
/**
 * use to encode/decode typescript enums
 */
function decodeEnum<T extends string>(
  input: string | (string | null)[] | null | undefined,
  enumValues: T[]
): T | null | undefined {
  const str = decodeString(input);

  if (str == null) {
    return null;
  }

  return enumValues.includes(str as any) ? (str as T) : undefined;
}

//
// Custom params
// enables us to receive the correct TS type
//
export const ThreadTypeParam = {
  encode: (threadType: THREAD_TYPE | null | undefined) =>
    encodeString(threadType),

  decode: (encodedString: string | (string | null)[] | null | undefined) =>
    decodeEnum<THREAD_TYPE>(encodedString, THREAD_TYPES),
};

/**
 * Used in the Tasks applet for the sorting options
 */
export const TasksSortParam = {
  encode: (sortBy: IGetTaskSortEnum | null | undefined) => encodeString(sortBy),

  decode: (encodedString: string | (string | null)[] | null | undefined) =>
    decodeEnum<IGetTaskSortEnum>(encodedString, TASKS_SORT_OPTIONS),
};

export const MeasureParam = {
  encode: (measureSlug: MeasureSlug | null | undefined) =>
    encodeString(measureSlug),

  decode: (encodedString: string | (string | null)[] | null | undefined) =>
    decodeEnum<MeasureSlug>(encodedString, MEASURE_SLUGS),
};

export const ComparisonVariablesParam = {
  encode: (
    comparisonVars: HeartbeatComparisonQueriesVariable | null | undefined
  ) => encodeJson(comparisonVars),

  decode: (
    encodedCompVars: string | (string | null)[] | null | undefined
  ): HeartbeatComparisonQueriesVariable => decodeJson(encodedCompVars),
};

export const IsostringParam = {
  encode: (isoString: IsoString | null | undefined) => encodeString(isoString),

  decode: (encodedString: string | (string | null)[] | null | undefined) =>
    decodeString(encodedString),
};

export const ReturnToParam = {
  // encode this: /products?groupId=8114733b-10fc-4c6e-861e-dba80714607c&sort=a_z
  // to this: %2Fproducts%3FgroupId%3D8114733b-10fc-4c6e-861e-dba80714607c%26sort%3Da_z
  encode: (url: string | null | undefined) => {
    if (!url) return null;

    return encodeURIComponent(url);
  },

  // `decodeURI` has been used here instead of `decodeURIComponent` because we are decoding path segments as well as query params
  // When testing, it seems that decodeURIComponent would not work properly
  decode: (encodedUrl: string | (string | null)[] | null | undefined) => {
    if (!encodedUrl) return undefined;

    if (Array.isArray(encodedUrl)) {
      // if null array return undefined
      if (isEmpty(encodedUrl.filter(Boolean))) {
        return undefined;
      }

      return encodedUrl.map((value) => (value ? decodeURI(value) : null))[0];
    }

    return decodeURI(encodedUrl);
  },
};

// re-exports the whole library
export * from 'use-query-params';
