import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useRef,
  useState,
} from 'react';
import { useDialog } from 'react-aria';
import { ModalContextValue } from './types';
import { ModalState } from '..';

const ModalContext = createContext<ModalContextValue | undefined>(undefined);

interface IModalContextProviderProps {
  children: ReactNode;
  modalTriggerState: ModalState;
}

export const ModalContextProvider: FC<IModalContextProviderProps> = ({
  children,
  modalTriggerState,
}) => {
  // TODO[low]: Not currently passing any props (AriaDialogProps) - do we want to?
  const dialogRef = useRef(null);
  const ariaProps = useDialog({}, dialogRef);

  const [isBottomOfScrollContainer, setIsBottomOfScrollContainer] =
    useState(true);

  const state = {
    modalTriggerState,
    dialogRef,
    ...ariaProps,
    isBottomOfScrollContainer,
    setIsBottomOfScrollContainer,
  };

  return (
    <ModalContext.Provider value={state}>{children}</ModalContext.Provider>
  );
};

/**
 * Hook to access the mobile Menu context
 */
export const useModalContext = (): ModalContextValue => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error(
      'useModalContext must be used within a ModalContextProvider'
    );
  }

  return context;
};
