import type { FC } from 'react';
import {
  FormInputBase,
  IFormInputBaseProps,
} from '../FormInputBase/FormInputBase';
import { TimePicker } from '../TimePicker';
import { ITimePicker } from '../TimePicker/TimePicker';

interface FormDatePickProps extends IFormInputBaseProps, ITimePicker {
  name: string;
}

export const FormTimePicker: FC<FormDatePickProps> = (props) => {
  return (
    <FormInputBase {...props}>
      <TimePicker {...props} />
    </FormInputBase>
  );
};
