import React from 'react';
import { Circle, MainHeading, Panel, SliderTray } from '@tapestry/weave';
import { mapThreadTypeToThreadIcon } from '@tapestry/shared/utils';
import {
  Dairy1,
  Dairy5,
  Deli3,
  ExternalLinkIcon,
  Grocery1,
  Meat2,
} from '@tapestry/shared/icons';
import Skeleton from 'react-loading-skeleton';
import { THREAD_TYPE } from '@tapestry/types';
import shuffle from 'lodash/shuffle';

const FAKE_SELLS_WITH = [
  { name: 'Penne', img: Grocery1, price: '$2' },
  { name: 'Bruschetta', img: Meat2, price: '$8.45kg' },
  { name: 'Bacon', img: Deli3, price: '$3.45' },
  { name: 'a2 Milk', img: Dairy1, price: '$5.45' },
  { name: 'Greek yoghurt', img: Dairy5, price: '$4.98' },
];

const SellWithCard = ({ item: { name, price, img }, threadType }) => {
  const Icon = mapThreadTypeToThreadIcon(threadType);

  return (
    <div
      className={`font-base min-w-56 border-gray-lighter bg-gray-lighter relative flex h-64 flex-col justify-between  rounded-lg border bg-cover bg-center bg-no-repeat p-4 text-left text-xs ${
        img ? 'text-white' : 'text-white'
      }`}
      style={{ backgroundImage: `url("${img}")` }}
    >
      {/* overlay */}
      <div className="absolute inset-0 rounded-lg bg-black opacity-25" />

      <div className="relative flex h-full w-full flex-col justify-between">
        <Circle className="self-end bg-white">
          <ExternalLinkIcon />
        </Circle>

        <div>
          <Circle className="bg-pink">
            <Icon fillColor="#fff" />
          </Circle>
          <h6 className="mt-2 capitalize">{name}</h6>
          <p>{price}</p>
        </div>
      </div>
    </div>
  );
};

export const SellWellWithWidget = ({
  items = FAKE_SELLS_WITH,
  isLoading = false,
}) => {
  return (
    <Panel noHorizontalPadding>
      <div className="mb-4" id="top-selling">
        <MainHeading>Commonly Sold With</MainHeading>
      </div>
      <SliderTray>
        {isLoading
          ? [...Array(4).keys()].map((each) => (
              <Skeleton
                key={each}
                height={224}
                width={220}
                className="rounded-lg"
              />
            ))
          : shuffle(items).map((each, idx) => (
              <SellWithCard
                key={`${each.name}-${idx}`}
                item={each}
                threadType={THREAD_TYPE.PRODUCT}
              />
            ))}
      </SliderTray>
    </Panel>
  );
};
