import {
  ComboBoxOptionItem,
  IComboboxProps,
  ItemIconProp,
} from '@tapestry/types';

export const defaultLabelAccessor = (item: ComboBoxOptionItem) => {
  return item.label;
};

export const resolveLabelProp = (label: IComboboxProps['label']) => {
  return typeof label === 'string' ? label : label.label;
};

export const getItemIcon = (
  item: ComboBoxOptionItem,
  itemIcon: ItemIconProp | undefined | null
) => {
  if (!itemIcon) return null;

  if (typeof itemIcon === 'function') {
    return itemIcon(item);
  }

  return itemIcon;
};
