import { useOverlayTriggerState } from '@react-stately/overlays';
import { OverlayTriggerProps } from '@react-types/overlays';

/**
 * Wrapper for the `useOverlayTriggerState` from `@react-stately/overlays` as it was annoying to remember the name + import
 *
 * use it to control your modal state
 *
 * @example
 * const MyComponent = () => {
 *  const myModal = useModal()
 *
 *  const handleOpen = () => {
 *   onboardingModalState.open()
 *  }
 *
 *  return (
 *    {myModal.isOpen && (
 *      <Modal />
 *    )}
 *  )
 * }
 */
export const useModal = (props: OverlayTriggerProps = {}) => {
  return useOverlayTriggerState(props);
};
