import { CodeScannerProductNotFound } from './CodeScannerProductNotFound';
import {
  ISortByEnum,
  useGetPaginatedThreadsLazyQuery,
} from '@tapestry/shared/graphql';
import { useRouter } from 'next/router';
import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { THREAD_TYPE } from '@tapestry/types';
import { encodeObject } from 'serialize-query-params';
import { useState } from 'react';
import { NavBarShopSelector } from '../navbar-search';
import { CodeScannerThreadError } from './CodeScannerThreadError';
import dynamic from 'next/dynamic';
import { trackEvent } from '@tapestry/shared/utils';
const DynamicScanner = dynamic(() =>
  import('./Scanner/Scanner').then((mod) => mod.CodeScanner)
);

export const ScannerPage = () => {
  const router = useRouter();
  const [productNotFound, setProductNotFound] = useState(false);

  const [getThreads, { loading: isLookingForThread, error: hasThreadError }] =
    useGetPaginatedThreadsLazyQuery({
      onCompleted({ threadPaginationV6 }) {
        const productId = threadPaginationV6?.data?.[0]?.id;

        if (!productId) {
          setProductNotFound(true);

          trackEvent({
            event: 'CodeScanner',
            category: 'code_scanner',
            action: 'scanner_product_not_found',
            label: String(productId),
          });

          return;
        }

        router.push(
          ROUTE_PATHS.build.heartbeat({
            threadType: THREAD_TYPE.PRODUCT,
            qp: encodeObject(
              {
                productId,
              },
              '=',
              '&'
            ),
          })
        );
      },
    });

  function onScanSuccess(decodedText) {
    getThreads({
      variables: {
        filters: { thread_type: 'product' },
        search: decodedText,
        extraData: false,
        limit: 1,
        page: 1,
        sortBy: ISortByEnum.Relevant,
      },
    });
  }

  const onCancel = () => {
    router.back();
  };

  if (!productNotFound) {
    return (
      <main>
        <div className="fixed top-0 z-10 mt-4 flex w-full justify-center px-4">
          <div className="bg-primary inline-block rounded-full px-4">
            <NavBarShopSelector inHeader invertTextColor />
          </div>
        </div>
        <DynamicScanner onScanSuccess={onScanSuccess} onCancel={onCancel} />
      </main>
    );
  }

  return (
    <main className="text-gray-text flex items-center justify-center px-4 sm:px-0">
      {isLookingForThread && (
        <p>Searching your database for the corresponding product...</p>
      )}

      {hasThreadError && <CodeScannerThreadError />}

      {productNotFound && (
        <CodeScannerProductNotFound
          handleGoBack={router.back}
          setProductNotFound={setProductNotFound}
        />
      )}
    </main>
  );
};
