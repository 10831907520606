import React from 'react';
import { HeartbeatChartPeriod, ROUTE_PATHS } from '@tapestry/shared/constants';
import { THREAD_TYPE, Twist } from '@tapestry/types';
import { IThreadV4Type } from '@tapestry/shared/graphql';
import { MessageIcon } from '@tapestry/shared/icons';
import {
  getTwistByKey,
  mapThreadTypeToThreadIcon,
} from '@tapestry/shared/utils';
import { MoreButtonEllipsis } from '../more-button-ellipsis/MoreButtonEllipsis';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ThreadTypeToThemeColorMapper } from '@tapestry/shared/client';
import {
  useActiveShopScopeParams,
  useURIReturnPath,
} from '@tapestry/shared/hooks';
import Skeleton from 'react-loading-skeleton';
import { AnalyseButton, Circle, useAppMediaQuery, Pill } from '@tapestry/weave';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { twMerge } from 'tailwind-merge';
import Link from 'next/link';

export const SimpleThreadListItem = ({
  item,
  asDiv = false,
  isLoading = false,
  onMessageButtonClick,
}: {
  item: Maybe<IThreadV4Type>;
  asDiv?: boolean;
  isLoading?: boolean;
  onMessageButtonClick?: () => void;
}) => {
  const { threadSummary: hasThreadSummaryFlag } = useFlags();

  const { isPhone } = useAppMediaQuery();
  const searchPageReturnPath = useURIReturnPath();
  const activeScope = useActiveShopScopeParams();
  const isGroupMode = !!activeScope?.groupId;

  const ownerShopTitle =
    getTwistByKey(Twist.Shop, item)?.extraData?.title ?? null;
  const ELLIPSIS_MENU_ITEMS = [];
  const threadType = item?.threadType;
  const FieldIcon = mapThreadTypeToThreadIcon(threadType as THREAD_TYPE);
  const iconBackgroundColor = threadType
    ? ThreadTypeToThemeColorMapper[threadType]?.iconBackgroundColor ||
      ThreadTypeToThemeColorMapper[threadType]?.backgroundColor
    : 'bg-orange';
  const textColor = threadType
    ? ThreadTypeToThemeColorMapper[threadType]?.textColor
    : 'text-orange';

  const defaultHeartbeatDuration =
    threadType === THREAD_TYPE.DEPARTMENT
      ? ''
      : `&duration=${HeartbeatChartPeriod.TrailingMonth}`;

  const threadLinkUrl = React.useMemo(() => {
    if (
      !item?.id ||
      (threadType && [THREAD_TYPE.STAFF].includes(threadType as THREAD_TYPE))
    ) {
      return '#';
    }

    const queryParams =
      threadType === THREAD_TYPE.COLLECTION ? { collection: 1 } : {};

    return ROUTE_PATHS.build.threadSummary(item?.id, queryParams);
  }, [item?.id, threadType]);

  const shouldDisableLink =
    (!hasThreadSummaryFlag && !asDiv) || threadLinkUrl === '#';

  return (
    <li className="focus-within:ring-primary list-none overflow-hidden rounded-md bg-white text-left focus-within:ring-1 sm:flex sm:items-center sm:justify-between">
      <div className="flex justify-between items-start ">
        <Link
          href={threadLinkUrl}
          aria-label={item?.title || ''}
          aria-describedby={`sr-link-description-${threadType}-${item?.title}`}
          className={twMerge(
            'flex flex-grow items-center p-4 pr-0 focus:outline-none sm:py-6',
            shouldDisableLink && 'cursor pointer-events-none'
          )}
        >
          {isLoading ? (
            <Skeleton circle height={32} width={32} />
          ) : (
            <div
              className={twMerge(
                'flex h-8 w-8 flex-shrink-0 items-center justify-center',
                'rounded-full p-2 sm:h-12 sm:w-12 sm:p-3',
                iconBackgroundColor
              )}
            >
              {threadType !== THREAD_TYPE.STAFF ? (
                <FieldIcon className="h-full w-auto" fillColor="#fff" />
              ) : (
                <span className={twMerge(textColor, 'font-bold')}>
                  {item?.title
                    ?.split(' ')
                    .map((token) => token[0])
                    .join('')}
                </span>
              )}
            </div>
          )}

          <p
            id={`sr-link-description-${threadType}-${item?.title}`}
            className="sr-only"
          >
            If clicked, you will be redirected to the thread view for this $
            {threadType}
          </p>

          <div className="ml-4 sm:ml-6 ">
            <h6 className=" flex items-start justify-between text-base font-bold md:text-lg">
              {isLoading ? (
                <Skeleton width={isPhone ? 100 : 300} />
              ) : (
                item?.title
              )}
            </h6>

            {ownerShopTitle && isGroupMode && (
              <Pill
                label={ownerShopTitle}
                textColor="text-black"
                bgColor="bg-gray-200"
                className="mt-2 hidden w-max text-left sm:block"
              />
            )}
          </div>
        </Link>
        <Link
          title="View this product in Heartbeat"
          href={ROUTE_PATHS.build.heartbeat({
            threadType,
            qp: `${threadType === 'collection' ? 'tag' : threadType}Id=${
              threadType === 'collection' ? item?.title : item?.id
            }${defaultHeartbeatDuration}&returnTo${searchPageReturnPath}`,
          })}
          className="p-4 sm:hidden"
        >
          <AnalyseButton variant="icon" />
        </Link>
      </div>

      {ownerShopTitle && isGroupMode && (
        <div className="flex justify-end sm:hidden">
          <Pill
            label={ownerShopTitle}
            textColor="text-black"
            bgColor="bg-gray-200"
          />
        </div>
      )}

      <div className="flex items-center justify-end space-x-2 p-4 pl-0 sm:justify-between sm:py-6 hidden sm:flex">
        {isLoading ? (
          <>
            <Skeleton circle height={40} width={40} className="mr-4" />
            <Skeleton circle height={40} width={40} />
          </>
        ) : (
          <>
            {onMessageButtonClick ? (
              <button className="group" onClick={onMessageButtonClick}>
                <Circle
                  size="h-8 w-8 p-2"
                  className="border-none bg-gray-200 transition-colors duration-150 ease-out group-hover:group-focus:bg-gray-300"
                >
                  <p className="sr-only">Open Message</p>
                  <MessageIcon />
                </Circle>
              </button>
            ) : null}

            <Link
              title={`View this ${threadType} in Heartbeat`}
              href={ROUTE_PATHS.build.heartbeat({
                threadType,
                qp: `${threadType === 'collection' ? 'tag' : threadType}Id=${
                  threadType === 'collection' ? item?.title : item?.id
                }${defaultHeartbeatDuration}&returnTo=${searchPageReturnPath}`,
              })}
            >
              <AnalyseButton />
              <span className="sr-only">
                View this {threadType} in heartbeat
              </span>
            </Link>
            <MoreButtonEllipsis items={ELLIPSIS_MENU_ITEMS} />
          </>
        )}
      </div>
    </li>
  );
};
