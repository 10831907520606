import { Env, Scopes } from '@tapestry/types';
import { IPermissionGateProps } from '@tapestry/types';
import isEmpty from 'lodash/isEmpty';

/**
 * Action / Utils / Functions Imports
 */

/**
 * Utils to check permission
 *
 * @param scopes - an array of scope for a feature
 * @param isDevMode - need to let the util know if it's devmode if you need to use it
 * @return `true | false`
 */
export const hasPermissions = (
  scopes: IPermissionGateProps['scopes'] | null,
  isDevMode?: boolean
) => {
  if (scopes === null) return false;
  if (isEmpty(scopes)) return true;

  return scopes.some((scope) => {
    // * enable on testins environmet support
    if (scope === Env.Test && String(process.env.NODE_ENV) === scope) {
      return true;
    }

    // * check to allow on a given environment
    if (scope === String(process.env.NEXT_PUBLIC_APP_ENV)) {
      return true;
    }

    // * Automatically enable all the prod-ready flag onto pre-release
    if (
      scope === Env.Production &&
      String(process.env.NEXT_PUBLIC_APP_ENV) === Env.PreRelease
    ) {
      return true;
    }

    // * Checks for dev mode
    if (scope === Scopes.devMode && isDevMode) {
      return true;
    }

    return false;
  });
};
