import React, { ReactElement, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import isEmpty from 'lodash/isEmpty';
import { LoadingSpinnerIcon } from '@tapestry/shared/icons';
import { ITag } from '@tapestry/shared/graphql';
import uuid from 'react-uuid';
import { FocusScope } from 'react-aria';
import { InputValueTag, TagInputTag } from './Tags';
import {
  FormInputBase,
  IFormInputBaseProps,
} from '../FormInputBase/FormInputBase';

interface ITagInputInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  iconLeft?: ReactElement;
  iconRight?: ReactElement;
}

interface ITagInputProps extends IFormInputBaseProps {
  value: ITag[];
  onChange: (tags: ITag[]) => void;
  onBlur?: (tags: ITag[]) => void;
  input?: ITagInputInputProps;
  isLoading?: boolean;
}

export const TagInput = React.forwardRef<HTMLInputElement, ITagInputProps>(
  (
    {
      value: tagList,
      input: inputProps = {},
      isLoading,
      onChange,
      ...restOfProps
    },
    ref
  ) => {
    const { iconLeft, iconRight } = inputProps;
    const [inputValue, setInputValue] = useState('');

    /**
     * Functions
     */
    const handleAddNewTag = (value: string) => {
      const newTag: ITag = {
        id: uuid(),
        name: value,
      };

      onChange([newTag, ...tagList]);
      setInputValue('');
    };

    const handleRemoveTag = (tagId: string) => {
      const newList = tagList.filter((currTag) => currTag.id !== tagId);
      onChange(newList);
    };

    return (
      <FormInputBase {...restOfProps}>
        {/* input */}
        <div className="border-gray-border relative rounded-md border bg-white py-4">
          {iconLeft && (
            <div className="min-w-8 pointer-events-none absolute left-0 top-1/2 ml-2 flex h-8 w-8 -translate-y-1/2 transform items-center justify-center sm:ml-5">
              {iconLeft}
            </div>
          )}

          <input
            ref={ref}
            value={inputValue}
            onChange={({ target }) => setInputValue(target.value)}
            className={twMerge(
              `placeholder-gray-DEFAULT focus:border-orange-hank sm:pr-18 w-full pr-10 text-base font-normal tracking-wider text-black focus:outline-none`,
              iconLeft ? 'pl-18' : 'pl-6'
            )}
            placeholder={inputProps?.placeholder || 'Type something...'}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
                e.preventDefault();
                handleAddNewTag(inputValue);
              }
            }}
            {...inputProps}
          />

          {isLoading && (
            <div className="sm:pr-18 absolute top-1/2 right-0 z-10 h-4 w-4 -translate-y-1/2 transform pr-10">
              <LoadingSpinnerIcon />
            </div>
          )}

          {iconRight && (
            <div className="min-w-8 pointer-events-none absolute left-0 top-1/2 ml-2 flex h-8 w-8 -translate-y-1/2 transform items-center justify-center sm:ml-5">
              {iconRight}
            </div>
          )}
        </div>

        <ul
          className={twMerge(
            'flex flex-row flex-wrap items-center space-x-4',
            inputValue || tagList.length ? 'mt-4' : 'mt-0'
          )}
        >
          <FocusScope>
            {inputValue && (
              <InputValueTag
                inputValue={inputValue}
                handleAddAsNewTag={handleAddNewTag}
              />
            )}

            {!isEmpty(tagList) &&
              tagList.map((tag) => (
                <TagInputTag
                  key={tag.id}
                  tag={tag}
                  handleRemoveTag={handleRemoveTag}
                />
              ))}
          </FocusScope>
        </ul>
      </FormInputBase>
    );
  }
);
