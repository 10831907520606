import { SpringValue, useSpring } from '@react-spring/web';
import React, { FC, useContext, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useOnClickOutside } from '../hooks/useOnClickOutside';

type DesktopMenuContextState = {
  showDropdown: boolean;
  springs: {
    opacity: SpringValue<number>;
    transform: SpringValue<string>;
  };
  rootRef: React.RefObject<HTMLDivElement>;
  openMenu: () => void;
  closeMenu: () => void;
};

const DesktopMenuContext = React.createContext<
  DesktopMenuContextState | undefined
>(undefined);

export const DesktopMenuContextProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const [springs, api] = useSpring(() => ({
    from: { opacity: 0, transform: 'scale(0.8)' },
    config: { tension: 200 },
  }));

  const { callback: debouncedHideDropdown } = useDebouncedCallback(() => {
    setShowDropdown(false);
  }, 500);

  const openMenu = () => {
    setShowDropdown(true);
    api.start({ opacity: 1, transform: 'scale(1)' });
  };

  const closeMenu = () => {
    api.start({ opacity: 0, transform: 'scale(0.8)' });
    debouncedHideDropdown();
  };

  // hides the menu if clicked outside
  useOnClickOutside(rootRef, closeMenu);

  const providerValue = {
    rootRef,
    showDropdown,
    springs,
    openMenu,
    closeMenu,
  };

  return (
    <DesktopMenuContext.Provider value={providerValue}>
      {children}
    </DesktopMenuContext.Provider>
  );
};

/**
 * Hook to access the DesktopMenuContext context
 */
export const useDesktopMenuContext = (): DesktopMenuContextState => {
  const context = useContext(DesktopMenuContext);

  if (context === undefined) {
    throw new Error(
      'useMobileMenuContext must be used within a DesktopMenuContextProvider'
    );
  }

  return context;
};
