import React, { FunctionComponent, ReactNode } from 'react';

interface IProps {
  children: ReactNode;
}

const SectionHeader: FunctionComponent<React.PropsWithChildren<IProps>> = ({
  children,
}) => {
  return (
    <div className="mb-4 flex items-center justify-between">{children}</div>
  );
};

export default SectionHeader;
