import {
  BasketValue,
  Profitability,
  Satisfaction,
  StockLevels,
  Transactions,
  ZeroStock,
  GrossMargin,
  TotalSales,
  BasketProfitIcon,
  BullhornIcon,
  UserGroupIcon,
  AvgWeeklySales,
  PercentageIcon,
  ArrowAboveDotIcon,
} from '@tapestry/shared/icons';
import {
  HankHLogoIcon,
  SellThroughRateIcon,
  ShrinkageIcon,
  StockProfitIcon,
  StockTurnIcon,
  ExchangeIcon,
  GraphMetricIcon,
  CalendarDayIcon,
  CalendarDayWeekIcon,
  LostSalesIcon,
  CubeIcon,
  UserCostIcon,
  NotepadBudgetIcon,
  BasketMarginIcon,
} from '@tapestry/shared/icons';
import {
  AGGREGATION_TYPE,
  IMetric,
  MeasureSlug,
  Visualization,
  THREAD_TYPE,
  ALL_VISUALIZATION,
} from '@tapestry/types';
import { HANK_UNI_URLS } from './hank-university-links';

// filtering utility
const topShopsOnSpaces = (viz: Visualization, thread: THREAD_TYPE) => {
  if (thread === THREAD_TYPE.SPACE) {
    return viz !== Visualization.TopShops;
  }

  return true;
};

export const METRICS: IMetric[] = [
  {
    key: MeasureSlug.TotalSales,
    title: 'Sales',
    mainLabel: 'Total Sales',
    unit: { symbol: '$', position: 'left' },
    icon: <TotalSales />,
    aggregationType: AGGREGATION_TYPE.SUM,
    enableFor: (thread, vis, vars) => {
      if (thread === THREAD_TYPE.PRODUCT) {
        return [
          Visualization.Main,
          Visualization.BestTimes,
          Visualization.SellWellWith,
          Visualization.Demographics,
          Visualization.TopShops,
          Visualization.TopSuppliers,
          Visualization.Postcodes,
          Visualization.ProductVariants,
        ];
      }

      if (thread === THREAD_TYPE.CATEGORY) {
        return [
          Visualization.Main,
          Visualization.BestTimes,
          Visualization.TopCollections,
          Visualization.TopSpaces,
          Visualization.TopProducts,
          Visualization.Demographics,
          Visualization.TopShops,
          Visualization.TopSuppliers,
          Visualization.Postcodes,
        ];
      }

      if (thread === THREAD_TYPE.DEMOGRAPHIC) {
        return [
          Visualization.Main,
          Visualization.TopDepartments,
          Visualization.TopProducts,
          Visualization.TopCategories,
          Visualization.Postcodes,
          Visualization.BestTimes,
        ];
      }

      if (thread === THREAD_TYPE.POSTCODE) {
        return [
          Visualization.Main,
          Visualization.TopDepartments,
          Visualization.TopProducts,
          Visualization.TopCategories,
          Visualization.Demographics,
          Visualization.BestTimes,
        ];
      }

      if (thread === THREAD_TYPE.STAFF) {
        return [
          Visualization.Main,
          Visualization.TopDepartments,
          Visualization.TopProducts,
          Visualization.TopCategories,
          Visualization.BestTimes,
        ];
      }

      return [
        Visualization.Main,
        Visualization.TopProducts,
        Visualization.TopDepartments,
        Visualization.TopCategories,
        Visualization.TopSpaces,
        Visualization.BestTimes,
        Visualization.TopCollections,
        Visualization.Demographics,
        Visualization.Postcodes,
        Visualization.TopShops,
        Visualization.TopSuppliers,
        Visualization.Staff,
        Visualization.Lists,
      ].filter((viz) => {
        if (thread === THREAD_TYPE.SPACE) {
          return viz !== Visualization.TopShops;
        }

        if (thread === THREAD_TYPE.LIST) {
          return viz !== Visualization.Lists;
        }

        if (thread === THREAD_TYPE.SUPPLIER || vars?.app === 'supply') {
          return viz !== Visualization.TopSuppliers;
        }

        return true;
      });
    },
    isExportable: [
      THREAD_TYPE.SHOP,
      THREAD_TYPE.LIST,
      THREAD_TYPE.PRODUCT,
      THREAD_TYPE.DEPARTMENT,
      THREAD_TYPE.SPACE,
      THREAD_TYPE.CATEGORY,
      THREAD_TYPE.COLLECTION,
      THREAD_TYPE.SUPPLIER,
      // * ADD GROUP in here in the future ?
    ],
    hankUniversityLink: HANK_UNI_URLS.metrics.sales,
  },
  {
    key: MeasureSlug.Transactions,
    title: 'Transactions',
    mainLabel: 'Total Orders',
    unit: { symbol: '' },
    icon: <Transactions />,
    aggregationType: AGGREGATION_TYPE.SUM,
    enableFor: (thread) => {
      if (thread === THREAD_TYPE.DEMOGRAPHIC) {
        return [
          Visualization.Main,
          Visualization.TopDepartments,
          Visualization.TopProducts,
          Visualization.TopCategories,
          Visualization.Postcodes,
          Visualization.BestTimes,
        ];
      }

      if (thread === THREAD_TYPE.POSTCODE) {
        return [
          Visualization.Main,
          Visualization.TopDepartments,
          Visualization.TopProducts,
          Visualization.TopCategories,
          Visualization.Demographics,
          Visualization.BestTimes,
        ];
      }

      if (thread === THREAD_TYPE.STAFF) {
        return [
          Visualization.Main,
          Visualization.TopDepartments,
          Visualization.TopProducts,
          Visualization.TopCategories,
          Visualization.BestTimes,
        ];
      }

      const allowed = [
        THREAD_TYPE.DEPARTMENT,
        THREAD_TYPE.CATEGORY,
        THREAD_TYPE.SPACE,
        THREAD_TYPE.SHOP,
        THREAD_TYPE.LIST,
      ];

      if (allowed.includes(thread)) {
        return ALL_VISUALIZATION.filter(
          (viz) =>
            viz !== Visualization.ProductVariants &&
            viz !== Visualization.SellWellWith
        );
      }

      return false;
    },
    isExportable: true,
    hankUniversityLink: HANK_UNI_URLS.metrics.transactions,
  },
  {
    key: MeasureSlug.UnitsSold,
    title: 'Units Sold',
    mainLabel: '(Qty) Sold',
    unit: { symbol: '' },
    icon: <Transactions />,
    aggregationType: AGGREGATION_TYPE.SUM,

    enableFor: (thread) => {
      if (thread === THREAD_TYPE.SUPPLIER) {
        return [
          Visualization.Main,
          Visualization.TopShops,
          Visualization.TopDepartments,
          Visualization.TopProducts,
          Visualization.TopCategories,
          Visualization.TopSpaces,
          Visualization.BestTimes,
          Visualization.Postcodes,
          Visualization.TopCollections,
        ];
      }

      return ALL_VISUALIZATION.filter(
        (viz) => viz !== Visualization.SellWellWith
      );
    },
    isExportable: true,
  },
  {
    key: MeasureSlug.SupplierMarketShare,
    title: 'Market Share',
    mainLabel: 'Market Share',
    icon: <ArrowAboveDotIcon />,
    aggregationType: AGGREGATION_TYPE.AVERAGE,
    unit: { symbol: '%', position: 'right' },
    enableFor: (thread, _, vars) => {
      if (
        vars?.app === 'supply' &&
        [THREAD_TYPE.SHOP, THREAD_TYPE.CATEGORY].includes(thread)
      ) {
        return [Visualization.Main];
      }

      return null;
    },
  },
  {
    key: MeasureSlug.AvgWeeklySales,
    title: 'Average Weekly Sales',
    mainLabel: 'Average Weekly Sales',
    icon: <AvgWeeklySales />,
    aggregationType: AGGREGATION_TYPE.AVERAGE,
    readonly: true,
    enableFor: (thread) => {
      if ([THREAD_TYPE.PRODUCT].includes(thread)) {
        return []; // don't display any visualizations but still allow the measure
      }

      return false;
    },
  },
  {
    key: MeasureSlug.Profitability,
    title: 'Gross Profit',
    mainLabel: 'Gross Profit',
    unit: { symbol: '$' },
    icon: <Profitability />,
    aggregationType: AGGREGATION_TYPE.SUM,
    enableFor: (thread) => {
      if (
        thread === THREAD_TYPE.COLLECTION ||
        thread === THREAD_TYPE.SUPPLIER
      ) {
        return [
          Visualization.Main,
          Visualization.BestTimes,
          Visualization.TopCategories,
          Visualization.TopDepartments,
          Visualization.TopSpaces,
        ];
      }

      if (thread === THREAD_TYPE.DEMOGRAPHIC) {
        return [
          Visualization.Main,
          Visualization.TopDepartments,
          Visualization.TopCategories,
          Visualization.TopProducts,
          Visualization.Postcodes,
          Visualization.BestTimes,
        ];
      }

      if (thread === THREAD_TYPE.POSTCODE) {
        return [
          Visualization.Main,
          Visualization.TopDepartments,
          Visualization.TopCategories,
          Visualization.TopProducts,
          Visualization.Demographics,
          Visualization.BestTimes,
        ];
      }

      if (thread === THREAD_TYPE.STAFF) {
        return [
          Visualization.Main,
          Visualization.TopDepartments,
          Visualization.TopProducts,
          Visualization.TopCategories,
          Visualization.BestTimes,
        ];
      }

      return ALL_VISUALIZATION.filter((viz) => {
        return (
          viz !== Visualization.SellWellWith &&
          viz !== Visualization.ProductVariants &&
          topShopsOnSpaces(viz, thread)
        );
      });
    },
    hankUniversityLink: HANK_UNI_URLS.metrics.grossProfit,
    // todo[low]: this should be considering shop timezone instead of +10:00 default
    zeroDataBeforeDate: '2021-08-15T00:00:00.000+10:00',
    isExportable: true,
  },

  {
    key: MeasureSlug.Margin,
    title: 'Gross Margin',
    mainLabel: 'Gross Margin',
    unit: { symbol: '%', position: 'right' },
    helperText:
      'The total Gross Margin (%) is produced as an average of the individual days across the period.',
    icon: <GrossMargin />,
    aggregationType: AGGREGATION_TYPE.AVERAGE,
    enableFor: (thread, vis, vars) => {
      const allowedThreads = [
        THREAD_TYPE.PRODUCT,
        THREAD_TYPE.DEPARTMENT,
        THREAD_TYPE.CATEGORY,
        THREAD_TYPE.SPACE,
        THREAD_TYPE.SHOP,
        THREAD_TYPE.LIST,
        THREAD_TYPE.COLLECTION,
        THREAD_TYPE.SUPPLIER,
      ];

      if (allowedThreads.includes(thread)) {
        return ALL_VISUALIZATION.filter(
          (viz) =>
            viz !== Visualization.SellWellWith &&
            viz !== Visualization.ProductVariants &&
            topShopsOnSpaces(viz, thread)
        );
      }

      if (thread === THREAD_TYPE.DEMOGRAPHIC) {
        return [
          Visualization.Main,
          Visualization.TopDepartments,
          Visualization.TopCategories,
          Visualization.TopProducts,
          Visualization.Postcodes,
          Visualization.BestTimes,
        ];
      }

      if (thread === THREAD_TYPE.POSTCODE) {
        return [
          Visualization.Main,
          Visualization.TopDepartments,
          Visualization.TopCategories,
          Visualization.TopProducts,
          Visualization.Demographics,
          Visualization.BestTimes,
        ];
      }

      if (thread === THREAD_TYPE.STAFF) {
        return [
          Visualization.Main,
          Visualization.TopDepartments,
          Visualization.TopProducts,
          Visualization.TopCategories,
          Visualization.BestTimes,
        ];
      }

      return false;
    },
    hankUniversityLink: HANK_UNI_URLS.metrics.grossMargin,
    // todo[low]: this should be considering shop timezone instead of +10:00 default
    zeroDataBeforeDate: '2021-08-15T00:00:00.000+10:00',
    isExportable: true,
  },
  {
    key: MeasureSlug.PercentageOfContribution,
    title: 'Category Contribution',
    mainLabel: 'Category Contribution',
    icon: <PercentageIcon />,
    aggregationType: AGGREGATION_TYPE.AVERAGE,
    unit: { symbol: '%', position: 'right' },
    readonly: true,
    enableFor: (thread) => {
      if (thread === THREAD_TYPE.PRODUCT) {
        return [Visualization.Main];
      }

      return false;
    },
  },
  {
    key: MeasureSlug.BasketValue,
    title: 'Basket Value',
    altTitle: 'Basket Contribution',
    altTitleCondition: (activeThread: THREAD_TYPE): boolean => {
      return activeThread === THREAD_TYPE.PRODUCT;
    },
    mainLabel: 'Avg. Basket Value',
    unit: { symbol: '$' },
    icon: <BasketValue />,
    aggregationType: AGGREGATION_TYPE.AVERAGE,
    enableFor: (thread, viz, vars) => {
      if (vars?.app === 'supply') return null;

      const allowedThread = [
        THREAD_TYPE.SHOP,
        THREAD_TYPE.LIST,
        THREAD_TYPE.COLLECTION,
        THREAD_TYPE.DEMOGRAPHIC,
        THREAD_TYPE.POSTCODE,
        THREAD_TYPE.STAFF,
      ];

      if (allowedThread.includes(thread)) {
        return [Visualization.Main, Visualization.BestTimes];
      }

      return null;
    },
    hankUniversityLink: HANK_UNI_URLS.metrics.basketValue,
    dimensionsLabels: {
      bestTimes: 'Basket Value Across the Period',
      topDepartments: 'Basket Value by Department',
    },
    isExportable: false,
  },
  {
    key: MeasureSlug.BasketMargin,
    title: 'Basket Margin',
    mainLabel: 'Avg. Basket Margin',
    unit: { symbol: '%', position: 'right' },
    icon: <BasketMarginIcon />,
    aggregationType: AGGREGATION_TYPE.AVERAGE,
    enableFor: (thread, viz, vars) => {
      if (vars?.app === 'supply') return null;

      if (thread === THREAD_TYPE.SHOP || thread === THREAD_TYPE.LIST) {
        return [Visualization.Main, Visualization.BestTimes];
      }

      return null;
    },
    hankUniversityLink: HANK_UNI_URLS.metrics.basketMargin,
    dimensionsLabels: {
      bestTimes: 'Basket Margin Across the Period',
      topDepartments: 'Basket Margin by Department',
    },
    isExportable: false,
  },
  {
    key: MeasureSlug.BasketProfit,
    title: 'Basket Profit',
    mainLabel: 'Avg. Basket Profit',
    unit: { symbol: '$' },
    icon: <BasketProfitIcon />,
    aggregationType: AGGREGATION_TYPE.AVERAGE,
    enableFor: (thread, viz, vars) => {
      if (vars?.app === 'supply') return null;

      if (
        thread === THREAD_TYPE.SHOP ||
        thread === THREAD_TYPE.LIST ||
        thread === THREAD_TYPE.DEMOGRAPHIC ||
        thread === THREAD_TYPE.POSTCODE ||
        thread === THREAD_TYPE.STAFF
      ) {
        return [Visualization.Main, Visualization.BestTimes];
      }

      return null;
    },
    hankUniversityLink: HANK_UNI_URLS.metrics.basketProfit,
    dimensionsLabels: {
      bestTimes: 'Basket Profit Across the Period',
      topDepartments: 'Basket Profit by Department',
    },
    isExportable: false,
  },
  {
    key: MeasureSlug.AvgBasketContributionValue,
    title: 'Basket Sale Contribution',
    mainLabel: 'Basket Sale Contribution',
    unit: { symbol: '$', position: 'left' },
    icon: <BasketProfitIcon />,
    aggregationType: AGGREGATION_TYPE.AVERAGE,
    enableFor: (thread, viz, vars) => {
      if (vars?.app === 'supply') return null;

      if (
        [
          THREAD_TYPE.DEPARTMENT,
          THREAD_TYPE.CATEGORY,
          THREAD_TYPE.PRODUCT,
        ].includes(thread)
      ) {
        return [
          Visualization.Main,
          Visualization.BestTimes,
          Visualization.TopShops,
        ];
      }

      return null;
    },
    hankUniversityLink: HANK_UNI_URLS.metrics.basketSaleContributionDollar,
    dimensionsLabels: {
      bestTimes: 'Basket Sale Contribution Across the Period',
    },
    isExportable: false,
  },
  {
    key: MeasureSlug.AvgBasketContribution,
    title: 'Basket Sale Contribution',
    mainLabel: 'Basket Sale Contribution',
    hankUniversityLink: HANK_UNI_URLS.metrics.basketSaleContributionPercent,
    unit: { symbol: '%', position: 'right' },
    icon: <BasketProfitIcon />,
    aggregationType: AGGREGATION_TYPE.AVERAGE,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;

      if (
        [
          THREAD_TYPE.DEPARTMENT,
          THREAD_TYPE.CATEGORY,
          THREAD_TYPE.PRODUCT,
        ].includes(thread)
      ) {
        return [
          Visualization.Main,
          Visualization.BestTimes,
          Visualization.TopShops,
        ];
      }

      return null;
    },
    dimensionsLabels: {
      bestTimes: 'Basket Sale Contribution Across the Period',
    },
    isExportable: false,
  },
  {
    key: MeasureSlug.TotalCostPrice,
    title: 'Total Cost Price',
    mainLabel: 'Total Cost Price',
    unit: { symbol: '$' },
    icon: <NotepadBudgetIcon />,
    aggregationType: AGGREGATION_TYPE.SUM,
    enableFor: (activeThread) => {
      const enableViz = [
        Visualization.Main,
        Visualization.TopProducts,
        Visualization.TopCategories,
      ];

      if (
        [
          THREAD_TYPE.COLLECTION,
          THREAD_TYPE.SUPPLIER,
          THREAD_TYPE.DEPARTMENT,
        ].includes(activeThread)
      ) {
        return enableViz;
      }

      if (activeThread === THREAD_TYPE.PRODUCT) {
        return enableViz.filter((viz) => viz !== Visualization.TopProducts);
      }

      if (activeThread === THREAD_TYPE.CATEGORY) {
        return enableViz.filter((viz) => viz !== Visualization.TopCategories);
      }

      return false;
    },
    isExportable: true,
  },
  {
    key: MeasureSlug.OnPromotion,
    title: 'Sld On Promo',
    mainLabel: 'Sld On Promo',
    unit: { symbol: '$' },
    icon: <BullhornIcon />,
    aggregationType: AGGREGATION_TYPE.SUM,
    enableFor: [
      THREAD_TYPE.PRODUCT,
      THREAD_TYPE.DEPARTMENT,
      THREAD_TYPE.CATEGORY,
      THREAD_TYPE.SPACE,
      THREAD_TYPE.SHOP,
      THREAD_TYPE.LIST,
    ],
    hankUniversityLink: HANK_UNI_URLS.metrics.soldOnPromotionDollar,
  },
  {
    key: MeasureSlug.PromotionalMixOfSales,
    title: 'Sld On Promo',
    mainLabel: 'Sld On Promo',
    unit: { symbol: '%', position: 'right' },
    icon: <BullhornIcon />,
    aggregationType: AGGREGATION_TYPE.AVERAGE,
    enableFor: (thread) => {
      const allowed = [
        THREAD_TYPE.PRODUCT,
        THREAD_TYPE.DEPARTMENT,
        THREAD_TYPE.CATEGORY,
        THREAD_TYPE.SHOP,
        THREAD_TYPE.LIST,
        THREAD_TYPE.COLLECTION,
        THREAD_TYPE.SUPPLIER,
      ];

      if (allowed.includes(thread)) {
        return ALL_VISUALIZATION.filter(
          (viz) => viz !== Visualization.ProductVariants
        );
      }

      if (thread === THREAD_TYPE.DEMOGRAPHIC) {
        return [
          Visualization.Main,
          Visualization.TopDepartments,
          Visualization.TopCategories,
          Visualization.TopProducts,
          Visualization.Postcodes,
          Visualization.BestTimes,
        ];
      }

      if (thread === THREAD_TYPE.POSTCODE) {
        return [
          Visualization.Main,
          Visualization.TopDepartments,
          Visualization.TopCategories,
          Visualization.TopProducts,
          Visualization.Demographics,
          Visualization.BestTimes,
        ];
      }

      if (thread === THREAD_TYPE.STAFF) {
        return [
          Visualization.Main,
          Visualization.TopDepartments,
          Visualization.TopProducts,
          Visualization.TopCategories,
          Visualization.BestTimes,
        ];
      }

      return null;
    },
    hankUniversityLink: HANK_UNI_URLS.metrics.soldOnPromotionPercent,
    isExportable: true,
  },
  {
    key: MeasureSlug.SalesPerSq,
    title: 'Sales Per SQM',
    mainLabel: '',
    unit: { symbol: '$' },
    icon: <CubeIcon fillColor="#666565" />,
    aggregationType: AGGREGATION_TYPE.AVERAGE,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;

      if (thread !== THREAD_TYPE.SHOP && thread !== THREAD_TYPE.LIST) {
        return null;
      }

      return [Visualization.Main];
    },
    variants: [
      {
        label: 'Selling Area',
        mobileLabel: 'Sell. Area',
        slug: MeasureSlug.SalesPerSq,
      },
      {
        label: 'Land Area',
        mobileLabel: 'Land Area',
        slug: 'sales_per_sq_land_area',
      },
      {
        label: 'Gross Lettable Area',
        mobileLabel: 'G.L. Area',
        slug: 'sales_per_sq_gross_letting',
      },
    ],
    hankUniversityLink: HANK_UNI_URLS.metrics.salesPerSQM,
  },
  {
    key: MeasureSlug.SalesPerLabourHour,
    title: 'Sls / Labour Hr',
    mainLabel: '',
    unit: { symbol: '$' },
    icon: <UserCostIcon fillColor="#ED7725" />,
    aggregationType: AGGREGATION_TYPE.AVERAGE,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;

      if (
        ![THREAD_TYPE.SHOP, THREAD_TYPE.LIST, THREAD_TYPE.STAFF].includes(
          thread
        )
      ) {
        return false;
      }

      return [Visualization.Main];
    },
    hankUniversityLink: HANK_UNI_URLS.metrics.salesPerLabourHour,
  },
  {
    key: MeasureSlug.TotalWageCost,
    title: 'T- Wage Cost',
    mainLabel: '',
    unit: { symbol: '$' },
    icon: <UserCostIcon />,
    aggregationType: AGGREGATION_TYPE.SUM,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;

      if (
        ![THREAD_TYPE.SHOP, THREAD_TYPE.LIST, THREAD_TYPE.STAFF].includes(
          thread
        )
      ) {
        return null;
      }

      return [Visualization.Main];
    },
    hankUniversityLink: HANK_UNI_URLS.homepage.homepage,
    isExportable: false,
  },
  {
    key: MeasureSlug.TotalWageHours,
    title: 'T- Wage Hours',
    mainLabel: '',
    icon: <UserGroupIcon />,
    aggregationType: AGGREGATION_TYPE.SUM,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;

      if (thread !== THREAD_TYPE.SHOP && thread !== THREAD_TYPE.LIST) {
        return null;
      }

      return [Visualization.Main];
    },
    hankUniversityLink: HANK_UNI_URLS.homepage.homepage,
    isExportable: false,
  },
  {
    key: MeasureSlug.StockLevel,
    title: 'Stock Levels',
    mainLabel: '',
    icon: <StockLevels />,
    aggregationType: AGGREGATION_TYPE.SUM,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;

      return thread === THREAD_TYPE.PRODUCT ? [Visualization.Main] : null;
    },
  },
  {
    key: MeasureSlug.Satisfaction,
    title: 'Satisfaction',
    mainLabel: '',
    icon: <Satisfaction />,
    aggregationType: AGGREGATION_TYPE.SUM,
    disabled: true,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;
      const allowedThread = [
        THREAD_TYPE.PRODUCT,
        THREAD_TYPE.DEPARTMENT,
        THREAD_TYPE.CATEGORY,
        THREAD_TYPE.SPACE,
        THREAD_TYPE.SHOP,
        THREAD_TYPE.LIST,
      ];

      return allowedThread.includes(thread) ? true : false;
    },
  },

  {
    key: MeasureSlug.ZeroStockItems,
    title: 'Zero Stock',
    mainLabel: '',
    icon: <ZeroStock />,
    aggregationType: AGGREGATION_TYPE.SUM,
    disabled: true,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;
      const allowedThread = [
        THREAD_TYPE.PRODUCT,
        THREAD_TYPE.DEPARTMENT,
        THREAD_TYPE.CATEGORY,
        THREAD_TYPE.SPACE,
        THREAD_TYPE.SHOP,
        THREAD_TYPE.LIST,
      ];

      return allowedThread.includes(thread) ? true : false;
    },
  },
  {
    key: MeasureSlug.WeightedAvgMarketPrice,
    title: 'Weighted Average Market Price',
    mainLabel: '',
    icon: <HankHLogoIcon fillColor="#ED7725" />,
    aggregationType: AGGREGATION_TYPE.AVERAGE,
    disabled: true,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;
      const allowedThread = [THREAD_TYPE.PRODUCT];

      return allowedThread.includes(thread) ? true : false;
    },
  },
  {
    key: MeasureSlug.Volume,
    title: 'Volume (LTR / KG)',
    mainLabel: '',
    icon: <HankHLogoIcon fillColor="#ED7725" />,
    aggregationType: AGGREGATION_TYPE.SUM,
    disabled: true,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;
      const allowedThread = [
        THREAD_TYPE.PRODUCT,
        THREAD_TYPE.DEPARTMENT,
        THREAD_TYPE.CATEGORY,
        THREAD_TYPE.SPACE,
      ];

      return allowedThread.includes(thread) ? true : false;
    },
  },
  {
    key: MeasureSlug.RetailSalesValue,
    title: 'Retail Sales Value',
    mainLabel: '',
    icon: <HankHLogoIcon fillColor="#ED7725" />,
    aggregationType: AGGREGATION_TYPE.SUM,
    disabled: true,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;
      const allowedThread = [
        THREAD_TYPE.PRODUCT,
        THREAD_TYPE.DEPARTMENT,
        THREAD_TYPE.CATEGORY,
        THREAD_TYPE.SPACE,
      ];

      return allowedThread.includes(thread) ? true : false;
    },
  },
  {
    key: MeasureSlug.ReturnInvestment,
    title: 'ROI',
    mainLabel: '',
    icon: <ExchangeIcon fillColor="#ED7725" />,
    aggregationType: AGGREGATION_TYPE.SUM,
    disabled: true,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;
      const allowedThread = [
        THREAD_TYPE.PRODUCT,
        THREAD_TYPE.DEPARTMENT,
        THREAD_TYPE.CATEGORY,
        THREAD_TYPE.SPACE,
        THREAD_TYPE.SHOP,
        THREAD_TYPE.LIST,
      ];

      return allowedThread.includes(thread) ? true : false;
    },
  },
  {
    key: MeasureSlug.AvgInventoryLvl,
    title: 'Avg. Inventory Level',
    mainLabel: '',
    icon: <GraphMetricIcon />,
    aggregationType: AGGREGATION_TYPE.AVERAGE,
    disabled: true,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;
      const allowedThread = [
        THREAD_TYPE.PRODUCT,
        THREAD_TYPE.DEPARTMENT,
        THREAD_TYPE.CATEGORY,
        THREAD_TYPE.SPACE,
        THREAD_TYPE.SHOP,
        THREAD_TYPE.LIST,
      ];

      return allowedThread.includes(thread) ? true : false;
    },
  },
  {
    key: MeasureSlug.AvgDaysToSell,
    title: 'Avg. Days To Sell',
    mainLabel: '',
    icon: <CalendarDayIcon fillColor="#ED7725" />,
    aggregationType: AGGREGATION_TYPE.AVERAGE,
    disabled: true,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;
      const allowedThread = [
        THREAD_TYPE.PRODUCT,
        THREAD_TYPE.DEPARTMENT,
        THREAD_TYPE.CATEGORY,
        THREAD_TYPE.SPACE,
        THREAD_TYPE.SHOP,
        THREAD_TYPE.LIST,
      ];

      return allowedThread.includes(thread) ? true : false;
    },
  },
  {
    key: MeasureSlug.WeeksOnHand,
    title: 'Weeks On Hand',
    mainLabel: '',
    icon: <CalendarDayWeekIcon fillColor="#ED7725" />,
    aggregationType: AGGREGATION_TYPE.SUM,
    disabled: true,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;
      const allowedThread = [
        THREAD_TYPE.PRODUCT,
        THREAD_TYPE.DEPARTMENT,
        THREAD_TYPE.CATEGORY,
        THREAD_TYPE.SPACE,
      ];

      return allowedThread.includes(thread) ? true : false;
    },
  },
  {
    key: MeasureSlug.LostSales,
    title: 'Lost Sales',
    mainLabel: '',
    icon: <LostSalesIcon fillColor="#ED7725" />,
    aggregationType: AGGREGATION_TYPE.SUM,
    disabled: true,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;
      const allowedThread = [
        THREAD_TYPE.PRODUCT,
        THREAD_TYPE.DEPARTMENT,
        THREAD_TYPE.CATEGORY,
        THREAD_TYPE.SPACE,
        THREAD_TYPE.SHOP,
        THREAD_TYPE.LIST,
      ];

      return allowedThread.includes(thread) ? true : false;
    },
  },
  {
    key: MeasureSlug.StockTurn,
    title: 'Stock Turn',
    mainLabel: '',
    icon: <StockTurnIcon fillColor="#ED7725" />,
    aggregationType: AGGREGATION_TYPE.SUM,
    disabled: true,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;
      const allowedThread = [
        THREAD_TYPE.PRODUCT,
        THREAD_TYPE.DEPARTMENT,
        THREAD_TYPE.SPACE,
        THREAD_TYPE.SHOP,
        THREAD_TYPE.LIST,
      ];

      return allowedThread.includes(thread) ? true : false;
    },
  },
  {
    key: MeasureSlug.SellThroughRate,
    title: 'Sell Through Rate',
    mainLabel: '',
    icon: <SellThroughRateIcon fillColor="#ED7725" />,
    aggregationType: AGGREGATION_TYPE.SUM,
    disabled: true,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;
      const allowedThread = [
        THREAD_TYPE.PRODUCT,
        THREAD_TYPE.DEPARTMENT,
        THREAD_TYPE.CATEGORY,
        THREAD_TYPE.SPACE,
        THREAD_TYPE.SHOP,
        THREAD_TYPE.LIST,
      ];

      return allowedThread.includes(thread) ? true : false;
    },
  },
  {
    key: MeasureSlug.Shrinkage,
    title: 'Shrinkage',
    mainLabel: '',
    icon: <ShrinkageIcon fillColor="#ED7725" />,
    aggregationType: AGGREGATION_TYPE.SUM,
    disabled: true,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;
      const allowedThread = [
        THREAD_TYPE.PRODUCT,
        THREAD_TYPE.DEPARTMENT,
        THREAD_TYPE.CATEGORY,
        THREAD_TYPE.SPACE,
        THREAD_TYPE.SHOP,
        THREAD_TYPE.LIST,
      ];

      return allowedThread.includes(thread) ? true : false;
    },
  },
  {
    key: MeasureSlug.StockProfitOffset,
    title: 'Stock Profit (Offset)',
    mainLabel: '',
    icon: <StockProfitIcon fillColor="#ED7725" />,
    aggregationType: AGGREGATION_TYPE.SUM,
    disabled: true,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;
      const allowedThread = [
        THREAD_TYPE.PRODUCT,
        THREAD_TYPE.SHOP,
        THREAD_TYPE.LIST,
      ];

      return allowedThread.includes(thread) ? true : false;
    },
  },
  {
    title: 'Capture Rate',
    mainLabel: '',
    key: MeasureSlug.CaptureRate,
    icon: <UserGroupIcon fillColor="#ED7725" />,
    aggregationType: AGGREGATION_TYPE.AVERAGE,
    disabled: false,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;
      if (![THREAD_TYPE.DEMOGRAPHIC, THREAD_TYPE.POSTCODE].includes(thread))
        return null;

      return [Visualization.Main, Visualization.BestTimes];
    },
    unit: { symbol: '%', position: 'right' },
  },
  {
    key: MeasureSlug.ReturningVisitor,
    title: 'Returning Visitor',
    mainLabel: '',
    icon: <ExchangeIcon fillColor="#ED7725" />,
    aggregationType: AGGREGATION_TYPE.SUM,
    disabled: false,
    enableFor: (thread, vis, vars) => {
      if (vars?.app === 'supply') return null;
      if (![THREAD_TYPE.DEMOGRAPHIC, THREAD_TYPE.POSTCODE].includes(thread))
        return null;

      return [Visualization.Main, Visualization.BestTimes];
    },
  },
];

export default METRICS;
