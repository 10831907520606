import { ErrorBoundary } from '@tapestry/shared/client';
import { Checkbox, Modal, ModalState } from '@tapestry/weave';
import React, { FC, FormEventHandler } from 'react';
import { Control, Controller } from 'react-hook-form';

type FormFields =
  | (Record<string, unknown> & { setHasDefaultFilters: boolean })
  | { setHasDefaultFilters: boolean };

interface IAppletFilterModalWrapperProps {
  modalState: ModalState;
  handleResetFilters: VoidFunction;
  onSubmit: FormEventHandler<HTMLFormElement>;
  control: Control<FormFields, any>;
}

export const AppletFilterModalWrapper: FC<
  React.PropsWithChildren<IAppletFilterModalWrapperProps>
> = ({ children, handleResetFilters, modalState, onSubmit, control }) => {
  return (
    <ErrorBoundary errorView={null}>
      <Modal state={modalState}>
        <Modal.Dialog>
          <form onSubmit={onSubmit}>
            <Modal.Content>
              <Modal.Title>Filters</Modal.Title>

              <div className="space-y-8">{children}</div>
            </Modal.Content>

            <Modal.Footer justify="sm:justify-between" flipFlex>
              <Controller
                control={control}
                name="setHasDefaultFilters"
                render={({ field: { value, onChange } }) => (
                  <Checkbox defaultSelected={value} onChange={onChange}>
                    Set as default filters
                  </Checkbox>
                )}
              />

              <div className="space-y-4 sm:flex sm:flex-row-reverse sm:items-center sm:gap-4 sm:space-y-0">
                <Modal.Button type="submit" status="primary">
                  Apply Filters
                </Modal.Button>
                <Modal.Button onClick={handleResetFilters}>Reset</Modal.Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Dialog>
      </Modal>
    </ErrorBoundary>
  );
};
