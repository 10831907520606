import { Fullname } from '../utils-type/full-name';
import { IsoString } from '../utils-type/iso-string';

export enum ToastNotificationType {
  TaskCreated = 'taskCreated',
  TaskCompleted = 'taskCompleted',
  TaskOverdue = 'taskOverdue',
  taskDeleted = 'taskDeleted',
  Reminder = 'reminder',
  Assigned = 'assigned',
  Error = 'error',
  Info = 'info',
  Success = 'success',
}

export type ToastNotificationTypeOption =
  | 'taskCreated'
  | 'taskCompleted'
  | 'taskDeleted'
  | 'taskOverdue'
  | 'reminder'
  | 'assigned'
  | 'error'
  | 'info'
  | 'success';

// NOTE: Can you, once the dev has chosen the type of toast, inform him of the content it should receive? like string or isodate or name
export interface IToastNotification {
  id?: string;
  type: ToastNotificationTypeOption;
  content?: string | IsoString | Fullname | null;
  avatarUrl?: string;
}
