import React from 'react';
import { ExclamationCircleIcon } from '@tapestry/shared/icons';

export const MaintenanceBanner = () => {
  return (
    <div className="bg-gray fixed left-0 top-0 right-0">
      <div className="mx-auto flex max-w-6xl items-center justify-center py-3 px-3 text-center sm:px-6 lg:px-8">
        <ExclamationCircleIcon className="mr-2 h-6 w-6" fillColor="#fff" />
        <p className="font-medium text-white">
          We're currently doing maintenance. We will be back soon.
        </p>
      </div>
    </div>
  );
};
