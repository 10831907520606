import React from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * A simple low black opacity overlay
 *
 * Apply `position:relative` to parent and possible sibling if required
 * @param rounded - use to round the borders to fit parents
 */
export const Overlay = ({
  rounded,
  opacity = 'opacity-25',
}: {
  rounded?: `rounded-${string}`;
  opacity?: `opacity-${string}`;
}): JSX.Element => {
  return (
    <div className={twMerge('absolute inset-0 bg-black', opacity, rounded)} />
  );
};
