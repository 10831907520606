var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f1a5648e40d8d982511455fdeb3b41f55558af23"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { invariant } from 'ts-invariant';

/**
 * -- Replays --
 *
 * Sentry plan has a limit of 500 replays per months
 * that's 16 replays a day
 * As of 14 April 2023, we have 30 users
 * Sampling half would fill the quota
 * but we need to leave room for error Replay
 * might get 13 error replay / day
 */
const replaysSessionSampleRateByEnv = () => {
  if (
    process.env.NEXT_PUBLIC_APP_ENV === 'development' ||
    process.env.NODE_ENV === 'test'
  ) {
    return 0;
  }

  return 0.07;
};

/**
 * -- Trace Sample Rate --
 *
 * - Does not track on development
 * - Track half on staging and production
 */
const chooseTracesSampleRateByEnv = () => {
  switch (process.env.NEXT_PUBLIC_APP_ENV) {
    case 'development':
      return 0;

    default:
      return 0.5;
  }
};

invariant(
  process.env.NEXT_PUBLIC_SENTRY_RETAILPLUS_DSN,
  'NEXT_PUBLIC_SENTRY_RETAILPLUS_DSN is not setup properly'
);

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_RETAILPLUS_DSN;

invariant(
  process.env.NEXT_PUBLIC_API_REST_URL,
  'NEXT_PUBLIC_API_REST_URL is not define in the environment file'
);
invariant(
  process.env.NEXT_PUBLIC_API_URL,
  'NEXT_PUBLIC_API_URL is not define in the environment file'
);

if (
  process.env.NEXT_PUBLIC_APP_ENV !== 'development' &&
  process.env.NODE_ENV !== 'test'
) {
  Sentry.init({
    dsn: SENTRY_DSN,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: chooseTracesSampleRateByEnv(),

    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    environment: process.env.NEXT_PUBLIC_APP_ENV,

    // Replay
    replaysSessionSampleRate: replaysSessionSampleRateByEnv(),
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      Sentry.browserTracingIntegration({
        shouldCreateSpanForRequest: (url) => {
          // Do not create spans for outgoing requests to a `/health/` endpoint
          return !url.includes('.html');
        },
      }),

      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    tracePropagationTargets: [
      'localhost',
      process.env.NEXT_PUBLIC_API_REST_URL,
      process.env.NEXT_PUBLIC_API_URL,
    ],
  });
}
