import { FC, useEffect } from 'react';
import { UserFeedbackModal } from './UserFeedbackModal';
import { useModal, useProfile } from '@tapestry/shared/hooks';
import { ReplyIcon } from '@tapestry/shared/icons';
import { SpringValue, a } from '@react-spring/web';

let jsConfetti;

export const UserFeedback: FC<{ opacity?: SpringValue<number> }> = ({
  opacity = 1,
}) => {
  const userFeedbackModalState = useModal();
  const [profile] = useProfile();

  const makeThemPop = () => {
    jsConfetti
      .addConfetti({ emojis: ['🦄'], emojiSize: 40 })
      .then(() => jsConfetti.clearCanvas());
  };

  useEffect(function loadUpConfetti() {
    const setupCanva = async () => {
      const JSConfetti = (await import('js-confetti')).default;
      jsConfetti = new JSConfetti();
    };

    setupCanva();
  }, []);

  return (
    <>
      <button
        title="Submit Feedback"
        type="button"
        onClick={userFeedbackModalState.open}
        className="truncate w-full text-left flex items-center justify-start"
        onKeyUp={({ key }) => {
          if (key === 'Enter') {
            userFeedbackModalState.open();
          }
        }}
      >
        <span className="rounded-full flex items-center text-black p-2 justify-center h-8 w-8 shrink-0">
          <ReplyIcon fillColor="currentColor" />
        </span>

        <a.span
          style={{
            opacity,
          }}
          className="ml-2"
        >
          Submit Feedback
        </a.span>
      </button>

      {userFeedbackModalState.isOpen && (
        <UserFeedbackModal
          modalState={userFeedbackModalState}
          profile={profile}
          makeThemPop={makeThemPop}
        />
      )}
    </>
  );
};
