import { BREAKPOINTS, ResponsiveBreakpoints } from '@tapestry/types';

export interface IResponsiveRangeProps {
  above?: Exclude<ResponsiveBreakpoints, 'xxl'>;
  below?: Exclude<ResponsiveBreakpoints, 'sm'>;
}

export const resolveResponsiveRangeProps = (
  props: IResponsiveRangeProps
): [boolean, boolean, boolean, boolean] => {
  const { above, below } = props;

  if (!above && !below) {
    return [false, false, false, false];
  }

  const startIndex = above ? BREAKPOINTS.indexOf(above) + 1 : 0;
  const endIndex = below
    ? BREAKPOINTS.indexOf(below) - 1
    : BREAKPOINTS.length - 1;
  const range = BREAKPOINTS.slice(startIndex, endIndex + 1);

  const includeMobile = range.indexOf('sm') >= 0;
  const includeTablet = range.indexOf('md') >= 0;
  const includeDesktop = range.indexOf('lg') >= 0;
  const includeWide = range.indexOf('xxl') >= 0;

  return [includeMobile, includeTablet, includeDesktop, includeWide];
};
