import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { twMerge } from 'tailwind-merge';

export interface ILabelProps {
  htmlFor: string;
  isLoading?: boolean;
  size?: 'small' | 'normal' | 'large';
}

const Label: React.FC<React.PropsWithChildren<ILabelProps>> = ({
  children,
  htmlFor,
  isLoading,
  size = 'normal',
}) => {
  return (
    <label
      htmlFor={htmlFor}
      className={twMerge(
        'block text-base font-bold capitalize tracking-wide text-black',
        size === 'small' && 'text-sm',
        size === 'normal' && 'text-base',
        size === 'large' && 'text-xl'
      )}
    >
      {isLoading ? <Skeleton /> : children}
    </label>
  );
};

export { Label };
