import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  from,
  Observable,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { addBreadcrumb, captureException, withScope } from '@sentry/nextjs';
import { CACHE_CONFIG } from './apollo-cache-config';
import { maskSensitiveData } from '../utils/mask-data';
import { getHeartbeatMockResponse } from '../__apollo-query-mocks__';
import fetch from 'cross-fetch';

const PUBLIC_OPERATIONS = ['Login', 'signUpUser'];

// *******************************************
// Create link chain (middlewares)
// -------------------------------------------
/**
 * Http res/req link - connects to the api
 */
const httpLink = createHttpLink({
  uri: `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
  fetch,
});

/**
 * Auth middleware adding token before every requests
 */
const authLink = new ApolloLink((operation, forward) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('accessToken');

  // drop all request apart from `login` if no token
  if (!token && !PUBLIC_OPERATIONS.includes(operation.operationName)) {
    return new Observable((observer) => {
      observer.complete();
    });
  }

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }));

  return forward(operation);
});

/**
 * Error middleware logging errors to our error monitoring system
 */
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      }

      captureException(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        { contexts: { variables: operation.variables } }
      );
    });

  if (networkError) {
    if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') {
      console.log(`[Network error]: ${networkError}`);
    }

    captureException(`[Network error]: ${networkError}`, {
      contexts: { variables: operation.variables },
    });
  }
});

/**
 * Add some additionnal graphql context to sentry
 */
const sentryLink = new ApolloLink((operation, forward) => {
  addBreadcrumb({
    category: 'fetch.graphql',
    message: `${operation.operationName}`,
    data: {
      variables: operation.variables,
    },
  });

  // experimenting some more with sentry
  //NOTE: TBD might need to use `configureScope` instead of `withScope` and blast the context on fetch return
  withScope((scope) => {
    scope.setTag('graphql-operation', operation.operationName);
    scope.setContext('graphql-variables', operation.variables);
  });

  return forward(operation);
});

/**
 * Data masking Link
 * Add data masking to prevent sensitive data from being displayed in demo mode
 */

const dataMaskingLink = (on: boolean) =>
  new ApolloLink((operation, forward) => {
    return forward(operation).map((response) =>
      on ? maskSensitiveData(operation, response) : response
    );
  });

/**
 * Mock link
 *
 * use this link to mock queries responses, etc..
 */
const mockResponseLink = new ApolloLink((operation, forward) => {
  const mockResponse = getHeartbeatMockResponse(operation);

  if (mockResponse) {
    return new Observable((observer) => {
      observer.next({
        ...mockResponse,
      });
      observer.complete();
    });
  }

  return forward(operation);
});

/**
 * handle request retry only on network error (i.e. not on graphQL error)
 */
const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => {
      // Severity: low
      // TODO: research another way to implement noRetry,
      // the problem with the context is that the context is not documented at the code level,
      // so we have to find the way to allow this context to be discoverable.

      const context = _operation.getContext();

      if (context?.noRetry) {
        return false;
      }

      return !!error;
    },
  },
});

const httpServerLinks = from([
  authLink,
  errorLink,
  retryLink,
  sentryLink,
  httpLink,
]);
const cache = new InMemoryCache(CACHE_CONFIG);

const apolloClient = ({ isDemo = false }: { isDemo: boolean }) => {
  return new ApolloClient({
    link: from([dataMaskingLink(isDemo), mockResponseLink, httpServerLinks]),
    cache,
  });
};

export { apolloClient };
