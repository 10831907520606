import { FC } from 'react';
import { returnFileSize } from './user-feedback-utils';
import { CloseIcon } from '@tapestry/shared/icons';

interface FileListProps {
  fileList: File[];
  handleRemoveFileFromList: (file: File) => void;
}

export const FileList: FC<FileListProps> = ({
  fileList,
  handleRemoveFileFromList,
}) => {
  return (
    <ul className="space-y-2 mt-2">
      {fileList.map((file) => {
        const { name, size } = file;

        return (
          <li
            key={name + String(size)}
            className="flex border rounded-md justify-between relative"
          >
            <div className="px-4 py-2 overflow-clip">
              <h6 className="truncate">{name}</h6>
              <p className="text-sm text-gray-text">{returnFileSize(size)}</p>
            </div>

            <img
              src={URL.createObjectURL(file)}
              alt={`user uploaded ${name}`}
              className="rounded-r-md h-full w-32"
            />

            <button
              type="button"
              onClick={() => handleRemoveFileFromList(file)}
              className="absolute top-0 right-0 rounded-full mt-2 mr-2 p-1 bg-white hover:bg-button-basic-hover focus:bg-button-basic-hover focus:outline-none duration-300 transition-colors flex items-center justify-center h-6 min-w-6"
            >
              <span className="sr-only">Remove file</span>
              <CloseIcon light />
            </button>
          </li>
        );
      })}
    </ul>
  );
};
