import { useEffect, RefObject } from 'react';

type Event = MouseEvent | TouchEvent;

/**
 * Copy from https://usehooks-typescript.com/react-hook/use-on-click-outside
 *
 * @example
 * const Component = (params) => {
 * const ref = useRef();
 *
 * const handleClickOutside = () => {
 *   //do something;
 * };
 *
 * useOnClickOutside(ref, handleClickOutside);
 *
 * return (
 *   <div ref={ref}>
 *     <p>hello</p>
 *   </div>
 * )
 * };
 */
export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler?: (event: Event) => void
) {
  useEffect(() => {
    if (!ref?.current || !handler) {
      return;
    }

    const listener = (event: Event) => {
      const el = ref.current;

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event?.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);

    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };
  }, [ref, handler]);
}

export default useOnClickOutside;
