import React, { FC } from 'react';

type MainHeadingProps = {
  className?: string;
};

const MainHeading: FC<React.PropsWithChildren<MainHeadingProps>> = ({
  children,
  className,
}) => {
  return (
    <h2 className={`text-lg font-bold text-black ${className}`}>{children}</h2>
  );
};

export { MainHeading };
