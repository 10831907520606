import { StaticImageData } from 'next/image';

function isStaticImageData(
  image: string | StaticImageData
): image is StaticImageData {
  return (image as StaticImageData).src !== undefined;
}

/**
 * Retrieves the src of a static image.
 */
export function getStaticImageSrc(image: string | StaticImageData): string {
  if (isStaticImageData(image)) {
    return image.src;
  }

  return image;
}
