import ModalRoot from './Modal';
import { ModalTitle } from './ModalTitle';
import { ModalDescription } from './ModalDescription/ModalDescription';
import { ModalActionButton } from './Footer';
import { ModalFooter } from './Footer';
import { Content } from './Content/Content';
import { Dialog } from './Dialog/Dialog';

export type { OverlayTriggerState as ModalState } from '@react-stately/overlays';

export const Modal = Object.assign(ModalRoot, {
  Title: ModalTitle,
  Description: ModalDescription,
  Footer: ModalFooter,
  Button: ModalActionButton,
  Content,
  Dialog,
});
