export enum DateTimeSet {
  Millisecond = 'millisecond',
  Second = 'second',
  Minute = 'minute',
  Hour = 'hour',
  DayInMonth = 'date',
  DayInWeek = 'day',
  Month = 'month',
  // NOTE: add more if needed
}
