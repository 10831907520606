import { Contract } from '@tapestry/types';

export const CONTRACTS_SORTING_ORDER = [
  Contract.Tags,
  Contract.Assignable,
  Contract.DueDate,
  Contract.Reminder,
  Contract.Shop,
  Contract.Department,
  Contract.Products,
];
