// *******************************************************
// FilterButtonWidget
// -------------------------------------------------------
// Component Description
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React, { useRef } from 'react';

// *******************************************
// Hook Imports
// -------------------------------------------
import { useButton } from '@react-aria/button';

// *******************************************
// Components Imports
// -------------------------------------------
import { FilterIcon } from '@tapestry/shared/icons';

// *******************************************
// Interface Imports
// -------------------------------------------

// *******************************************
// Local Interface
// -------------------------------------------
import { AriaButtonProps } from '@react-types/button';
interface IFilterButtonWidgetProps extends AriaButtonProps<'button'> {
  title: string;
  isActive: boolean;
}

// *******************************************
// Main Component
// -------------------------------------------
export const FilterButtonWidget = (props: IFilterButtonWidgetProps) => {
  const { title, isActive } = props;
  const filterButtonRef = useRef(null);
  const { buttonProps } = useButton(
    {
      ...props,
    },
    filterButtonRef
  );

  return (
    <button
      title={title || 'Choose a filter'}
      ref={filterButtonRef}
      className={`grow-on-hover ring-orange-hank z-50 flex h-10 w-10 items-center justify-center rounded-full border border-gray-300 p-3 shadow-lg transition-all duration-500 ease-in-out hover:bg-gray-300 focus:outline-none focus:ring-2 ${
        isActive ? 'bg-blue' : 'bg-white'
      }`}
      {...buttonProps}
    >
      <FilterIcon
        className="h-full w-auto"
        fillColor={isActive ? '#fff' : '#000'}
      />
    </button>
  );
};

export default FilterButtonWidget;
