import { Card, FrameContent, Link, Overlay } from '@tapestry/weave';
import { FC } from 'react';
import backgroundImg from './map-bg-image.jpg';
import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { getStaticImageSrc } from '@tapestry/shared/utils';

export const InviteARetailerBanner: FC = () => {
  return (
    <FrameContent noVerticalSpacing maxWidth="xl">
      <Card
        spacing="small"
        backgroundImage={getStaticImageSrc(backgroundImg)}
        noOverlay
      >
        <Overlay rounded="rounded-lg" opacity="opacity-[.40]" />

        <div className="relative flex w-full items-center justify-between">
          <div className="flex flex-row items-center">
            <p className="ml-4 text-lg font-bold text-white">
              Want more retailers? Invite them.
            </p>
          </div>

          <Link
            href={ROUTE_PATHS.inviteARetailerTypeform}
            external
            type="button-primary"
            spacing="small"
          >
            Invite A Retailer
          </Link>
        </div>
      </Card>
    </FrameContent>
  );
};
