import { TAPESTRY_APP_CONFIGS } from '@tapestry/shared/constants';
import { Env, ITapestryAppConfigurations } from '@tapestry/types';

interface IAppInfo {
  appInfo: ITapestryAppConfigurations;
  appEnvironment: Env;
  isRetailApp: boolean;
  isSupplyApp: boolean;
}

const isRetailApp = (appInfo: ITapestryAppConfigurations) =>
  appInfo?.title === TAPESTRY_APP_CONFIGS.retail.title;

const isSupplyApp = (appInfo: ITapestryAppConfigurations) =>
  appInfo?.title === TAPESTRY_APP_CONFIGS.supply.title;

const getCurrentAppInfo = (): IAppInfo => {
  const appName = process.env['NEXT_PUBLIC_APP_NAME'] || '';
  const isValidApp = Object.keys(TAPESTRY_APP_CONFIGS).includes(appName);
  const currentApp = isValidApp ? appName : 'retail';

  const appEnvironment = (process.env['NEXT_PUBLIC_APP_ENV'] ||
    'development') as Env;
  const appInfo = TAPESTRY_APP_CONFIGS[currentApp];

  const _isRetailApp = isRetailApp(appInfo);
  const _isSupplyApp = isSupplyApp(appInfo);

  return {
    appInfo,
    appEnvironment,
    isRetailApp: _isRetailApp,
    isSupplyApp: _isSupplyApp,
  };
};

export { getCurrentAppInfo, isRetailApp, isSupplyApp };
