import React, { FunctionComponent } from 'react';

interface IListItemTitleProps {
  children: any;
  className?: string;
}

export const ListItemTitle: FunctionComponent<
  React.PropsWithChildren<IListItemTitleProps>
> = ({ children, className }) => {
  return (
    <h3
      className={`truncate-2-lines text-left text-base font-bold leading-6 text-gray-900 sm:truncate sm:text-xl ${className}`}
      title={children}
    >
      {children}
    </h3>
  );
};
