import { HeartbeatIcon } from '@tapestry/shared/icons';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';

interface IAnalyseButtonProps {
  variant?: 'icon' | 'compact';
  disabled?: boolean;
}

const AnalysVariantClassnames = {
  icon: 'h-8 w-8 p-1.5',
  compact: 'h-6 py-1 px-2',
};

const AnalyseButton: React.FC<React.PropsWithChildren<IAnalyseButtonProps>> = ({
  variant,
  disabled,
}: IAnalyseButtonProps) => (
  <span
    className={twMerge(
      'ease flex items-center justify-center rounded-full border-2 bg-white transition-colors duration-150',
      variant ? AnalysVariantClassnames[variant] : 'h-8 px-3 py-2',
      disabled
        ? 'border-gray-disabled text-gray-disabled pointer-events-none'
        : 'border-primary text-primary hover:bg-primary focus:bg-primary active:bg-primary-dark active:border-primary-dark hover:text-white focus:text-white focus:outline-none'
    )}
  >
    <HeartbeatIcon fillColor="currentColor" />
    {variant !== 'icon' ? (
      <span className="text-bold ml-1 inline-block text-xs">Analyse</span>
    ) : null}
  </span>
);

export { AnalyseButton };
