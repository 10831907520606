import { TPlatform } from './AppInstallationPrompt.type';

const IOS_USER_AGENTS_REGEX = /iphone|ipad|ipod/;
const ANDROID_USER_AGENT = /android/;

const getDevicePlatform = (): TPlatform => {
  if (typeof window === 'undefined') {
    return 'node';
  }

  if (IOS_USER_AGENTS_REGEX.test(window.navigator.userAgent.toLowerCase())) {
    return 'ios';
  }

  if (ANDROID_USER_AGENT.test(window.navigator.userAgent.toLowerCase())) {
    return 'android';
  }

  return 'unknown';
};

const isInStandaloneMode = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  return (
    'standalone' in window.navigator && (window.navigator as any).standalone
  );
};

export { getDevicePlatform, isInStandaloneMode };
