import { FC } from 'react';
import { SlidersIcon } from '@tapestry/shared/icons';
import { Circle } from '@tapestry/weave';
import type { AppletFilterButtonProps } from './AppletFilterButton.type';

type AppletFilterMobileButtonProps = Pick<
  AppletFilterButtonProps,
  'disabled' | 'activeFiltersCount'
>;

const AppletFilterMobileButton: FC<AppletFilterMobileButtonProps> = ({
  disabled,
  activeFiltersCount,
}) => {
  return (
    <span className="md:hidden">
      <Circle size="size-14 p-4 border-gray-border border-2  shadow-sm">
        <SlidersIcon fillColor={disabled ? '#eee' : '#000'} />
      </Circle>

      {activeFiltersCount > 0 ? (
        <Circle
          size="h-6 w-6"
          className="bg-red absolute right-0 top-0 -translate-y-1 translate-x-2 transform font-normal text-white"
        >
          {activeFiltersCount}
        </Circle>
      ) : null}
    </span>
  );
};

export { AppletFilterMobileButton };
