import { Twist } from '@tapestry/types';

export const TWIST_SORTING_ORDER = [
  // Twist.Tags,
  Twist.MoreInformation,
  Twist.DueDate,
  Twist.EstimateDuration,
  // Twist.Reminder,
  Twist.SubTasks,
  Twist.Attachments,
  Twist.AttachLink,
  Twist.Shop,
  // Twist.Products,
  Twist.DepartmentId,
  Twist.Assignable,
  // Twist.Owner,
];
