import React, { FC } from 'react';

const Panel: FC<
  React.PropsWithChildren<{
    noVertSpacing?: boolean;
    noHorizontalPadding?: boolean;
  }>
> = ({ children, noVertSpacing, noHorizontalPadding }) => {
  return (
    <section
      className={`w-full ${noHorizontalPadding ? '' : 'px-4'} ${
        noVertSpacing ? '' : 'my-8'
      }`}
    >
      <div className="mx-auto w-full max-w-screen-xl rounded-lg bg-white py-6 px-4 sm:py-12 sm:px-12">
        {children}
      </div>
    </section>
  );
};

export { Panel };
