import { FC, ReactNode } from 'react';
import { Button } from '../../Button';
import { IButton } from '../../Button/Button.types';

export interface IModalButtonProps
  extends Omit<IButton, 'rounded' | 'overwriteColors' | 'className'> {
  children: string | ReactNode;
}

export const ModalActionButton: FC<IModalButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <Button {...props} rounded="rounded-full" className="w-full sm:w-auto">
      {children}
    </Button>
  );
};

export default ModalActionButton;
