import { useRef } from 'react';

/**
 * utility hook to calculate the number of rendes
 */
export const useRenderCount = () => {
  const numberOfRenders = useRef(0);
  numberOfRenders.current++;
  console.log('numberOfRenders', numberOfRenders.current);
};
