export const getMaxWidth = (width: string) => {
  switch (width) {
    case 'md':
      return 'max-w-screen-md';

    case 'lg':
      return 'max-w-screen-lg';

    case 'xl':
      return 'max-w-screen-xl';

    default:
      return 'max-w-screen-lg';
  }
};
