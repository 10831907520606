import React from 'react';
import {
  CloseIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from '@tapestry/shared/icons';
import { Nullable } from '@tapestry/types';

interface IFormMessage {
  message?: Nullable<string>;
  failed?: Nullable<boolean>;
  onClose?: () => void;
}

export const FormMessage = ({ message, failed, onClose }: IFormMessage) => {
  if (failed === false) {
    return (
      <div className="bg-success mb-6 rounded-md p-4">
        <div className="flex items-center">
          <div className="flex-shrink-0 text-white">
            <CheckCircleIcon className="h-5 w-5" fillColor="currentColor" />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-white">{message}</p>
          </div>

          <button
            type="button"
            onClick={onClose}
            className="ml-auto inline-flex h-4 rounded-md text-white outline-none focus:outline-none"
          >
            <span className="sr-only">Dismiss</span>
            <CloseIcon fillColor="#fff" />
          </button>
        </div>
      </div>
    );
  }

  if (failed === true) {
    return (
      <div className="bg-danger mb-6 rounded-md p-4">
        <div className="flex items-center">
          <div className="flex-shrink-0 text-white">
            <ExclamationCircleIcon
              className="h-5 w-5"
              fillColor="currentColor"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-white">{message}</p>
          </div>

          <button
            type="button"
            onClick={onClose}
            className="ml-auto inline-flex h-4 rounded-md text-white outline-none focus:outline-none"
          >
            <span className="sr-only">Dismiss</span>
            <CloseIcon fillColor="#fff" />
          </button>
        </div>
      </div>
    );
  }

  return null;
};

export default FormMessage;
