import React, { FunctionComponent, KeyboardEvent } from 'react';

interface IListShellProps {
  children: any;
  reverseOrderOnMobile?: boolean;
  shellClassName?: string;
  dontCollapse?: boolean;
  onClick?: () => void | undefined;
  onKeyDown?: (e: KeyboardEvent) => void;
  noShadow?: boolean;
  style?: Record<string, unknown>;
  borderColor?: string;
  padding?: 'md' | 'lg';
  disabled?: boolean;
  asDiv?: boolean;
}

const getPadding = (size: string) => {
  switch (size) {
    case 'md':
      return 'p-5 sm:py-5 sm:px-6';

    case 'lg':
      return 'px-6 py-7';

    default:
      return 'p-2 sm:p-5';
  }
};

export const ListItemShell: FunctionComponent<
  React.PropsWithChildren<IListShellProps>
> = ({
  children,
  reverseOrderOnMobile,
  shellClassName,
  dontCollapse, // Makes it's never stack on small devices
  onClick = undefined,
  onKeyDown,
  noShadow = false,
  style,
  disabled = false,
  borderColor = 'border-gray-border',
  padding = '',
  asDiv,
}) => {
  const layout = dontCollapse
    ? 'flex flex-row justify-between items-center'
    : 'sm:flex sm:flex-row sm:justify-between sm:items-center';

  const _padding = getPadding(padding);

  const Tag = asDiv ? 'div' : 'button';

  return (
    <div
      className={`mb-2 box-border rounded-md bg-white ${
        noShadow ? `border ${borderColor}` : 'shadow'
      }`}
    >
      <Tag
        // type="button"
        className={`${
          reverseOrderOnMobile ? 'flex flex-col-reverse' : ''
        } ${layout} ${_padding} w-full rounded-md ${
          onClick ? 'cursor-pointer' : ''
        }  ${shellClassName}`}
        onClick={onClick}
        onKeyDown={onKeyDown}
        style={style}
        disabled={disabled}
      >
        {children}
      </Tag>
    </div>
  );
};

export default ListItemShell;
