import { FC, PropsWithChildren, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface ITwistListItemProps {
  icon?: ReactNode;
  iconBackgroundColor?: string;
  showStrip?: boolean;
  showGutter?: boolean;
}

const TwistListItem: FC<PropsWithChildren<ITwistListItemProps>> = ({
  icon,
  children,
  iconBackgroundColor = 'bg-pink-normal',
  showStrip = true,
  showGutter = true,
}) => {
  return (
    <li className={twMerge('relative', showGutter && 'pb-8')}>
      {showStrip ? (
        <div className="hidden absolute sm:flex h-full w-12 flex-shrink-0 justify-center">
          <span className="bg-gray-100 h-full w-1" aria-hidden="true" />
        </div>
      ) : null}

      <div className="gap-4 sm:gap-6 flex w-full overflow-hidden ">
        {/* icon */}
        <div
          className={twMerge(
            'hidden size-12 flex-shrink-0 rounded-full p-3.5 z-10 sm:flex place-content-center',
            iconBackgroundColor
          )}
        >
          {icon}
        </div>

        {/* content */}
        <div className="sm:mt-3 overflow-hidden w-full">{children}</div>
      </div>
    </li>
  );
};

export { TwistListItem };
