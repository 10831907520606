import { captureException } from '@sentry/nextjs';
import { SUPPORT_EMAIL } from '@tapestry/shared/constants';
import { BugIcon } from '@tapestry/shared/icons';
import * as React from 'react';
import { PrompUserFeedbackButton } from './PrompUserFeedbackButton';
import Link from 'next/link';

interface IErrorBondaryProps {
  errorView?: React.ReactNode;
  onError?: (error: any) => void;
  children: React.ReactNode;
}

interface IState {
  hasError: boolean;
  errorMessage: any;
}

export class ErrorBoundary extends React.Component<IErrorBondaryProps, IState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { errorMessage: error, hasError: true };
  }

  componentDidCatch(error) {
    if (process.env.NODE_ENV !== 'test') {
      captureException(error);

      this.props.onError && this.props.onError(error);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.props.errorView) {
        return this.props.errorView;
      }

      return <ErrorUI />;
    }

    return this.props.children;
  }
}

export const ErrorUI = () => {
  return (
    <div className="mx-auto mt-12 max-w-xl px-4">
      <div className="bg-white ">
        <div className="mx-auto max-w-7xl space-y-4 px-4 py-12 text-center text-gray-900 sm:px-6 lg:px-8 lg:py-16">
          <BugIcon className="mx-auto h-8 w-auto" fillColor="currentColor" />
          <h2 className="text-2xl font-extrabold tracking-tight sm:text-3xl">
            Something went wrong.
          </h2>
          <p className="text-base">
            You've stumble upon a bug in our system. We've been notified and
            ought to fix it as soon as possible.
            <br />
            <br />
            Help us out by providing some context around the error.
            <br />
            Alternatively, you can return home using the button below.
          </p>

          <div className="space-y-2 sm:space-x-4 sm:space-y-0">
            <PrompUserFeedbackButton />

            <Link
              href="/"
              className="hover:bg-primary text-primary border-primary inline-flex min-w-40 items-center justify-center rounded-lg border-2 bg-white px-4 py-4 text-sm font-medium transition-colors duration-300 ease-out hover:text-white focus:outline-none"
            >
              Return to home
            </Link>
          </div>

          <p className="text-sm">
            If this error persists, you can contact{' '}
            <a
              href={`mailto:${SUPPORT_EMAIL}`}
              className="text-primary underline"
            >
              support
            </a>{' '}
            directly
          </p>
        </div>
      </div>
    </div>
  );
};
