import { IFormInputMinimumProps, Range } from '@tapestry/types';
import React from 'react';
import { FormInputBase } from '../FormInputBase/FormInputBase';

export interface IRangeInputsProps extends IFormInputMinimumProps {
  value: Range;
  onChange: (range: Range) => void;
  onBlur?: (range: Range) => void;
}

export const RangeInputs = React.forwardRef<HTMLDivElement, IRangeInputsProps>(
  (
    { name, value: range, onChange, onBlur, disabled, ...defaultProps },
    ref
  ) => {
    const handleOnChange = (key: 'min' | 'max', value: string) => {
      const newValue = value.length === 0 ? null : Number(value);
      const newState: Range = {
        ...range,
        [key]: newValue,
      };

      onChange(newState);
    };

    const handleComponentBlur = (event) => {
      if (!onBlur) return;
      onBlur(event);
    };

    return (
      <FormInputBase name={name} {...defaultProps}>
        <div
          id={name}
          className="flex items-center"
          onBlur={handleComponentBlur}
          ref={ref}
        >
          <input
            type="number"
            id={`${name}-min`}
            name={`${name}-min`}
            placeholder="min"
            value={range?.min || ''}
            onChange={(e) => {
              handleOnChange('min', e.target.value);
            }}
            className="max-w-32 border-gray-lightest text-gray-text focus:border-orange-hank focus:ring-orange-hank relative cursor-default rounded-md border bg-white px-6 py-3 text-left text-base focus:outline-none focus:ring-1 disabled:cursor-not-allowed sm:max-w-none sm:px-4"
            disabled={disabled}
          />

          {/* Hyphen - */}
          <span className="mx-4">&#8208;</span>

          <input
            type="number"
            id={`${name}-max`}
            name={`${name}-max`}
            placeholder="max"
            min={range?.min || undefined} // more the the min number
            value={range?.max || ''}
            onChange={(e) => {
              handleOnChange('max', e.target.value);
            }}
            className="max-w-32 border-gray-lightest text-gray-text focus:border-orange-hank focus:ring-orange-hank relative cursor-default rounded-md border bg-white px-6 py-3 text-left text-base focus:outline-none focus:ring-1 disabled:cursor-not-allowed sm:max-w-none sm:px-4"
            disabled={disabled}
          />
        </div>
      </FormInputBase>
    );
  }
);
