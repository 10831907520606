import React, { VFC } from 'react';
import { useTabPanel } from '@react-aria/tabs';
import { TabListState } from '@react-stately/tabs';

interface ITabPanelProps extends React.HTMLAttributes<HTMLElement> {
  state: TabListState<Record<string, unknown>>;
  className?: string;
}

export const TabPanel: VFC<ITabPanelProps> = ({
  state,
  className,
  ...props
}) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const { tabPanelProps } = useTabPanel(props, state, ref);
  const activePanel = state.selectedItem?.props.children;

  return (
    <div {...tabPanelProps} ref={ref} className={className}>
      {activePanel}
    </div>
  );
};
