import { FC } from 'react';
import { Control } from 'react-hook-form';
import {
  FormInputBase,
  IFormInputBaseProps,
} from '../FormInputBase/FormInputBase';
import { DynamicPhoneNumberInput } from '../PhoneNumberInput';

interface IFormPhoneNumberInput extends IFormInputBaseProps {
  control: Control;
}

export const FormPhoneNumberInput: FC<IFormPhoneNumberInput> = (props) => {
  return (
    <FormInputBase {...props}>
      <DynamicPhoneNumberInput name={props.name} control={props.control} />
    </FormInputBase>
  );
};
