// *******************************************************
// Toast
// -------------------------------------------------------
// Component Description
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React, { useEffect, useState } from 'react';

// *******************************************
// Component Imports
// -------------------------------------------
import { Transition } from '@headlessui/react';

// *******************************************
// Interface Imports
// -------------------------------------------
import { IToastNotification, ToastNotificationType } from '@tapestry/types';
import { getIcon, getIconBackgroundColor, getText } from './toastUtils';
import { CloseIcon } from '@tapestry/shared/icons';

type ToastProps = {
  notification: IToastNotification;
  removeToast: (id: string) => void;
};

// *******************************************
// The component
// -------------------------------------------
export function Toast({
  notification: { content, type, id, avatarUrl },
  removeToast,
}: ToastProps) {
  const [timerWidth, settimerWidth] = useState(5);

  const { title, textContent } = getText(type, content);
  const IconComponent = getIcon(type);
  const IconBackgroundColor = getIconBackgroundColor(type);

  // when you mount => start a 5 second timer
  // at the end, remove yourself from the list
  useEffect(() => {
    const timeoutDuration = type === 'error' ? 7500 : 5000;
    const timeId = setTimeout(() => {
      if (!id) return;
      removeToast(id);
    }, timeoutDuration);

    const intervalId = setInterval(() => {
      settimerWidth((prevWidth) => prevWidth - 1);
    }, 800);

    return () => {
      clearTimeout(timeId);
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Transition
      as="div"
      show
      appear
      enter=" ease-out duration-500 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={`pointer-events-auto w-full max-w-xs overflow-hidden rounded-lg border border-gray-300 bg-white shadow-lg`}
    >
      <div className="p-4 pb-3">
        <div className="flex items-center justify-between">
          <div className="flex w-full items-center">
            {type === ToastNotificationType.Assigned ? (
              <div className="relative">
                <img
                  src={avatarUrl}
                  alt={'User avatar'}
                  className={`h-8 w-8 min-w-8 rounded-full sm:h-10 sm:w-10 sm:min-w-10`}
                />
                <div
                  className={`absolute bottom-0 right-0  flex h-6 w-6 min-w-6 flex-shrink-0 translate-y-2 items-center justify-center rounded-full p-1 ${IconBackgroundColor}`}
                >
                  <IconComponent fillColor="#fff" />
                </div>
              </div>
            ) : (
              <div
                className={`flex h-10 w-10 min-w-10 flex-shrink-0 items-center justify-center rounded-full p-2 ${IconBackgroundColor}`}
              >
                <IconComponent
                  fillColor="#fff"
                  light={type === ToastNotificationType.TaskCompleted}
                />
              </div>
            )}

            <div className="ml-2 w-0 flex-1">
              <p className={`text-sm font-bold`}>{title || ''}</p>

              <p className={`text-xxs text-gray-text mt-1`}>
                {textContent || content}
              </p>
            </div>
          </div>

          <div className="flex flex-shrink-0">
            <button
              className="inline-flex h-5 w-5 items-center justify-center rounded-full"
              onClick={() => {
                if (!id) return;
                removeToast(id);
              }}
            >
              <span className="sr-only">Close</span>
              <CloseIcon fillColor="#889aa4" light />
            </button>
          </div>
        </div>
      </div>

      {/* countdown- when updrade to JIT => use dynamic props => w-[timerWidth]/5 */}
      <div
        className={`h-1 rounded-lg bg-gray-700 transition-all ease-linear`}
        style={{ width: `${timerWidth * 20}%`, transitionDuration: '1s' }}
      />
    </Transition>
  );
}

export default Toast;
