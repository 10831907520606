import { useDrag } from '@use-gesture/react';
import { useSpring, config } from '@react-spring/web';
import React, { FC, ReactNode, useContext, useState, Dispatch } from 'react';
import { SpringValue } from '@react-spring/web';
import { ReactDOMAttributes } from '@use-gesture/react/dist/declarations/src/types';

type MenuContextState = {
  open: (e) => void;
  close: (velocity?: number) => void;
  bind: () => ReactDOMAttributes;
  y: SpringValue<number>;
  height: number;
  setHeight: Dispatch<React.SetStateAction<number>>;
  shouldPreventScroll: boolean;
};

const HIGHER_THAN_THE_TALLEST_PHONE = 800;

const MobileMenuContext = React.createContext<MenuContextState | undefined>(
  undefined
);

export const MobileMenuContextProvider: FC<
  React.PropsWithChildren<{ children: ReactNode }>
> = ({ children }) => {
  const [height, setHeight] = useState(HIGHER_THAN_THE_TALLEST_PHONE);
  const [shouldPreventScroll, setShouldPreventScroll] = useState(false);
  const [{ y }, api] = useSpring(() => ({
    from: { y: height },
  }));

  const open = ({ canceled }) => {
    // when cancel is true, it means that the user passed the upwards threshold
    // so we change the spring config to create a nice wobbly effect
    api.start({
      y: 0,
      immediate: false,
      config: canceled ? config.wobbly : config.stiff,
    });

    if (!canceled) {
      setShouldPreventScroll(true);
    }
  };

  const close = (velocity = 0) => {
    api.start({
      y: height,
      immediate: false,
      config: { ...config.stiff, velocity },
    });
    setShouldPreventScroll(false);
  };

  const bind = useDrag(
    ({
      last,
      velocity: [, vy],
      direction: [, dy],
      offset: [, oy],
      cancel,
      canceled,
    }) => {
      // if the user drags up passed a threshold, then we cancel
      // the drag so that the sheet resets to its open position
      if (oy < -70) cancel();

      // when the user releases the sheet, we check whether it passed
      // the threshold for it to close, or if we reset it to its open positino
      if (last) {
        oy > height * 0.5 || (vy > 0.5 && dy > 0)
          ? close(vy)
          : open({ canceled });
      }
      // when the user keeps dragging, we just move the sheet according to
      // the cursor position
      else api.start({ y: oy, immediate: true });
    },
    {
      from: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true,
    }
  );

  // * Should I memo this?
  const providerValue: MenuContextState = {
    open,
    close,
    bind,
    y,
    height,
    setHeight,
    shouldPreventScroll,
  };

  return (
    <MobileMenuContext.Provider value={providerValue}>
      {children}
    </MobileMenuContext.Provider>
  );
};

/**
 * Hook to access the mobile Menu context
 */
export const useMobileMenuContext = (): MenuContextState => {
  const context = useContext(MobileMenuContext);

  if (context === undefined) {
    throw new Error(
      'useMobileMenuContext must be used within a MobileMenuContextProvider'
    );
  }

  return context;
};
