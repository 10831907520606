import { getColors, getFont, getSpacing } from '../shared-styles/button-utils';
import { Url } from 'next/dist/shared/lib/router/router';

export interface ILink {
  href: Url;
  external?: boolean;
  type?: 'button-primary' | 'text';
  children: React.ReactNode;
  spacing?: 'none' | 'small' | 'medium' | 'lg';
  className?: string;
  title?: string;
}

export const getStyles = (
  type: ILink['type'],
  spacing: ILink['spacing'] = 'medium',
  className: ILink['className']
) => {
  switch (type) {
    case 'button-primary': {
      const _colors = getColors('primary');
      const _spacing = getSpacing(spacing, false);
      const font = getFont(spacing, false);

      return `${_colors} ${_spacing} ${font} rounded-lg border-2`;
    }

    default:
      return className;
  }
};
