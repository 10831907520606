import { dateTime } from '@tapestry/shared/utils';
import { IsoString } from '@tapestry/types';

export const separateDateFromTimeComponent = (value: IsoString | string) => {
  if (value.includes('T')) {
    return dateTime.parse(value).format('YYYY-MM-DDTHH:mm').split('T');
  }

  return [value, null];
};
