import { MinusIcon, PlusIcon } from '@tapestry/shared/icons';
import { FC, ReactElement } from 'react';
import { ListItem } from '../ListItem';

interface ISelectDefaultDropDownListItemProps {
  item;
  isHighlighted?: boolean;
  isChecked?: boolean;
  labelAccessor: (item) => string;
  icon?: ReactElement | null;
}

export const SelectDefaultDropDownListItem: FC<
  ISelectDefaultDropDownListItemProps
> = ({ item, isHighlighted, isChecked = false, labelAccessor, icon }) => {
  return (
    <ListItem
      tag="div"
      bgColor="bg-gray-lightest"
      borderColor={isHighlighted ? 'border-blue' : 'border-gray-lightest'}
      justify="between"
      padding="md"
    >
      <div className="flex items-center overflow-x-hidden">
        <span className="inline-flex size-7 sm:size-10 items-center justify-center relative">
          {icon}
        </span>
        <p className="ml-2 truncate font-semibold text-black">
          {labelAccessor(item)}
        </p>
      </div>

      <span
        title={`${isChecked ? 'Remove' : 'Add'} selection`}
        className="inline-flex size-7 items-center justify-center rounded-full bg-white p-1 sm:size-10 sm:p-2"
      >
        {isChecked ? (
          <MinusIcon fillColor="#0E73BB" light />
        ) : (
          <PlusIcon fillColor="#0E73BB" light />
        )}
      </span>
    </ListItem>
  );
};
