import { IShopByThreadType, IThreadV4Type } from '@tapestry/shared/graphql';
import { IThreadExtendedType } from '@tapestry/types';
import { Maybe } from 'graphql/jsutils/Maybe';

/**
 * A utility function to overlay all the shops that this thread belongs to over the thread object.
 * see `useGetShopsByThreads` for more details.
 */
export const overlayShopsByThreadsToThreads = (
  threads: Maybe<IThreadV4Type[]>,
  shopsByThreads: Maybe<IShopByThreadType[]>,
  isShopMode: boolean
): Maybe<IThreadV4Type[]> | IThreadExtendedType[] => {
  if (!shopsByThreads || isShopMode) return threads;

  return threads?.map((thread) => {
    const mathchingThreadObject = shopsByThreads?.find(
      (shopByThread) => shopByThread?.threadId === thread?.id
    );

    const extendedThread = {
      ...thread,
      inShops: mathchingThreadObject?.shops,
    };

    return extendedThread;
  });
};
