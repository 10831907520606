import { FC, ReactNode } from 'react';
import { useModalContext } from './modal-context/modal-context';

interface IModalTitleProps {
  children: ReactNode;
}

export const ModalTitle: FC<IModalTitleProps> = ({ children }) => {
  const { titleProps } = useModalContext();

  return (
    <h3 className="text-lg md:text-2xl font-bold text-black" {...titleProps}>
      {children}
    </h3>
  );
};
