import React, { FC } from 'react';
import { IShopSelector } from '@tapestry/shared/graphql';
import {
  calcBannerColor,
  calcShopOffline,
  calcMinutesSinceLastSynced,
  getReadableDuration,
} from './ShopOfflineBannerUtils';
import { useUIContext } from '@tapestry/shared/client';

export const StoreOfflineBanner: FC<{
  shopId: string;
  query: IShopSelector;
}> = ({ shopId, query }) => {
  const [{ hasTopBannersShowing }, dispatch] = useUIContext();
  const activeShop = query.shopSelector?.allShops.find(
    (shop) => shop.id === shopId
  );

  const lastSynced = calcMinutesSinceLastSynced(activeShop);
  const shopIsOffline = calcShopOffline(lastSynced);
  const bannerColor = calcBannerColor(lastSynced);
  const readableDurationSincSync = getReadableDuration(lastSynced);

  // NOTE: MAX - this is the way I found I had to do it to not trigger a infinite loop. Do you have an idea of how to do this better? it's pretty ugly
  if (shopIsOffline && !hasTopBannersShowing) {
    dispatch({
      type: 'TOGGLE_TOP_BANNERS',
      payload: true,
    });
  }

  if (!shopIsOffline && hasTopBannersShowing) {
    dispatch({
      type: 'TOGGLE_TOP_BANNERS',
      payload: false,
    });
  }

  return shopIsOffline ? (
    <div className={`${bannerColor} px-4 py-2 text-center text-white`}>
      Store has not been synced for {readableDurationSincSync}
    </div>
  ) : null;
};
