import { OnboardingSlide } from '@tapestry/types';

import AgendaScreen1 from './AgendaScreen1.jpg';
import AgendaScreen2 from './AgendaScreen2.jpg';
import AgendaScreen3 from './AgendaScreen3.jpg';
import AgendaScreen4 from './AgendaScreen4.jpg';

export const AGENDA_ONBOARDING_SLIDES: OnboardingSlide[] = [
  {
    header: 'Create and delegate',
    text: 'Create and assign tasks to help maximise productivity. Make sure you or your team never forget to complete a task again.',
    image: AgendaScreen1 as unknown as string,
  },
  {
    header: 'Filter and find the relevant tasks',
    text: 'Filters help you quickly find all the tasks that have been assigned to a particular shop, department, staff member or tag.',
    image: AgendaScreen2 as unknown as string,
  },
  {
    header: 'Never miss a task again',
    text: 'Some tasks need to be done every week. Use the repeating feature so you can schedule a task to repeat either daily, weekly, fortnightly or monthly.',
    image: AgendaScreen3 as unknown as string,
  },
  {
    header: 'Recommendations turned into tasks',
    text: 'Hank continually learns and puts forward ideas for your shop in the form of recommendations. These can then be turned into a task and then actioned by you in Tasks',
    image: AgendaScreen4 as unknown as string,
  },
];
