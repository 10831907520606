import React, { FC, ReactElement } from 'react';
import { CloseIcon } from '@tapestry/shared/icons';
import { twMerge } from 'tailwind-merge';

const getSizing = (
  size: IPillProps['size'],
  fontWeight: IPillProps['fontWeight']
) => {
  switch (size) {
    case 'xsmall':
      return `text-xs px-2 py-1 gap-2 ${fontWeight || 'font-medium'}`;

    case 'small':
      return `text-xs px-2 py-1 gap-2 ${fontWeight || 'font-bold'}`;

    case 'big':
      return `text-sm px-3 py-px gap-2 ${fontWeight || 'font-medium'}`;

    case 'xxl':
      return `text-base px-6 py-3 sm:py-4 gap-4 ${fontWeight || 'font-bold'}`;

    default:
      return `text-sm px-3 py-px gap-2 ${fontWeight || 'font-medium'}`;
  }
};

// TODO: merge with `getSizing` to generate the object that combine classess
// for example
// {
//   container: 'text-xs px-2 py-1 gap-2',
//   icon: 'font-medium',
// }

const getIconSize = (size: IPillProps['size']) => {
  switch (size) {
    case 'big':
      return 'size-5';

    case 'xxl':
      return 'size-6';

    default:
      return 'size-4';
  }
};

interface IPillProps {
  label: string | number;
  className?: string;
  bgColor?: string;
  textColor?: string;
  fontWeight?: string;
  size?: 'xsmall' | 'small' | 'big' | 'xxl';
  iconLeft?: ReactElement | null;
  iconRight?: ReactElement | null;
  onClear?: (event: React.MouseEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  title?: string;
  textStyle?: 'uppercase' | 'capitalize' | 'lowercase' | 'normal-case';
}

/**
 * Pill
 *
 * A pill shape label
 *
 * alt: Tag
 *
 * @param {string} label - provide the pill label,
 * @param {string} title - a onHover title
 * @param className - tailwind classname
 * @param bgColor - as Css prop
 * @param textColor - default:'text-white' - use to change the text color
 * @param fontWeight - as string
 * @param size - default = 'big' - 'big | 'small',
 * @param iconLeft - as FC,
 * @param iconRight - as FC,
 * @param onClear
 */
export const Pill: FC<React.PropsWithChildren<IPillProps>> = ({
  label,
  title,
  className,
  bgColor,
  textColor = 'text-black',
  size = 'big',
  iconLeft = null,
  iconRight = null,
  onClear,
  onKeyDown,
  fontWeight,
  textStyle = 'uppercase',
}) => {
  const sizing = getSizing(size, fontWeight);
  const iconSize = getIconSize(size);
  const tailwindBGColor = bgColor?.startsWith('bg') ? bgColor : null;
  const cssBGColor = bgColor && !bgColor.startsWith('bg') ? bgColor : '';

  return (
    <span
      className={twMerge(
        'inline-flex items-center justify-center overflow-hidden rounded-full bg-gray-300 transition-shadow duration-150 focus-within:shadow',
        sizing,
        tailwindBGColor,
        textColor,
        className
      )}
      style={{ backgroundColor: cssBGColor }}
      title={title}
    >
      {iconLeft && (
        <span
          className={twMerge(
            'relative flex  items-center justify-center',
            iconSize
          )}
        >
          {iconLeft}
        </span>
      )}

      <span className={twMerge('overflow-hidden truncate', textStyle)}>
        {label}
      </span>

      {iconRight && (
        <span className={twMerge('flex items-center justify-center', iconSize)}>
          {iconRight}
        </span>
      )}

      {!!onClear && (
        <span
          aria-label="remove"
          role="button"
          tabIndex={0}
          title={`remove ${label}`}
          className="flex size-4 items-center justify-center rounded-full p-0.5 hover:bg-black/25 focus:outline-none"
          onClick={onClear}
          onKeyDown={(e) => {
            onKeyDown && onKeyDown(e);
          }}
        >
          <span className="sr-only">Remove {label}</span>
          <CloseIcon light fillColor="currentColor" />
        </span>
      )}
    </span>
  );
};

export default Pill;
