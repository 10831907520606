export const SHORTCUT = {
  HEARTBEAT: {
    PREV_PERIOD: ',',
    NEXT_PERIOD: '.',
    PREV_METRIC: '[',
    NEXT_METRIC: ']',
    FILTER_RESET: 'r',
  },
  THREAD: {
    CREATE_NEW_THREAD: '',
  },
  APP: {
    FOCUS_SEARCH: 'meta k',
    VIEWS: {
      HEARTBEAT: 'control a',
      TASKS: 'control t',
      PRODUCTS: 'control p',
      LISTS: 'control l',
      DEALS: 'control h',
      DEPARTMENTS: 'control d',
      MANUFACTURERS: 'control m',
      SPACES: 'control s',
      CATEGORIES: 'control c',
      SUPPLIERS: 'control u',
    },
  },
  TASKS: {
    CREATE_NEW_TASK: 'control n',
  },
};
