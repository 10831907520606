// *******************************************************
// Tag
// -------------------------------------------------------
// Component Description
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
// *******************************************
// Component Imports
// -------------------------------------------
import { CloseIcon } from '@tapestry/shared/icons';

// *******************************************
// Constant Imports
// -------------------------------------------

// *******************************************
// Hook Imports
// -------------------------------------------

// *******************************************
// Interface Imports
// -------------------------------------------

// *******************************************
// Action / Utils / Functions Imports
// -------------------------------------------

// *******************************************
// Local Interface
// -------------------------------------------
export interface ITagProps {
  label: string;
  onRemove?: () => void;
}

/**
 * Tag
 *
 * The tag component is a simple colored tag
 *
 * Alternatives: Pill - if you want rounded edges
 *
 * @param {string} label - the label for the tag
 * @param {function} onRemove - Provide a onRemove function to display a 'X' icon
 *
 * @return {*}
 */
export const Tag = ({ onRemove, label }: ITagProps) => {
  return (
    <span className="bg-blue-lighter text-blue inline-flex w-max items-center rounded-sm py-2 px-5 text-base font-bold uppercase">
      {label || 'label'}

      {onRemove && (
        <button
          type="button"
          onClick={onRemove}
          onKeyPress={({ key }) => {
            if (key === 'Enter') {
              onRemove();
            }
          }}
          className="hover:bg-blue-light focus:bg-blue-light ml-2 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full focus:outline-none"
        >
          <span className="sr-only">Remove Tag</span>
          <CloseIcon fillColor="#0e73bb" light />
        </button>
      )}
    </span>
  );
};

export default Tag;
