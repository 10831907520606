import React, { FC } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { SearchIcon } from '@tapestry/shared/icons';

interface ISearchInputProps {
  label?: string;
  placeholder?: string;
  searchTerm?: string;
  fullWidth?: boolean;
  onChange: (text: string) => any;
  disabled?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const SearchInput: FC<React.PropsWithChildren<ISearchInputProps>> = ({
  label = 'Search',
  placeholder = 'Search',
  searchTerm = '',
  fullWidth,
  onChange,
  onKeyDown,
  disabled,
}) => {
  const debounced = useDebouncedCallback((text) => onChange(text), 500);

  return (
    <div className={fullWidth ? 'w-full' : 'max-w-48 w-full lg:max-w-lg'}>
      <label htmlFor="search" className="sr-only">
        {label}
      </label>
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <SearchIcon
            className="h-4 w-4 text-gray-300"
            fillColor="currentColor"
          />
        </div>
        <input
          id="search"
          type="search"
          className="bg-gray-input focus:border-blue-light focus:ring-blue block w-full rounded-md border border-gray-300 py-2 pl-8 pr-3 text-xs font-semibold leading-5 placeholder-gray-300 transition duration-150 ease-in-out focus:placeholder-gray-700 focus:outline-none disabled:cursor-not-allowed sm:text-sm"
          placeholder={placeholder}
          disabled={disabled}
          defaultValue={searchTerm || ''}
          onChange={(e) => debounced.callback(e.currentTarget.value)}
          onKeyDown={(e) => {
            if (onKeyDown) {
              onKeyDown(e);
            }
          }}
        />
      </div>
    </div>
  );
};

export default SearchInput;
