export enum Applet {
  Home = 'homepage',
  Heartbeat = 'heartbeat',
  Tasks = 'task',
  Departments = 'departments',
  Categories = 'categories',
  Orders = 'orders',
  Products = 'products',
  Suppliers = 'suppliers',
  Staff = 'staff',
  Loyalty = 'loyalty',
  Devices = 'devices',
  Customers = 'customers',
  Permissions = 'permissions',
}
