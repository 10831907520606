import React, { useCallback, useState, FC } from 'react';
import { ComboBoxOptionItem, IComboboxProps } from '@tapestry/types';
import { InternalCombobox } from './InternalCombobox';
import { useEffectAfterMount } from '../hooks/use-effect-after-mount';

/**
 * Alt: if you are looking for a multi-select/combobox, use `MultiCombobox`
 */
export const Combobox: FC<IComboboxProps> = (props) => {
  const { onChange, renderSelectedItem } = props;
  const [selectedItem, setSelectedItem] = useState<ComboBoxOptionItem | null>(
    props?.defaultOption || null
  );

  const removeSelectedItem = useCallback(() => {
    setSelectedItem(null);
  }, []);

  useEffectAfterMount(() => {
    onChange(selectedItem);
  }, [selectedItem, onChange]);

  return (
    <>
      <InternalCombobox
        setSelectedItem={setSelectedItem}
        shouldResetInputValue={!!renderSelectedItem}
        {...props}
      />

      {selectedItem && renderSelectedItem ? (
        <div className="mt-4 text-left">
          {renderSelectedItem(selectedItem, removeSelectedItem)}
        </div>
      ) : null}
    </>
  );
};

export default Combobox;
