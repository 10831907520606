import { Button } from './Button';
import { DropdownMenuComponent } from './Dropdown';
import { MenuItem } from './MenuItem';
import { Menu } from './Menus';
import { StyledMenuItem } from './StyledMenuItem';

// TODO[low]: Change name when old dropdown menu it out of the project
export const ResponsiveDropdownMenu = Object.assign(DropdownMenuComponent, {
  Button,
  Menu,
  MenuItem,
  StyledMenuItem,
});
