// *******************************************************
// UseDimensionsOnResize
// -------------------------------------------------------
// Component Description
// a hook to measure the width and/or height of container
// pass in the ref of the element to calculate
// returns dimensions and recalculate on resize
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import { useEffect, useState } from 'react';

// *******************************************
// Main Component
// -------------------------------------------
/**
 * measure the width and/or height of container. pass in the ref of the element to calculate. returns dimensions and recalculate on resize
 */
export const useDimensionsOnResize = (componentRef: any) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const getDimensions = () => ({
    width: componentRef.current.offsetWidth,
    height: componentRef.current.offsetHeight,
  });

  useEffect(() => {
    // might want to debounce it, might not
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (componentRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [componentRef]);

  return dimensions;
};

export default useDimensionsOnResize;
