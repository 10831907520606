import { lastEventId, showReportDialog } from '@sentry/nextjs';
import { FC } from 'react';

export const PrompUserFeedbackButton: FC = () => {
  const eventId = lastEventId();

  return (
    <button
      className="rounded-lg inline-flex items-center justify-center border-2 transition-colors duration-300 ease-out focus:outline-none font-medium text-sm bg-primary border-primary hover:bg-primary-dark hover:border-primary-dark focus:border-primary-light text-white py-4 px-4 min-w-40"
      onClick={() => showReportDialog({ eventId })}
    >
      Submit Feedback
    </button>
  );
};
