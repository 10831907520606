import { StringParam, useQueryParams } from '@tapestry/shared/utils';
import { useMemo } from 'react';

type IUseActiveShopScopeParams = {
  shopId?: string;
  groupId?: string;
};

/**
 * This hook enables reading the params related to `activeShopScope`
 *
 * @return {object}
 * {[scopeId]: 'lasdnas0-uap-sdjasdpas-0da'}
 */
export const useActiveShopScopeParams = (
  config?: IUseActiveShopScopeParams
): Record<string, string | null | undefined> => {
  const shopIdKey = config?.shopId || 'shopId';
  const groupIdKey = config?.groupId || 'groupId';
  const [{ shopId, groupId }] = useQueryParams({
    shopId: StringParam,
    groupId: StringParam,
  });

  const activeParam = useMemo(
    () => ({
      [groupId ? groupIdKey : shopIdKey]: groupId ? groupId : shopId,
    }),
    [shopId, groupId, shopIdKey, groupIdKey]
  );

  return activeParam;
};

export default useActiveShopScopeParams;
