import { createContext, Dispatch, SetStateAction, useState } from 'react';

import { IToastNotification } from '@tapestry/types';
import Toast from './Toast';

export type ToastContextState = [
  toastList: IToastNotification[],
  setToastList: Dispatch<SetStateAction<IToastNotification[]>>
];

export const ToastContext = createContext<ToastContextState | undefined>(
  undefined
);

export function ToastProvider({ children }) {
  const [toastList, setToastList] = useState<Array<IToastNotification>>([]);
  const value: ToastContextState = [toastList, setToastList];

  // NOTE: KNOW PROBLEMS => it currently clear all notification at once,
  // this might be due to the filter function and removeToast function shelf live
  // or
  // because the ToastProvider re-renders, it creates a new removeToast function...
  const removeToast = (id: string) => {
    const newList = toastList.filter((toast) => {
      return toast.id !== id;
    });
    setToastList(newList);
  };

  return (
    <ToastContext.Provider value={value}>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start"
        style={{ zIndex: 9999 }}
      >
        <div className="flex w-full flex-col items-center space-y-2 sm:items-end">
          {toastList?.map((notification) => (
            <Toast
              key={notification?.id}
              notification={notification}
              removeToast={removeToast}
            />
          ))}
        </div>
      </div>
      {children}
    </ToastContext.Provider>
  );
}
