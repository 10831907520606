// can be replace by a lib calle 'he' but it's big
/**
 * Simple helper function to decode HTML entities
 */
export const decodeHTMLEntities = function (string: string): string {
  // this prevents any overhead from creating the object each time
  const element = document.createElement('div');

  function decodeEntities(str) {
    if (str && typeof str === 'string') {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = '';
    }

    return str;
  }

  return decodeEntities(string);
};
