import React from 'react';
import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { useProfile } from '@tapestry/shared/hooks';
import Link from 'next/link';
import { avatarPlaceholder } from '@tapestry/shared/icons';
import Image from 'next/image';

export const AvatarLink = () => {
  const [profile] = useProfile();

  return (
    <Link
      href={ROUTE_PATHS.ManageAccount}
      className="min-w-8 hover:border-cerulean focus:border-cerulean hidden h-8 w-8 rounded-full border border-transparent text-sm transition duration-150 ease-in-out focus:outline-none sm:flex"
      id="avatar-menu"
      title={`Go to your profile\n${profile?.first_name} ${profile?.last_name}\n${profile?.email}`}
    >
      <Image
        src={profile?.photo_url || avatarPlaceholder}
        alt={'User avatar'}
        className={`h-full w-full rounded-full`}
        height={30}
        width={30}
      />
    </Link>
  );
};
