import * as React from 'react';
import { useTab } from '@react-aria/tabs';
import { TabListState } from '@react-stately/tabs';
import { twMerge } from 'tailwind-merge';

interface ITabProps {
  item: any;
  state: TabListState<Record<string, unknown>>;
  className: {
    active?: string;
    root: string;
  };
}

export const Tab: React.VFC<ITabProps> = ({ item, state, className }) => {
  const { key, rendered } = item;
  const ref = React.useRef<HTMLDivElement | null>(null);
  const { tabProps } = useTab({ key }, state, ref);
  const isSelected = state.selectedKey === key;

  return (
    <div
      {...tabProps}
      ref={ref}
      className={twMerge(
        className.root || 'rounded-md px-3 py-2 text-sm',
        isSelected
          ? [className.active || 'bg-blue-lighter text-blue']
          : 'text-gray-text',
        'cursor-pointer'
      )}
    >
      {rendered}
    </div>
  );
};
