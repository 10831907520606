import React from 'react';

/**
 * Force SSG/SSR rendering framework to only render the childen in the client
 */

export function useClientRenderingOnly() {
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted;
}

export function ClientOnly({ children, ...props }) {
  const hasMounted = useClientRenderingOnly();

  if (!hasMounted) {
    return null;
  }

  // ! probably should set this to react.cloneElement
  return <div {...props}>{children}</div>;
}
