import React, { FunctionComponent } from 'react';

interface ILISCProps {
  children: any;
}

export const ListItemSecondColumn: FunctionComponent<
  React.PropsWithChildren<ILISCProps>
> = ({ children }) => {
  return (
    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
      {children}
    </div>
  );
};

export default ListItemSecondColumn;
