import React, { FC } from 'react';

/**
 * a simple circle shape
 *
 * @param size - use for sizing class names
 * @param className - use for any styling classname
 */
export const Circle: FC<
  React.PropsWithChildren<{
    size?: string;
    className?: string;
  }>
> = ({
  children,
  size = 'h-10 w-10 p-2.5',
  className = 'border border-black text-white',
}) => {
  return (
    <span
      className={`flex items-center justify-center rounded-full ${size} ${className}`}
    >
      {children}
    </span>
  );
};
