import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { useModalContext } from '../modal-context/modal-context';

const MEDIA_QUERY_TARGETS = {
  'xs:': 'xs:',
  'sm:': 'sm:',
  'md:': 'md:',
  'lg:': 'lg:',
  'xl:': 'xl:',
  'xxl:': 'xxl:',
} as const;
export type MediaQueryTarget = keyof typeof MEDIA_QUERY_TARGETS;

const FLEX_JUSTIFY = {
  'justify-start': 'justify-start',
  'justify-center': 'justify-center',
  'justify-end': 'justify-end',
  'justify-between': 'justify-between',
} as const;
export type FlexJustify = keyof typeof FLEX_JUSTIFY;

export interface IModalFooterProps {
  children: React.ReactNode;
  flipFlex?: boolean;
  justify?: `${MediaQueryTarget}${FlexJustify}` | `${FlexJustify}`;
}

export const ModalFooter: FC<IModalFooterProps> = ({
  justify = 'justify-start',
  children,
  flipFlex = false,
}) => {
  const { isBottomOfScrollContainer } = useModalContext();

  return (
    <footer
      className={twMerge(
        'w-full py-4 px-4 sm:px-8 border-t space-y-4 sm:space-y-0 sm:gap-4',
        'sm:flex sm:flex-row-reverse sm:items-center',
        justify,
        flipFlex ? 'sm:flex-row' : 'sm:flex-row-reverse',
        'sticky bottom-0 z-10 rounded-b-lg bg-white transition-shadow duration-150',
        !isBottomOfScrollContainer && 'shadow-top'
      )}
    >
      {children}
    </footer>
  );
};

export default ModalFooter;
