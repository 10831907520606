import React, { useRef, useState, FC } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@tapestry/shared/icons';
import { Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import flattenChildren from 'react-flatten-children';

// *******************************************
// Local Interface
// -------------------------------------------
export interface ISliderTrayProps {
  spacing?: 'none' | 'small' | 'medium' | 'large';
  scrollDistance?: number;
  containerClassName?: string;
  listClassName?: string;
  noEdgeGradients?: boolean;
  gradientsColor?: string;
  hideArrowsBelowTablet?: boolean;
  hideArrowsOnMobile?: boolean;
  noArrows?: boolean;
  arrowClassName?: string;
  snapAlign?: 'snap-center' | 'snap-start' | 'snap-end';
}

// *******************************************
// Action / Utils / Functions Imports
// -------------------------------------------
const getitemSpacing = (spacing: ISliderTrayProps['spacing']) => {
  switch (spacing) {
    case 'none':
      return '';

    case 'small':
      return 'space-x-2';

    case 'medium':
      return 'space-x-3';

    case 'large':
      return 'space-x-6';

    default:
      return 'space-x-3';
  }
};

const getArrowsDisplay = (
  hideOnMobile?: boolean,
  hideBelowTablets?: boolean
) => {
  if (hideBelowTablets) {
    return 'hidden lg:block';
  }

  if (hideOnMobile) {
    return 'hidden xs:block';
  }

  return '';
};

const useScrollData = (
  listRef: React.RefObject<HTMLUListElement>
): [
  {
    scrollLocation: number;
    maxScrollLocation: number;
    hasReachedTheEndOfScrollContainer: boolean;
  },
  React.Dispatch<number>
] => {
  const [scrollLocation, setScrollLocation] = useState(
    listRef?.current?.scrollLeft || 0
  );

  const maxScrollLocation =
    (listRef?.current?.scrollWidth || 0) - (listRef?.current?.clientWidth || 0);

  const hasReachedTheEndOfScrollContainer =
    scrollLocation === maxScrollLocation;

  const state = {
    scrollLocation,
    maxScrollLocation,
    hasReachedTheEndOfScrollContainer,
  };

  return [state, setScrollLocation];
};

// *******************************************
// Main Component
// -------------------------------------------
/**
 * Slider Tray
 *
 * A horizontal sliding tray with arrow on both sides. You can scroll it or click the arrows to move it along
 *
 * @param spacing - controls spacing between items - default: `medium`
 * @param scrollDistance - how much the tray slides left/right per event
 * @param containerClassName - forwards className that you want to apply to the container
 * @param arrowClassName - forwards className that you want to apply to arrow
 * @param listClassName - forwards className to list wrapper around the list items
 * @param noEdgeGradients - hides the edge gradients
 * @param gradientsColor - provide a color for the gradients (i.e. #eee, #fff, etc...)
 * @param noArrows - hides arrows
 * @param hideArrowsBelowTablet - hide arrows below on certain breakpoint
 * @param hideArrowsOnMobile - hide arrows below on certain breakpoint
 */
export const SliderTray: FC<React.PropsWithChildren<ISliderTrayProps>> = ({
  children,
  spacing = 'medium',
  containerClassName = '',
  scrollDistance = 250,
  listClassName = 'px-px py-px',
  noEdgeGradients = false,
  gradientsColor = 'rgba(255,255,255,1)',
  hideArrowsBelowTablet,
  hideArrowsOnMobile,
  noArrows,
  arrowClassName = '',
  snapAlign = 'snap-center',
}) => {
  const listRef = useRef<HTMLUListElement>(null);
  const [
    { scrollLocation, maxScrollLocation, hasReachedTheEndOfScrollContainer },
    setScrollLocation,
  ] = useScrollData(listRef);

  const itemSpacing = getitemSpacing(spacing);
  const arrowDisplay = getArrowsDisplay(
    hideArrowsOnMobile,
    hideArrowsBelowTablet
  );

  const handleOnScroll = (e) => {
    setScrollLocation(e.currentTarget.scrollLeft);
  };

  const handleScrollOnClick = (towardsLeft = false) => {
    if (!listRef?.current) return;

    const directedScrollDistance = towardsLeft
      ? -scrollDistance
      : scrollDistance;

    listRef.current.scrollBy({
      left: directedScrollDistance,
      behavior: 'smooth',
    });
  };

  return (
    <div className={twMerge('relative w-full', containerClassName)}>
      {/* Edge gradient */}
      {!noEdgeGradients && (
        <Transition
          as="div"
          appear
          show={scrollLocation !== 0}
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="absolute bottom-0 left-0 top-0 z-10 h-full w-10"
            style={{
              backgroundImage: `linear-gradient(to right, ${gradientsColor}, rgba(255,255,255,0))`,
            }}
          />
        </Transition>
      )}

      {!noArrows && (
        <span
          className={twMerge(
            arrowDisplay,
            twMerge(
              'absolute left-0 top-1/2 z-10 -translate-x-1/4 -translate-y-1/2',
              arrowClassName
            )
          )}
        >
          <button
            type="button"
            title="Slide left"
            disabled={scrollLocation === 0}
            className={twMerge(
              'flex items-center justify-center rounded-full',
              'h-12 w-12 min-w-12 p-2 sm:h-10 sm:w-10 sm:min-w-10',
              'border-gray-150 border bg-white shadow-lg',
              scrollLocation === 0 && 'hidden'
            )}
            onClick={() => handleScrollOnClick(true)}
            onKeyUp={({ key }) => {
              if (key === 'Enter') {
                handleScrollOnClick(true);
              }
            }}
          >
            <ChevronLeftIcon className="h-full w-auto" />
          </button>
        </span>
      )}

      <ul
        ref={listRef}
        className={twMerge(
          'no-scrollbar isolate flex snap-x snap-mandatory flex-row overflow-x-scroll',
          itemSpacing,
          listClassName
        )}
        onScroll={handleOnScroll}
      >
        {React.Children.map(flattenChildren(children), (child) => (
          <li className={twMerge(snapAlign, 'snap-normal')}>{child}</li>
        ))}
      </ul>

      {/* Edge gradient */}
      {!noEdgeGradients && (
        <Transition
          as="div"
          appear
          show={scrollLocation !== maxScrollLocation}
          enter="transition-opacity duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="absolute bottom-0 right-0 top-0 z-10 h-full w-10"
            style={{
              backgroundImage: `linear-gradient(to left, ${gradientsColor}, rgba(255,255,255,0))`,
            }}
          />
        </Transition>
      )}

      {noArrows || hasReachedTheEndOfScrollContainer ? null : (
        <span
          className={twMerge(
            arrowDisplay,
            twMerge(
              'absolute right-0 top-1/2 z-10 -translate-y-1/2 translate-x-1/4',
              arrowClassName
            )
          )}
        >
          <button
            type="button"
            title="Slide right"
            disabled={hasReachedTheEndOfScrollContainer}
            className={twMerge(
              'flex items-center justify-center rounded-full',
              'h-12 w-12 min-w-12 p-2 sm:h-10 sm:w-10 sm:min-w-10',
              'border-gray-150 border bg-white shadow-lg',
              hasReachedTheEndOfScrollContainer &&
                'pointer-event-none cursor-not-allowed'
            )}
            onClick={() => handleScrollOnClick(false)}
            onKeyUp={({ key }) => {
              if (key === 'Enter') {
                handleScrollOnClick(false);
              }
            }}
          >
            <ChevronRightIcon
              className={twMerge(
                'h-full w-auto',
                scrollLocation === maxScrollLocation && 'opacity-50'
              )}
            />
          </button>
        </span>
      )}
    </div>
  );
};

export default SliderTray;
