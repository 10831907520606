import * as React from 'react';
import { useRadio } from '@react-aria/radio';
import { RadioContext } from './RadioGroup';
import { AriaRadioProps } from '@react-types/radio';
import { twMerge } from 'tailwind-merge';
import { RadioGroupState } from '@react-stately/radio';

interface IRadioProps extends AriaRadioProps {
  radioPosition?: 'left' | 'right';
  classes?: {
    root?: string;
    input?: string;
  };
}

const RadioItem: React.FC<React.PropsWithChildren<IRadioProps>> = (props) => {
  const { children, radioPosition = 'left', classes } = props;
  const state = React.useContext(RadioContext) as RadioGroupState;
  const ref = React.useRef<HTMLInputElement | null>(null);
  const { inputProps } = useRadio(props, state, ref);

  return (
    <label
      className={twMerge(
        'flex items-center',
        classes?.root || '  w-full',
        radioPosition === 'left' ? 'flex-row' : 'flex-row-reverse'
      )}
    >
      <input
        {...inputProps}
        ref={ref}
        className={twMerge(
          classes?.input,
          radioPosition === 'left' && 'mr-2',
          radioPosition === 'right' && 'ml-2'
        )}
      />
      {children}
    </label>
  );
};

export { RadioItem };
