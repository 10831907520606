import * as React from 'react';
import { SlideOver } from './SlideOver';

interface ISlideOverContext {
  isOpen: boolean;
  openSlider: (el: React.ReactElement) => void;
  closeSlider: () => void;
}

const SlideOverContext = React.createContext<ISlideOverContext>({
  isOpen: false,
  openSlider: () => undefined,
  closeSlider: () => undefined,
});

const SlideOverProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [element, setElement] = React.useState<React.ReactElement | null>(null);

  const handleOpen = (element: React.ReactElement) => {
    setElement(element);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleFinishTransition = () => {
    setElement(null);
  };

  return (
    <SlideOverContext.Provider
      value={{
        isOpen: !!element,
        openSlider: handleOpen,
        closeSlider: handleClose,
      }}
    >
      {children}
      <SlideOver
        isOpen={isOpen}
        onClose={handleClose}
        onFinishTransition={handleFinishTransition}
      >
        {element}
      </SlideOver>
    </SlideOverContext.Provider>
  );
};

export { SlideOverProvider, SlideOverContext };
