import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useInView } from 'react-intersection-observer';

type IntersectionConfig = {
  rootMargin: string;
} & Record<string, unknown>;

/**
 * enables lazy render of components given an intersection. combine this with lazy loading to split and lazy load the bundle.
 * @return
 * [refToAttachToNode, isFirstMountBoolean]
 *
 * tips: Increase the rottMargin property if you want to load before it intersect (i.e. {rootMargin: 300px})
 */

export const useInitialRenderOnIntersection = (
  intersectionConfig?: IntersectionConfig
): [
  (node?: Element | null | undefined) => void,
  boolean,
  Dispatch<SetStateAction<boolean>>
] => {
  const inViewConfig = { rootMargin: '0px', ...intersectionConfig };
  const [hasMountedOnceBefore, setHasMountedOnceBefore] = useState(false);
  const [compRef, isInView] = useInView(inViewConfig);

  useEffect(() => {
    if (isInView && !hasMountedOnceBefore) {
      setHasMountedOnceBefore(true);
    }
  }, [isInView, hasMountedOnceBefore]);

  return [compRef, hasMountedOnceBefore, setHasMountedOnceBefore];
};
