import { IsoString, IsoStringWithTZOffset } from '@tapestry/types';

type DateString = `${string}-${string}-${string}`; //i.e. 2024-11-03

/**
 * Split a datetime string and return the date component
 *
 * Require `T`
 */
export const extractDate = (datetime: IsoString | IsoStringWithTZOffset) => {
  return datetime.split('T')[0] as DateString;
};
