import { useAuthToken } from '@tapestry/shared/client';
import * as React from 'react';
import { IAttachment } from '@tapestry/types';
import { useEffect, useRef } from 'react';

// TODO[high]: check if the error type is correct
type Error = string | undefined;

type UseFileUploadProps = (
  config?: Partial<{
    onSuccess: (data: IAttachment) => void;
    onError: (error: Error) => void;
    onComplete: (data: IAttachment | undefined, error: Error) => void;
  }>
) => {
  uploadFile: (file: File) => Promise<void>;
  data: IAttachment | undefined;
  isUploading: boolean;
  error: Error;
};

const useFileUpload: UseFileUploadProps = (config) => {
  const accessToken = useAuthToken();

  const [data, setData] = React.useState<IAttachment | undefined>(undefined);
  const [isUploading, setIsUploading] = React.useState(false);
  const [error, setError] = React.useState<Error>();

  // hook keeps snapshot of the callback functions on each render,
  // to make sure that the latest version of callback is called;
  // keep the callback in the ref to avoid the stale closure
  const onSuccessCallbackRef = useRef<(data: IAttachment) => void>();
  const onErrorCallbackRef = useRef<(error: Error) => void>();
  const onCompleteCallbackRef =
    useRef<(data: IAttachment | undefined, error: Error) => void>();

  useEffect(
    function updateOnSuccessCallbackRef() {
      if (config?.onSuccess) {
        onSuccessCallbackRef.current = config?.onSuccess;
      }
    },
    [config?.onSuccess]
  );

  useEffect(
    function updateOnErrorCallbackRef() {
      if (config?.onError) {
        onErrorCallbackRef.current = config?.onError;
      }
    },
    [config?.onError]
  );

  useEffect(
    function updateOnCompleteCallbackRef() {
      if (config?.onComplete) {
        onCompleteCallbackRef.current = config?.onComplete;
      }
    },
    [config?.onComplete]
  );

  const handleUploadFile = async (file: File) => {
    if (!file) {
      return;
    }

    if (file) {
      setIsUploading(true);
      setData(undefined);
      setError(undefined);

      try {
        const formData = new FormData();
        formData.append(
          'file',
          file,
          file.name.replace(/[^a-zA-Z0-9-_.]/g, '')
        );

        const response = await fetch(
          `${process.env.NEXT_PUBLIC_API_REST_URL}/api/upload`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error('An error occurred');
        }

        const data = (await response.json()) as IAttachment;

        if (onSuccessCallbackRef?.current) {
          onSuccessCallbackRef?.current(data);
        }

        setData(data);
      } catch (error: unknown) {
        const _error = (error as Error) ?? 'Internal Server Error';

        setError(() => {
          if (onErrorCallbackRef?.current) {
            onErrorCallbackRef?.current(_error);
          }

          return _error;
        });
      } finally {
        if (onCompleteCallbackRef?.current) {
          onCompleteCallbackRef?.current(data, error);
        }

        setIsUploading(false);
      }
    }
  };

  return {
    uploadFile: handleUploadFile,
    data,
    isUploading,
    error,
  };
};

export { useFileUpload };
