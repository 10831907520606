import React, { FC } from 'react';
import { LoadingSpinnerIcon } from '@tapestry/shared/icons';

const LoadingIndicator: FC = () => {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      <h1 className="text-3xl font-bold">Loading...</h1>
      <h2 className="text-lg font-bold">Won't be a minute!</h2>
      <div className="mt-4 h-24 w-24">
        <LoadingSpinnerIcon />
      </div>
    </div>
  );
};

export { LoadingIndicator };
