import { IsoString } from '@tapestry/types';
import { ICalProps } from './Calendar';
import { dateTime } from '@tapestry/shared/utils';
import {
  NullableISOStringsDateRange,
  MaybePartialJSDateRange,
  NullableJSDate,
} from './types';

const toDateIfDate = (date: string | null) => {
  return date ? new Date(date) : null;
};

export const parseDateStringToJSDate = (dates: ICalProps['value']) => {
  if (Array.isArray(dates)) {
    const [start, end] = dates;
    const range: MaybePartialJSDateRange = [
      toDateIfDate(start),
      toDateIfDate(end),
    ];

    return range;
  }

  return toDateIfDate(dates);
};

function getStandardTime(where: 'to-start' | 'to-end') {
  return where === 'to-start' ? '00:00:00.000' : '23:59:59.999';
}

const standardiseDate = (
  date: Date,
  where: 'to-start' | 'to-end' = 'to-start'
): IsoString => {
  const parsedDate = dateTime.parse(date);
  const year = parsedDate.format('YYYY');
  const month = parsedDate.format('MM');
  const day = parsedDate.format('DD');

  const standardTime = getStandardTime(where);

  const standardisedString = `${year}-${month}-${day}T${standardTime}Z`;

  return standardisedString as IsoString;
};

export const parseJSDateToISOString = (
  date: NullableJSDate | MaybePartialJSDateRange
): IsoString | NullableISOStringsDateRange | null => {
  if (!date) return null;

  if (Array.isArray(date)) {
    const [start, end] = date;

    return [
      start ? standardiseDate(start, 'to-start') : null,
      end ? standardiseDate(end, 'to-end') : null,
    ];
  }

  return standardiseDate(date);
};
