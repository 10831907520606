import { DOMAttributes, FC, ReactNode, forwardRef } from 'react';
import {
  ModalContextProvider,
  useModalContext,
} from './modal-context/modal-context';
import { Transition, TransitionChild } from '@headlessui/react';
import React from 'react';
import {
  AriaModalOverlayProps,
  useModalOverlay,
  OverlayContainer,
} from 'react-aria';
import { ModalState } from '.';
import { useDebouncedCallback } from 'use-debounce';

interface IModalProps extends AriaModalOverlayProps {
  children: ReactNode;
  state: ModalState;
}

function isAtBottom(node) {
  return node.scrollHeight - node.scrollTop === node.clientHeight;
}

interface PanelProps extends DOMAttributes<HTMLDivElement> {
  children: ReactNode;
}

const Panel = forwardRef<HTMLDivElement, PanelProps>((props, ref) => {
  const { setIsBottomOfScrollContainer } = useModalContext();

  const { callback: debounceNotifyOfBottomingOut } = useDebouncedCallback(
    function NotifyOfBottomingOut(e) {
      setIsBottomOfScrollContainer(isAtBottom(e.target) ? true : false);
    },
    100
  );

  return (
    <div
      ref={ref}
      className="no-scrollbar relative max-h-full w-full overflow-y-auto rounded-lg bg-white"
      onScroll={debounceNotifyOfBottomingOut}
      {...props}
    >
      {props.children}
    </div>
  );
});

const Modal: FC<IModalProps> = ({ children, state, ...props }) => {
  const modalRef = React.useRef(null);
  const { modalProps, underlayProps } = useModalOverlay(props, state, modalRef);

  return (
    <OverlayContainer>
      <ModalContextProvider modalTriggerState={state}>
        <Transition as="div" appear show={state?.isOpen || false}>
          {/* Fades in/out the overlay */}
          <TransitionChild
            as="div"
            enter="transition ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="z-modal fixed inset-0 top-0 flex items-center justify-center overflow-y-scroll p-4"
          >
            {/* The underlay */}
            <div
              className="fixed inset-0 bg-black opacity-25"
              {...underlayProps}
            />

            {/* The panel */}
            <TransitionChild
              as="div"
              enter="transition ease-out duration-300"
              enterFrom="opacity-0 -translate-y-32"
              enterTo="opacity-100 -translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 -translate-y-0"
              leaveTo="opacity-0 -translate-y-32"
              className="z-10 mx-auto h-full max-h-full w-full max-w-screen-md"
            >
              <Panel ref={modalRef} {...modalProps}>
                {children}
              </Panel>
            </TransitionChild>
          </TransitionChild>
        </Transition>
      </ModalContextProvider>
    </OverlayContainer>
  );
};

export default Modal;
