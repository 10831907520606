import { UserSetting } from '@tapestry/types';
import { useCallback } from 'react';
import { useProfile } from '../use-profile/useProfile';
import { useRemoveUserSettingsByKeys } from '@tapestry/shared/graphql';
import isEmpty from 'lodash/isEmpty';

const CURRENT_USER_SETTING_VERSION = 2.0;

export const useUpdateUserSettings = (): ((
  setting: UserSetting,
  newValue
) => void) => {
  const [, updateProfile] = useProfile();
  const [removeSetting] = useRemoveUserSettingsByKeys();

  // ? if you were to provide type to updateUserSettings(), could you pass it to new value
  const updateUserSettings = useCallback(
    (setting: UserSetting, newValue: any) => {
      // Might want to only have `undefined` as a removal option in the future
      if (newValue === undefined || newValue === null || isEmpty(newValue)) {
        removeSetting({
          variables: {
            keys: [setting],
          },
        });
      } else {
        updateProfile({
          variables: {
            user_settings: {
              version: CURRENT_USER_SETTING_VERSION,
              [setting]: newValue,
            },
          },
        });
      }
    },
    []
  );

  return updateUserSettings;
};

export default useUpdateUserSettings;
