import React, { KeyboardEvent } from 'react';
import { DateString, ICalProps } from '../../Calendar';
import { dateTime } from '@tapestry/shared/utils';

export interface InputProps {
  ref: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
}

export interface ToggleButtonProps {
  'aria-label': string;
  onClick: () => void;
  onKeyPress: (e: KeyboardEvent) => void;
  type: 'button';
  title: string;
  disabled?: boolean;
}

export interface IDatePickerProps
  extends Omit<ICalProps, 'value' | 'onChange'> {
  children?:
    | ((props: {
        inputProps: InputProps;
        toggleButtonProps: ToggleButtonProps;
      }) => React.ReactNode)
    | React.ReactNode;
  value: DateString | string | null;
  onChange: (date: DateString | string | null) => void;
  onDateSelection?: (date: DateString | null) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  clearable?: boolean;
  name?: string;
  wrapperClassName?: string;
  hasBorders?: boolean;
}
const POSSIBLE_DATE_FORMATS = ['DD/MM/YYYY', 'DDMMYYYY', 'YYYY-MM-DD'];
export const DISPLAY_FORMAT = 'DD/MM/YYYY';

/**
 * if it's a valid date, return the date in YYYY-MM-DD format else the pass the value through
 */
export const parsedOrPassedDate = (value: string) => {
  const parsedDate = dateTime.parse(value, POSSIBLE_DATE_FORMATS);

  if (parsedDate.isValid()) {
    return parsedDate.format('YYYY-MM-DD'); // as DateString;
  } else {
    return value;
  }
};

/**
 * ditto as above but with formatting
 */
export const formattedOrPassedDate = (value: string) => {
  const parsedDate = dateTime.parse(value, POSSIBLE_DATE_FORMATS);

  if (parsedDate.isValid()) {
    return dateTime.format(value, DISPLAY_FORMAT, 'YYYY-MM-DD'); // as DateString;
  } else {
    return value;
  }
};
