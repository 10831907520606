import { captureException } from '@sentry/nextjs';

export const safeJSONParse = (string: string) => {
  if (!string) return null;
  let parsed;

  try {
    parsed = JSON.parse(string);
  } catch (error: any) {
    console.error(`safeJSONParse() error from this string: ${string}`, error);
    captureException(error);
  }

  return parsed;
};

export const safeJSONStringify = (object: Record<string, unknown>) => {
  let parsed = '';

  try {
    parsed = JSON.stringify(object);
  } catch (error: any) {
    console.error(
      `safeJSONStringify() error from this object: ${object}`,
      error
    );
    captureException(error);
  }

  return parsed;
};
