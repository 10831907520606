import { useModalContext } from '../modal-context/modal-context';
import { CloseIcon } from '@tapestry/shared/icons';
import { FC, ReactNode } from 'react';

interface IDialogProps {
  children: ReactNode;
}

export const Dialog: FC<IDialogProps> = ({ children }) => {
  const { dialogRef, dialogProps, modalTriggerState } = useModalContext();

  return (
    <div
      {...dialogProps}
      ref={dialogRef}
      className="outline-none text-center pt-4 h-full"
    >
      <div className="text-right px-4 sm:px-8">
        <button
          title="Close"
          aria-label="Close"
          className="hover:bg-gray-hover focus:bg-gray-hover inline-flex h-6 w-6 items-center justify-center rounded-full p-1 disabled:cursor-wait"
          onClick={modalTriggerState.close}
          onKeyUp={({ key }) => {
            if (key === 'Enter') {
              modalTriggerState.close();
            }
          }}
        >
          <CloseIcon light />
        </button>
      </div>

      {children}
    </div>
  );
};
