import { ITextInput } from './FormTextInput';

export const getBorderColor = (
  inputError: string | undefined,
  clearBorder: boolean
) => {
  if (inputError) {
    return 'border-red-light';
  }

  return clearBorder ? 'border-transparent' : 'border-gray-border';
};

export const getTextAlignment = (alignment: ITextInput['textAlign']) => {
  switch (alignment) {
    case 'left':
      return 'text-left';

    case 'center':
      return 'text-center';

    case 'right':
      return 'text-right';

    default:
      return 'text-left';
  }
};

export const getBorderRadius = (br: ITextInput['borderRadius']) => {
  switch (br) {
    case 'sm':
      return 'rounded-sm';

    case 'md':
      return 'rounded-md';

    case 'lg':
      return 'rounded-lg';

    default:
      return 'rounded-sm';
  }
};
