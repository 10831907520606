import React, { FC } from 'react';
import { useNumberFormatter } from 'react-aria';

interface ICurrencyProps {
  value: number;
  currency?: string | null;
  maximumFractionDigits?: number;
}

/**
 * formats a number into a valid currency locale
 *
 * Alternative: use `Number` for a simple number
 * @param value - number
 * @param currency - ISO 4217 currency codes (default: AUD)
 * @param maximumFractionDigits - number (default: 0)
 */
export const Currency: FC<ICurrencyProps> = ({
  value,
  currency = 'AUD',
  maximumFractionDigits = 0,
}) => {
  const formatter = useNumberFormatter({
    style: 'currency',
    currency: currency || 'AUD',
    maximumFractionDigits,
  });

  return <data value={value}>{formatter.format(value)}</data>;
};
