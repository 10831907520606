import React from 'react';
import {
  FormInputBase,
  IFormInputBaseProps,
} from '../FormInputBase/FormInputBase';
import { twMerge } from 'tailwind-merge';
import { getBorderColor, getBorderRadius, getTextAlignment } from './utils';

export interface TextAreaProps extends IFormInputBaseProps {
  name: string;
  placeholder?: string;
  value?: string | number | undefined;
  textAlign?: 'left' | 'center' | 'right';
  clearBorder?: boolean;
  borderRadius?: 'sm' | 'md' | 'lg';
  moreVerticalPadding?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
}

/**
 * Form Input component
 *
 * Alternative: for the an input box
 *
 * @param name - input name.
 * @param errors - error string
 * @param type - html input type
 * @param placeholder - i.e. your name
 * @param textAlign - `left` | `center` | `right`
 * @param clearBorder - for transparent borders
 * @param borderRadius - default: `md`. can be `sm` | `md` | `lg`
 * @param moreVerticalPadding - adds more vertical padding, sometime needed,
 *
 */
export const FormTextArea = React.forwardRef<
  HTMLTextAreaElement,
  TextAreaProps
>(
  (
    {
      placeholder = '',
      textAlign = 'left',
      clearBorder = false,
      borderRadius = 'md',
      autoFocus = false,
      ...props
    },
    ref
  ) => {
    const borderColor = getBorderColor(props?.error, clearBorder);
    const _textAlign = getTextAlignment(textAlign);
    const _borderRadius = getBorderRadius(borderRadius);

    const { labelSROnly: _, ...inputProps } = props;

    return (
      <FormInputBase {...props}>
        <textarea
          ref={ref}
          autoFocus={autoFocus}
          className={twMerge(
            `placeholder-gray-DEFAULT focus:border-orange-hank sm:pr-18 w-full border py-4 pl-6 pr-10 text-base font-normal tracking-wider text-black focus:outline-none disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-600`,
            _textAlign,
            _borderRadius,
            borderColor,
            inputProps.disabled ? 'cursor-not-allowed bg-gray-100' : 'bg-white'
          )}
          placeholder={placeholder || 'Type something...'}
          {...inputProps}
        />
      </FormInputBase>
    );
  }
);
