import * as React from 'react';
import { twMerge } from 'tailwind-merge';

interface IDropdownMenuItemProps {
  icon?: React.ReactNode;
  label: string;
  subLabel?: string;
  onClick?: any;
  asLink?: boolean;
  url?: string;
  isActive?: boolean;
}

export const DropdownMenuItem: React.FC<IDropdownMenuItemProps> = ({
  icon,
  label,
  subLabel,
  onClick,
  asLink,
  url,
  isActive,
}) => {
  const classNames = twMerge(
    subLabel ? 'items-start' : 'justify-center items-center bg-gray-100',
    isActive && 'bg-primary-lightest',
    'group w-full flex px-2 py-2 text-sm font-medium text-black rounded-md hover:bg-gray-hover active:bg-gray-active focus:outline-none focus:bg-gray-hover'
  );

  if (asLink) {
    return (
      <a
        className={classNames}
        href={url}
        onClick={onClick}
        target="_blank"
        rel="noreferrer"
      >
        {icon && (
          <span
            className={`mr-2 flex h-5 w-5 items-center justify-center ${
              subLabel ? 'mt-1' : ''
            }`}
          >
            {icon}
          </span>
        )}
        <div className={`${subLabel ? 'text-left' : 'mx-auto'}`}>
          <p className="-mb-px font-bold tracking-wide">{label}</p>
          {subLabel && <p className="text-gray-text text-xs">{subLabel}</p>}
        </div>
      </a>
    );
  }

  return (
    <button
      type="button"
      className={classNames}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
    >
      {icon && (
        <span
          className={`mr-2 flex h-5 w-5 items-center justify-center ${
            subLabel ? 'mt-1' : ''
          }`}
        >
          {icon}
        </span>
      )}
      <div className={`${subLabel ? 'text-left' : 'mx-auto'}`}>
        <p className="-mb-px font-bold tracking-wide">{label}</p>
        {subLabel && <p className="text-gray-text text-xs">{subLabel}</p>}
      </div>
    </button>
  );
};

export default DropdownMenuItem;
