import { loadable } from '@tapestry/shared/lazy-load';
import React, { FC, InputHTMLAttributes } from 'react';
import { Control } from 'react-hook-form';
import { Props } from 'react-phone-number-input/react-hook-form';

// * Note This import is for any use
// 'react-phone-number-input/react-hook-form' is a version of the library that is made specifically to work with react-hook-form
// Alternatively, you can import the root for the default version like so:
// import PhoneInput from 'react-phone-number-input';
const LazyReactHookFormPhoneInput = loadable<
  Props<InputHTMLAttributes<HTMLInputElement>, object>
>(() => import('react-phone-number-input/react-hook-form'), {
  ssr: false,
  chunkName: 'phone-input',
});

/**
 * Library used
 *
 * https://www.npmjs.com/package/react-phone-number-input?activeTab=readme
 *
 * * stylesheet in app `styles` folder
 */

interface IPhoneNumberInputProps {
  name: string;
  control: Control<NonNullable<unknown>>;
}

/**
 * Input used to pick a flag and mobile number
 * Takes care of all the parsing
 *
 * alternative: for a simple form input use `FormTextInput`
 *
 * @return string in e.614 format "+1123123123133"
 */
export const PhoneNumberInput: FC<IPhoneNumberInputProps> = ({
  name,
  control,
}) => {
  return (
    <LazyReactHookFormPhoneInput
      placeholder="Enter mobile number"
      defaultCountry="AU"
      name={name}
      control={control}
    />
  );
};

export default PhoneNumberInput;
