import * as React from 'react';
import { IIconProp, TapestryLogoIcon } from '@tapestry/shared/icons';
import { twMerge } from 'tailwind-merge';
import styles from './shared-tapestry-splash-screen.module.css';
import { useUIContext } from '@tapestry/shared/client';

export interface ITapestrySplashScreenProps {
  title: string;
  color: string;
  brandIcons: { long: React.FC<IIconProp> };
}

const TapestrySplashScreen: React.FC<ITapestrySplashScreenProps> = (props) => {
  const [{ showSplashScreen, splashScreenProps }] = useUIContext();

  if (!showSplashScreen) {
    return null;
  }

  const mergedProps = { ...props, ...splashScreenProps };
  const {
    color: backgroundColor,
    brandIcons: { long: Icon },
  } = mergedProps;

  return (
    <div
      className="z-modal fixed inset-0 flex flex-col items-center justify-center"
      style={{ backgroundColor }}
      data-cy="splash-screen"
    >
      <div className="flex flex-1 translate-y-8 flex-col items-center justify-center">
        <div className="h-14 w-auto sm:h-24">
          <Icon fillColor="#fff" />
        </div>

        <span className="relative mt-8 inline-flex h-10 w-10 items-center justify-center rounded-full p-2 sm:h-10 sm:w-10">
          <svg
            className="absolute left-0 top-0 h-full w-full rounded-full"
            fill="transparent"
          >
            <circle
              className="absolute left-0 top-0 h-full w-full"
              fill="transparent"
              stroke="rgba(0,0,0,0.2)"
              strokeWidth="6"
              cx="50%"
              cy="50%"
              r="50%"
            />
            <circle
              className={twMerge(
                styles['svg-circle__spin'],
                'absolute left-0 top-0 h-full w-full'
              )}
              fill="transparent"
              stroke="black"
              strokeWidth="6"
              cx="50%"
              cy="50%"
              r="50%"
              strokeDasharray="129"
              strokeDashoffset="129"
            />
          </svg>
        </span>
      </div>

      <div className="mb-8 text-center text-white">
        <p className="text-xxs mb-2">Powered by</p>
        <TapestryLogoIcon
          width={'40px'}
          height={'40px'}
          className="mx-auto"
          inWhite
        />
        <p className="text-base font-medium">tapestry</p>
      </div>
    </div>
  );
};

export { TapestrySplashScreen };
