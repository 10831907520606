import { Transition } from '@headlessui/react';
import { ShareSheetIcon } from '@tapestry/shared/icons';
import { Button } from '@tapestry/weave';
import { FC, useEffect, useRef } from 'react';
import { FocusScope, useModalOverlay } from 'react-aria';
import {
  BeforeInstallPromptEvent,
  TPlatform,
} from './AppInstallationPrompt.type';
import { getDevicePlatform, isInStandaloneMode } from './utils';
import { useModal } from '@tapestry/shared/hooks';

interface IAppInstallationPrompt {
  appIconSrc: string;
  appName: string;
}

const LAST_PROMPT_DATE_KEY = 'lastPromptTimestamp';

const ENABLED_PLATFORMS = ['android'];

const shouldShowPrompt = (devicePlatform: TPlatform) => {
  const isMobileDevice = ENABLED_PLATFORMS.includes(devicePlatform);

  if (!isMobileDevice || isInStandaloneMode()) {
    return false;
  }

  const todayTimestamp = new Date().setHours(0, 0, 0, 0);
  const lastPromptTimestamp = localStorage.getItem(LAST_PROMPT_DATE_KEY);

  if (lastPromptTimestamp) {
    const daysSinceLastPrompt = Math.floor(
      (todayTimestamp - Number(lastPromptTimestamp)) / 86400000
    );

    if (daysSinceLastPrompt < 14) {
      return false;
    }
  }

  localStorage.setItem(LAST_PROMPT_DATE_KEY, todayTimestamp.toString());

  return true;
};

let deferredPrompt: BeforeInstallPromptEvent | undefined;
const devicePlatform = getDevicePlatform();

const AppInstallationPrompt: FC<IAppInstallationPrompt> = ({
  appIconSrc,
  appName,
}) => {
  const modalRef = useRef(null);
  const promptModalState = useModal({
    defaultOpen: shouldShowPrompt(devicePlatform),
  });

  const { modalProps, underlayProps } = useModalOverlay(
    { isDismissable: true },
    promptModalState,
    modalRef
  );

  const handleInstallButtonClick = () => {
    if (!deferredPrompt) {
      return;
    }

    deferredPrompt.prompt();
  };

  const beforeInstallPromptListener = (evt: BeforeInstallPromptEvent) => {
    evt.preventDefault();

    deferredPrompt = evt;
  };

  useEffect(() => {
    if (shouldShowPrompt(devicePlatform)) {
      promptModalState.open();

      window.addEventListener(
        'beforeinstallprompt',
        beforeInstallPromptListener
      );
    }

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        beforeInstallPromptListener
      );

      deferredPrompt = undefined;
    };
  }, []);

  return (
    <Transition
      as="div"
      show={promptModalState.isOpen}
      enter="transition transform duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="z-modal fixed inset-0 flex h-full w-full items-end justify-center p-4"
    >
      <div className="fixed inset-0 bg-black opacity-50" {...underlayProps} />
      <FocusScope contain restoreFocus autoFocus>
        <div
          className="z-modal flex w-full max-w-screen-md flex-col items-center justify-center gap-4 rounded-md bg-white p-8 text-center"
          ref={modalRef}
          {...modalProps}
        >
          <img
            src={appIconSrc}
            alt="App Logo"
            width="50"
            height="50"
            className="rounded-md"
          />
          <h2 className="text-xl font-bold capitalize">Install {appName}</h2>
          <p>Install as an app on your device for quick access</p>

          {devicePlatform === 'android' ? (
            <Button
              spacing="small"
              status="primary"
              onClick={handleInstallButtonClick}
            >
              Install
            </Button>
          ) : (
            <p className="flex flex-wrap items-center justify-center text-sm">
              Tap <ShareSheetIcon className="mx-1 inline h-6 w-6" />
              then select 'Add to Home Screen'
            </p>
          )}
        </div>
      </FocusScope>
    </Transition>
  );
};

export default AppInstallationPrompt;
