import { FC, useMemo } from 'react';
import { Circle, Pill } from '@tapestry/weave';
import { SlidersIcon } from '@tapestry/shared/icons';
import { twMerge } from 'tailwind-merge';
import { AppletFilterMobileButton } from './AppletFilterMobileButton';
import type { AppletFilterButtonProps } from './AppletFilterButton.type';

const AppletFilterButton: FC<AppletFilterButtonProps> = ({
  activeFiltersCount,
  handleOpenModal,
  handleFiltersReset,
  disabled = false,
}) => {
  const hasFilters = activeFiltersCount > 0;
  const onClearhandler = useMemo(
    () =>
      hasFilters
        ? (e: React.MouseEvent | React.KeyboardEvent) => {
            e.stopPropagation();

            handleFiltersReset();
          }
        : undefined,
    [hasFilters, handleFiltersReset]
  );

  return (
    <button
      title="open filters menu"
      onClick={handleOpenModal}
      onKeyDown={({ key }) => {
        if (key === 'Enter') {
          handleOpenModal();
        }
      }}
      disabled={disabled}
      className="group relative rounded-full focus:outline-none"
    >
      <span className="sr-only">Open filters menu</span>

      <span>
        <AppletFilterMobileButton
          disabled={disabled}
          activeFiltersCount={activeFiltersCount}
        />
      </span>
      <Pill
        iconLeft={<SlidersIcon fillColor={disabled ? '#eee' : '#000'} />}
        label="Filters"
        size="xxl"
        textStyle="capitalize"
        bgColor="bg-white"
        className={twMerge(
          'border-gray-border group-hover:border-primary group-focus:border-primary hidden border-2 text-black shadow-sm transition-colors duration-150 md:inline-flex',
          disabled ? 'text-gray-disabled' : 'text-black'
        )}
        onClear={onClearhandler}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onClearhandler?.(e);
          }
        }}
        iconRight={
          hasFilters ? (
            <Circle size="size-6" className="bg-red font-normal text-white">
              {activeFiltersCount}
            </Circle>
          ) : null
        }
      />
    </button>
  );
};

export { AppletFilterButton };
