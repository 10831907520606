import Skeleton from 'react-loading-skeleton';

const ShopCardLoading = () => (
  <div className="mx-auto box-border block w-full cursor-pointer overflow-hidden rounded-lg bg-white shadow-md focus:outline-none focus:ring-2">
    <div className="-mt-1 w-full ">
      <Skeleton height={100} />
    </div>

    <div className="p-4 text-left">
      <h3
        className={`truncate-2-lines text-base font-bold leading-6 text-black sm:text-lg`}
      >
        <Skeleton />
      </h3>
      <p className="w-1/2">
        <Skeleton />
      </p>

      <div className="mt-3 flex flex-row items-end justify-between">
        <Skeleton circle height={40} width={40} />

        <Skeleton width={100} height={16} />
      </div>
    </div>
  </div>
);

export { ShopCardLoading };
