export enum Env {
  Local = 'development',
  Staging = 'staging',
  Production = 'production',
  Test = 'testing',
}

export enum Scopes {
  devMode = 'devMode',
}
