export function transformObjectEmtpyArrayValuesToUndefined<T extends object>(
  filters: T
): T {
  const newObject = Object.keys(filters).reduce((acc, key) => {
    if (Array.isArray(filters[key]) && filters[key].length === 0) {
      acc[key] = undefined;
    } else {
      acc[key] = filters[key];
    }

    return acc;
  }, {} as T);

  return newObject;
}
