// *******************************************
// Module Imports
// -------------------------------------------
import React, { useState } from 'react';
import { useToast } from '@tapestry/shared/client';
import { useAppMediaQuery } from '@tapestry/weave';
import { IThreadV4Type, useUpdateThread } from '@tapestry/shared/graphql';
import { LoadingSpinnerIcon } from '@tapestry/shared/icons';
import { Button } from '@tapestry/weave';
import { IMockRecommendedTask } from '@tapestry/types';
import TaskListItem from '../../TaskListItem/TaskListItem';
export interface ITasksListProps {
  listData: IThreadV4Type[] | IMockRecommendedTask[];
  loading?: boolean;
  showMax?: number;
  onShowMore?: () => void;
  showMoreButtonLabel?: string;
  hideShowMoreButton?: boolean;
  asRecommendedTasks?: boolean;
  onDeleteComplete?: VoidFunction;
}

/**
 * TasksList
 *
 * Renders a list of TaskListItem, generally associated with Tasks applet
 */
export const TasksList = ({
  listData = [],
  loading,
  showMax = 7,
  onShowMore,
  showMoreButtonLabel = 'Show More',
  hideShowMoreButton = false,
  asRecommendedTasks = false,
  onDeleteComplete,
}: ITasksListProps) => {
  const { isPhone } = useAppMediaQuery();
  const [isShowMoreToDoTask, setIsShowMoreToDoTask] = useState(false);
  const { addToast } = useToast();

  const [mutateThread, { loading: isLoadingCompletion }] = useUpdateThread({
    onCompleted: ({ updateThreadV4 }) => {
      addToast({
        type: 'taskCompleted',
        content: updateThreadV4?.title,
      });
    },
    onError: () =>
      addToast({
        type: 'error',
      }),
    refetchQueries: ['getThreads'], //TODO review this
  });

  return (
    <>
      <section>
        <h4 className="sr-only">Task list</h4>
        <div className="text-center">
          <ul className="mb-5">
            {listData
              ?.slice(0, isShowMoreToDoTask ? 999 : showMax)
              .map((task) => (
                <TaskListItem
                  key={task?.id}
                  task={task}
                  mutateThread={mutateThread}
                  asRecommendedTasks={asRecommendedTasks}
                  onDeleteComplete={onDeleteComplete}
                />
              ))}
          </ul>

          {listData && listData?.length > showMax && !hideShowMoreButton ? (
            <Button
              loading={loading ?? false}
              fullWidth={isPhone ? true : false}
              status="basic"
              spacing="small"
              onClick={() => {
                if (onShowMore) {
                  onShowMore();
                }

                setIsShowMoreToDoTask(!isShowMoreToDoTask);
              }}
            >
              {isShowMoreToDoTask ? 'Show Less' : showMoreButtonLabel}
            </Button>
          ) : null}
        </div>
      </section>

      {isLoadingCompletion && (
        <div className="fixed top-0 left-1/2 z-40 mt-24 inline-block h-8 w-8 -translate-y-1/2  sm:mt-32">
          <LoadingSpinnerIcon />
        </div>
      )}
    </>
  );
};
