import NextLink from 'next/link';
import { FC } from 'react';
import { getStyles, ILink } from './link-utils';

export const Link: FC<ILink> = ({
  href,
  title,
  children,
  external,
  type,
  spacing,
  className,
}) => {
  const externalProps = external
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {};

  const styles = getStyles(type, spacing, className);

  return (
    <NextLink href={href} title={title} {...externalProps} className={styles}>
      {children}
    </NextLink>
  );
};
