import {
  avatarPlaceholder,
  BakeryIconWithBubble,
  CalendarIcon,
  DepartmentIconWithBubble,
  DepartmentsIcon,
  ExclamationIcon,
  ExternalLinkIcon,
  PaperClipIcon,
  ProductIcon,
  ShopIcon,
  ShopIconWithBubble,
  StaffIcon,
  TagIcon,
  TickIcon,
  ProductIconWithBubble,
  BellIcon,
} from '@tapestry/shared/icons';
import { THREAD_TYPE, Twist } from '@tapestry/types';
import { TwistConfigObj } from '@tapestry/types';

export type TwistConfig = {
  [key in Twist]?: TwistConfigObj;
};

export const TWIST_CONFIG: TwistConfig = {
  [Twist.Title]: {
    key: Twist.Title,
    label: 'Task name', // Temporary changed, the value should come from the thread template
    inputPlaceholder: 'Write a task name',
    icon: TickIcon,
    iconBubbleColor: '#D20F3B',
    config: {
      required: true,
    },
  },
  [Twist.DueDate]: {
    key: Twist.DueDate,
    label: 'Due date',
    icon: CalendarIcon,
    iconBubbleColor: '#D20F3B',
  },
  [Twist.EstimateDuration]: {
    key: Twist.EstimateDuration,
    label: 'Estimated Duration',
    icon: BellIcon,
    iconBubbleColor: '#D20F3B',
  },
  [Twist.Shops]: {
    key: Twist.Shops,
    label: 'Shop',
    subLabel: 'Choose shops to tag to this task.',
    inputPlaceholder: 'Type a shop name',
    avatarPlaceholder: ShopIconWithBubble,
    iconBubbleColor: '#ed7725',
    icon: ShopIcon,
    threadType: THREAD_TYPE.SHOP,
    config: { isMulti: true },
  },
  [Twist.AssingedTo]: {
    key: Twist.AssingedTo,
    label: 'Assign to',
    subLabel: 'Search and add a staff member to this task.',
    inputPlaceholder: 'Type name',
    avatarPlaceholder: avatarPlaceholder as unknown as string,
    iconBubbleColor: null,
    icon: StaffIcon,
    config: { isMulti: true },
  },
  [Twist.MoreInformation]: {
    key: Twist.MoreInformation,
    label: 'Information',
    icon: ExclamationIcon,
    config: { minRows: 4 },
  },
  [Twist.Departments]: {
    key: Twist.Departments,
    threadType: THREAD_TYPE.DEPARTMENT,
    label: 'Department',
    subLabel: 'Choose departments to tag to this task.',
    inputPlaceholder: 'Type a department name',
    avatarPlaceholder: DepartmentIconWithBubble,
    iconBubbleColor: '#A1AA1E',
    icon: DepartmentsIcon,
    config: { isMulti: true },
  },
  [Twist.Product]: {
    key: Twist.Product,
    threadType: THREAD_TYPE.PRODUCT,
    label: 'Products',
    avatarPlaceholder: BakeryIconWithBubble,
    iconBubbleColor: '#44337a',
    icon: ProductIcon,
    config: {
      isMulti: true,
      queryLimit: 100,
      isFetchable: true,
      queryWithActiveId: true,
    },
  },
  [Twist.Attachments]: {
    key: Twist.Attachments,
    label: 'File and Image attachments',
    iconBubbleColor: '#0e73bb',
    icon: PaperClipIcon,
  },
  [Twist.AttachLink]: {
    key: Twist.AttachLink,
    label: 'External Link',
    iconBubbleColor: '#0e73bb',
    icon: ExternalLinkIcon,
  },
  [Twist.SubTasks]: {
    key: Twist.SubTasks,
    label: 'Sub Tasks',
    iconBubbleColor: '#0e73bb',
    icon: TickIcon,
  },
  [Twist.Tags]: {
    key: Twist.Tags,
    label: 'Tag',
    iconBubbleColor: '#0e73bb',
    icon: TagIcon,
  },
  [Twist.Products]: {
    key: Twist.Products,
    label: 'Products',
    subLabel: 'Choose product to tag to this task.',
    inputPlaceholder: 'Type a product name',
    avatarPlaceholder: ProductIconWithBubble,
    icon: ProductIcon,
    iconBubbleColor: '#44337a',
    threadType: THREAD_TYPE.PRODUCT,
    config: { isMulti: true },
  },
};
