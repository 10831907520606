import { Pill } from '@tapestry/weave';
import { ShoppingCartIcon } from '@tapestry/shared/icons';
import { ROUTE_PATHS } from '@tapestry/shared/constants';
import Link from 'next/link';
import Image from 'next/image';
import { FC } from 'react';

interface IShopCardProps {
  id: string;
  title: string;
  address?: {
    city?: string;
    state_code?: string;
    province?: string;
  };
  backgroundImageSrc: string;
  logoSrc: string;
  isPriority?: boolean;
}

export const ShopCard: FC<IShopCardProps> = ({
  id,
  title,
  address,
  backgroundImageSrc,
  logoSrc,
  isPriority = false,
}) => {
  return (
    <div className="mx-auto block w-full cursor-pointer rounded-lg bg-white shadow-md focus:outline-none focus:ring-2">
      <Link
        href={ROUTE_PATHS.build.heartbeat({ qp: `shopId=${id}` })}
        className="flex h-full flex-col"
        prefetch={false}
      >
        <div className="w-full flex-shrink-0 relative bg-gray-100 h-32 max-h-32 rounded-t-lg overflow-hidden">
          {backgroundImageSrc ? (
            <Image
              src={backgroundImageSrc}
              alt={`${title} shop`}
              className="object-cover"
              fill={true}
              priority={isPriority}
            />
          ) : null}
        </div>

        <div className="flex h-full flex-col justify-between p-4 text-left">
          <div>
            <h3
              className={`truncate-2-lines text-base font-bold leading-6 text-black sm:text-lg`}
            >
              {title}
            </h3>

            {address && (
              <p className="text-gray-text text-base">
                {`${address.city}, ${address.state_code ?? address.province}`}
              </p>
            )}
          </div>

          <div
            className={`mt-4 flex flex-row items-end ${
              logoSrc ? 'justify-between' : 'justify-end'
            } `}
          >
            {logoSrc ? (
              <Image
                src={logoSrc}
                alt="shop's logo"
                height={60}
                width={60}
                className="min-w-10 lg:h-15 lg:w-15 lg:min-w-15 h-10 w-10 rounded-full bg-white object-contain object-center shadow-md"
              />
            ) : null}

            <Pill
              label="supermarket"
              bgColor={'#CAD526'}
              textColor="text-white"
              size="small"
              iconLeft={<ShoppingCartIcon />}
            />
          </div>
        </div>
      </Link>
    </div>
  );
};
