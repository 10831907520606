import React from 'react';
import { BugIcon } from '@tapestry/shared/icons';

export const ChartErrorView = () => {
  return (
    <div className="flex h-full flex-col items-center justify-center bg-white">
      <BugIcon className="h-8 w-auto" />
      <p className="text-lg font-medium">Something unexpected happened...</p>
      <p className="mt-1 text-center text-sm">
        We are looking into it. <br />
        In the meantime, try navigating away and coming back or refresh the page
      </p>
    </div>
  );
};
