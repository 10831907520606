import { useMultipleSelection } from 'downshift';
import isEmpty from 'lodash/isEmpty';
import React, { FC } from 'react';
import { Pill } from '../../Pill';
import { InternalCombobox } from '../InternalCombobox';
import {
  ComboBoxOptionItem,
  ComboboxOptionsConfig,
  IMultiComboboxProps,
} from '@tapestry/types';
import { defaultLabelAccessor, getItemIcon } from '../combobox-utils';

/**
 *
 */
export const MultiCombobox: FC<IMultiComboboxProps> = (props) => {
  /**
   * prop extraction
   */
  const { onChange, options, defaultOptions } = props;
  const optionsConfig: ComboboxOptionsConfig = Array.isArray(options)
    ? { options }
    : options;
  const labelAccessor = optionsConfig.itemAccessor
    ? optionsConfig.itemAccessor
    : defaultLabelAccessor;

  /**
   * State
   */
  const {
    selectedItems,
    getSelectedItemProps,
    getDropdownProps,
    removeSelectedItem,
    addSelectedItem,
  } = useMultipleSelection({
    defaultSelectedItems: defaultOptions || [],
    onSelectedItemsChange({ selectedItems }) {
      onChange(selectedItems);
    },
  });

  /**
   * utils
   */
  const handleSelectedItemSelection = (selectedItem: ComboBoxOptionItem) => {
    if (selectedItems.some((item) => item.id === selectedItem?.id)) {
      removeSelectedItem(selectedItem);
    } else {
      addSelectedItem(selectedItem);
    }
  };

  return (
    <>
      <InternalCombobox
        multiSelectionSelectedItems={selectedItems}
        handleSelectedItemSelection={handleSelectedItemSelection}
        getDropdownProps={getDropdownProps}
        icon={optionsConfig?.itemIcon || null}
        {...props}
      />

      {!isEmpty(selectedItems) ? (
        <div className="mt-4 flex flex-wrap items-center gap-x-4 gap-y-2 overflow-hidden">
          {selectedItems.map((selectedItemToRender, index) => {
            return (
              <span
                {...getSelectedItemProps({
                  selectedItem: selectedItemToRender,
                  index,
                })}
                key={selectedItemToRender?.id}
                className="overflow-hidden"
              >
                <Pill
                  label={labelAccessor(selectedItemToRender)}
                  onClear={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    removeSelectedItem(selectedItemToRender);
                  }}
                  iconLeft={getItemIcon(
                    selectedItemToRender,
                    optionsConfig?.pillIcon || optionsConfig?.itemIcon
                  )}
                  className="w-full overflow-hidden px-1.5 py-1"
                />
              </span>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default MultiCombobox;
