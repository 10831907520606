import { dateTime } from '@tapestry/shared/utils';
import { DurationFormValue, IsoString } from '@tapestry/types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration, { DurationUnitType } from 'dayjs/plugin/duration';
import { Nullable } from '@tapestry/types';
dayjs.extend(duration);
dayjs.extend(relativeTime);

const getCompletionColor = (
  completion: boolean | null | string,
  datetime: string | null | undefined | boolean
) => {
  if (typeof completion === 'string') return;

  switch (completion) {
    case true:
      return 'text-green';

    case false:
      return 'text-black';

    case null:
      if (datetime && typeof datetime === 'string') {
        return dateTime.isBeforeNow(datetime)
          ? 'text-danger'
          : 'text-gray-text';
      }

      return 'text-gray-text';

    default:
      return 'text-gray-text';
  }
};

export const renderDatetime = (
  isoDateTime: IsoString | null,
  completion: boolean | null
) => {
  if (!isoDateTime) return null;

  const hasValue = completion !== null;
  const completionStatus = completion ? 'Completed' : 'Declined';
  const timeFromNow = dateTime.fromNow(isoDateTime);

  return (
    <span
      className={`flex items-center text-sm sm:text-base ${getCompletionColor(
        completion,
        isoDateTime
      )}`}
    >
      {/* {doesRepeat && (
        <RepeatIcon className="h-4 sm:h-5 mr-2" fillColor="currentColor" />
      )} */}

      {hasValue ? (
        completionStatus
      ) : (
        <>
          {dateTime.isBeforeNow(isoDateTime) ? 'Overdue -' : 'Due'}&nbsp;
          <time dateTime={isoDateTime}>{timeFromNow}</time>
        </>
      )}
    </span>
  );
};

export const getsubLabel = (
  shopTitle: Nullable<string>,
  departmentTitle: Nullable<string>
) => {
  // TODO uncomment when product twist is back 30 june 2022
  // let
  const productNameOrList = null;
  // if (Array.isArray(products)) {
  //   productNameOrList = products
  //     ?.map((product) => product.title)
  //     .join(', ');
  // } else if (products) {
  //   productNameOrList = products?.title;
  // }

  const sentenceArray = [shopTitle, departmentTitle, productNameOrList];

  return sentenceArray.filter((word) => word).join(', ');
};

export const getHumanizedDuration = (
  durationObj: Nullable<DurationFormValue>
) => {
  if (!durationObj) return null;

  if (durationObj.time_value === 'quarters') {
    if (durationObj.number === 1) {
      return 'a quarter';
    }

    return `${durationObj.number} quarters`;
  }

  return dayjs
    .duration(durationObj.number, durationObj.time_value as DurationUnitType)
    .humanize();
};
