import { FetchResult, Operation } from '@apollo/client';
import { IThreadV4Type } from '@tapestry/shared/graphql';

type TMaskingThreadV4 = (
  thread: IThreadV4Type,
  option?: { title?: string }
) => void;

const DEFAULT_LOGO_URL = '/images/general/default_shop_masking_icon_v1.png';

const maskingThreadV4: TMaskingThreadV4 = (thread, option) => {
  return {
    ...thread,
    title: option?.title || thread.title,
    twists: thread.twists.map((twist) => {
      if (twist.key === 'title' && option?.title) {
        return { ...twist, value: option?.title };
      }

      if (twist.key === 'shop_id') {
        return {
          ...twist,
          value: 'Demo Shop',
          extraData: {
            ...twist.extraData,
            title: 'Demo Shop',
          },
        };
      }

      if (twist.key === 'logo') {
        if (thread.threadType === 'shop') {
          return {
            ...twist,
            value: DEFAULT_LOGO_URL,
          };
        }

        // The value below use to use `faker-js`, if complain occurs, demand for mock logos
        return { ...twist, value: DEFAULT_LOGO_URL };
      }

      return twist;
    }),
  };
};

const maskSensitiveData = (
  operation: Operation,
  response: FetchResult<
    Record<string, any>,
    Record<string, any>,
    Record<string, any>
  >
) => {
  const { operationName, variables } = operation;

  if (operationName === 'shopSelector') {
    const allShops = response.data?.shopSelector.allShops.map((shopData) =>
      maskingThreadV4(shopData, { title: 'Demo Shop' })
    );

    const groups = response.data?.shopSelector.groups.map((groupData) => ({
      ...groupData,
      title: 'Demo Group',
      logo: DEFAULT_LOGO_URL,
      shops: groupData.shops.map((shopData) =>
        maskingThreadV4(shopData, { title: 'Demo Shop' })
      ),
    }));

    return { data: { shopSelector: { allShops, groups } } };
  }

  if (operationName === 'getHeartbeatTopShops') {
    const heartbeatChartTopShops = response.data?.heartbeatChartTopShopsV2;

    const transformedResponse = {
      data: {
        heartbeatChartTopShopsV2: {
          ...heartbeatChartTopShops,
          data: heartbeatChartTopShops.data.map((data) => ({
            ...data,
            logo: DEFAULT_LOGO_URL,
            name: 'Demo Shop',
          })),
        },
      },
    };

    return transformedResponse;
  }

  if (operationName === 'getShopsByThread') {
    const shopsByThread = response.data?.shopsByThread;

    const transformedResponse = {
      data: {
        shopsByThread: {
          ...shopsByThread,
          data: shopsByThread.data.map((data) => ({
            ...data,
            logo: DEFAULT_LOGO_URL,
            name: 'Demo Shop',
          })),
        },
      },
    };

    return transformedResponse;
  }

  if (operationName === 'getThread') {
    const thread = response.data?.threadV4;

    if (thread?.threadType !== 'staff') {
      return response;
    }

    return {
      data: { threadV4: maskingThreadV4(thread, { title: 'xxxx xxxx' }) },
    };
  }

  if (operationName === 'getSimpleThread') {
    const thread = response.data?.threadV4;

    if (thread?.threadType !== 'staff') {
      return response;
    }

    return { data: { threadV4: { ...thread, title: 'xxxx xxxx' } } };
  }

  const filters =
    typeof variables?.filters === 'string'
      ? JSON.parse(variables?.filters)
      : variables?.filters;
  const isStaff = filters?.thread_type === 'staff';

  if (operationName === 'getPaginatedThreads') {
    const thread = response.data?.threadPaginationV6;

    const transformedResponse = {
      data: {
        threadPaginationV6: {
          ...thread,
          title: isStaff ? 'xxxx xxxx' : thread.title,
          data: thread.data.map((data) =>
            maskingThreadV4(data, { title: isStaff ? 'xxxx xxxx' : undefined })
          ),
        },
      },
    };

    return transformedResponse;
  }

  return response;
};

export { maskSensitiveData };
