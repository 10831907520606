import dynamic, { DynamicOptions } from 'next/dynamic';
import { LazyLoader } from './lazy-loader';

type LoadableProps<P> = DynamicOptions<P> & {
  chunkName: string;
  fallbackComponent?: React.ReactNode;
  delay?: number;
};

type ComponentModule<P = object> = {
  default: React.ComponentType<P>;
};
type LoaderComponent<P = object> = Promise<
  React.ComponentType<P> | ComponentModule<P>
>;
type Loader<P = object> = (() => LoaderComponent<P>) | LoaderComponent<P>;

/**
 * @example
 * const MyLazyComponent = loadable(() => import('./lib/my-lazy-component'), {
 *  chunkName: 'lazy-component',
 *  fallbackComponent: <AppletSplashScreen />,
 * })
 */
export function loadable<P>(
  importFunc: Loader<P>,
  { fallbackComponent, delay = 1500, ...DynamicOptions }: LoadableProps<P>
) {
  const LazyComponent = dynamic(importFunc, {
    loading: () => <LazyLoader delay={delay} component={fallbackComponent} />,
    ...DynamicOptions,
  });

  return LazyComponent;
}
