import { ROUTE_PATHS, SUPPORT_EMAIL } from '@tapestry/shared/constants';
import Link from 'next/link';
import { FC } from 'react';

export const CodeScannerThreadError: FC = () => {
  return (
    <div>
      <h1 className="font bold text-lg">
        Something went wrong searching for your product.
      </h1>
      <p>
        <a href={`mailto:${SUPPORT_EMAIL}`} className="text-primary underline">
          Support
        </a>{' '}
        has been contacted. In the meantime, You could try to reload the page
        and try again.
      </p>
      <p>
        Alternatively, you could search for it on the{' '}
        <Link href={ROUTE_PATHS.search} className="text-primary underline">
          Search Page
        </Link>
      </p>
    </div>
  );
};
