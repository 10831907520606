import { IsoString } from '../../utils-type/iso-string';
import { Nullable } from '../../utils-type/nullable';

export enum Twist {
  Quantity = 'qty',
  Logo = 'logo',
  bgImage = 'bg_image',
  OnboardingImage = 'images.onboarded',
  LastSyncedAt = 'last_synced_at',
  Address = 'address_id',
  Shop = 'shop_id',
  Category = 'category_id',
  Space = 'space_id',
  Supplier = 'supplier_id',
  Title = 'title',
  DueDate = 'due_date',
  Assignable = 'assignable_id',
  DepartmentId = 'department_id',
  Product = 'product_id',
  List = 'list_id', // ! 12 Aug 2022, currrently not a valid twist I believe. Unsupported I should say
  CompletedTask = 'completed_task',
  MoreInformation = 'more_information',
  EstimateDuration = 'estimate_duration',
  Attachments = 'attachments',
  AttachLink = 'attach_link',
  SubTasks = 'sub_tasks',
  Timezone = 'timezone',
  Tags = 'tags',
  SKU = 'sku',
  UnitOfMeasure = 'unit_of_measure',
  UnitSize = 'unit_size',
  UnitPrice = 'unit_price',
  UnitPriceCost = 'unit_price_cost',
  Taxable = 'taxable',
  StockOnHand = 'StockOnHand',
  CurrentlyStocked = 'Active',
  PLU = 'plu', // ! currently not supported by API
  Shops = 'shops',
  Departments = 'departments',
  Categories = 'categories',
  Spaces = 'spaces',
  AssingedTo = 'assigned_to',
  Products = 'products',
}

export enum TwistType {
  ThreadHeader = 'thread_header',
  Datetime = 'datetime',
  Thread = 'thread',
  Hidden = 'hidden',
  Text = 'text',
  Interval = 'interval',
  TaskList = 'task_list',
  Link = 'link',
  Files = 'files',
  TagCollection = 'tag_collection',
  ThreadCollection = 'thread_collection',
  UserCollection = 'user_collection',
}

export type ThreadTwistsFormValues = {
  [Twist.Quantity]: number;
  [Twist.Logo]: Nullable<string>;
  [Twist.bgImage]: Nullable<string>;
  [Twist.OnboardingImage]: Nullable<string>;
  [Twist.LastSyncedAt]: Nullable<IsoString>;
  [Twist.Address]: Nullable<string>; // TODO verify
  [Twist.Shops]: Nullable<string>;
  [Twist.Title]: Nullable<string>;
  [Twist.DueDate]: Nullable<IsoString>;
  [Twist.Assignable]: Nullable<string>;
  [Twist.DepartmentId]: Nullable<string>;
  [Twist.CompletedTask]: Nullable<boolean>;
  [Twist.MoreInformation]: Nullable<string>;
  [Twist.EstimateDuration]: Nullable<IsoString>;
  [Twist.Attachments]: Nullable<string>; // TODO verify => prob array
  [Twist.AttachLink]: Nullable<string>; // TODO verify => prob array
  [Twist.SubTasks]: Nullable<string>; // TODO verify => prob array
  [Twist.Timezone]: Nullable<string>;
  [Twist.Departments]: Nullable<Array<string>>;
  [Twist.Categories]: Nullable<Array<string>>;
  [Twist.Spaces]: Nullable<Array<string>>;
  [Twist.AssingedTo]: Nullable<Array<string>>;
  [Twist.Product]: Nullable<Array<string>>;
};
