import { useRef, useCallback } from 'react';

/**
 * a double click implementation when the browser onDblClick event is not available or exposed
 *
 * @example
 * const MyComponent = () => {
 *  const handleDoubleClick = (args) => {
 *    doSomething(args)
 *  }
 *  const handleClick = useDoubleClick(handleDoubleClick)
 *
 *  return (
 *    <LibButton onClick={handleClick}>Double click me</LibButton>
 *  )
 * }
 */
export const useDoubleClick = (cb: (...args) => void) => {
  if (!cb) {
    throw new Error('useDoubleClick(): a callback function is required');
  }

  const clickCounter = useRef(0);

  const handleClick = useCallback(
    (...args: any) => {
      let timeID: ReturnType<typeof setTimeout>;

      const reset = () => {
        clickCounter.current = 0;
        clearTimeout(timeID);
      };

      if (clickCounter.current >= 1) {
        cb(...args);
        reset();
      } else {
        clickCounter.current = clickCounter.current + 1;
        timeID = setTimeout(() => {
          reset();
        }, 1000);
      }
    },
    [cb]
  );

  return handleClick;
};

export default useDoubleClick;
