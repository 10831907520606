/**
 * Persistant demo shops and groups
 */
export const DEMO_SHOPS_IDS = [
  // groupId
  '5b5a25e5-63c1-472e-ae47-4b290cbb09b0',
  // shopId
  '795b98d4-88d6-4cf4-9404-af033e021242',
  '78f5f215-a730-4f6a-a9b9-8cda97e3fcbd',
  'c1c961ec-d0c9-4e0a-9e47-2c4f1a013e2e',
  'bc6b0d46-a1e7-4b38-9e78-2ce0bdf9f077',
  '63625da8-de64-4e36-96b4-ed35e85a4207',
  'ccb24ded-a2a4-4adf-b974-dd5e62efb374',
  'bcc14d5a-2660-4c2d-8bfb-32b0d62f98cf',
  '7febfa4b-5d8c-4347-819a-a9a4cafe2608',
  '51c531b0-7cee-4538-b27d-9197b476f012',
  '69cbd7f7-12a5-4359-b05c-a45720e71f0f',
  '2b9f4605-fcf4-4bf7-8690-9c755da0525d',
  '86c237f9-70cf-42c1-96e8-b207c92b2f3f',
  '36c34d72-3869-451c-b6e0-4a94f9e16c82',
  'dffade71-aa57-4521-a427-22009b11c4ad',
  '25974a09-65ea-4816-876d-67ffa730de8c',
  '7db07067-994a-43cb-bd9b-2de8874d863a',
];
