import React, { FC } from 'react';
import { MobileMenuContextProvider } from './DropdownMobileContext';
import {
  DesktopMenuContextProvider,
  useDesktopMenuContext,
} from './DropdownDesktopContext';

/**
 * Provides a div wrapper to use `relative` onto
 */
const DropdownMenuRoot: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { rootRef } = useDesktopMenuContext();

  /* * This might have to be default to div width (w-full) but currently running into a problem with refs */
  return (
    <div ref={rootRef} className="relative w-max">
      {children}
    </div>
  );
};

export const DropdownMenuComponent: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <MobileMenuContextProvider>
      <DesktopMenuContextProvider>
        <DropdownMenuRoot>{children}</DropdownMenuRoot>
      </DesktopMenuContextProvider>
    </MobileMenuContextProvider>
  );
};
