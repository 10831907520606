import { FC } from 'react';
import { Twist } from '@tapestry/types';
import { getTwistByKey, trackEvent } from '@tapestry/shared/utils';
import { IThreadV4Type } from '@tapestry/shared/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Logo } from '@tapestry/weave';

interface IStoreSelectorDropdownShopListItemProps {
  shop: IThreadV4Type;
  closeDropdownMenu: () => void;
  isShowShopSidebar: boolean;
}

interface IDropdownShopsListProps {
  shopsList: Maybe<IThreadV4Type[]>;
  closeDropdownMenu: () => void;
  isShowShopSidebar: boolean;
}

const StoreSelectorDropdownShopListItem: FC<
  IStoreSelectorDropdownShopListItemProps
> = ({ shop, closeDropdownMenu, isShowShopSidebar }) => {
  const shopLogo = getTwistByKey(Twist.Logo, shop)?.value;
  const {
    pathname,
    query: { groupId: _, ...queryWithoutGroupId },
  } = useRouter();

  const handleAction = () => {
    trackEvent({
      event: 'ShopSelection',
      category: 'shop_selection',
      action: 'shop_clicked',
      label: shop?.title || undefined,
    });

    closeDropdownMenu();
  };

  return (
    <Link
      key={shop?.id}
      href={{
        pathname,
        query: {
          ...queryWithoutGroupId,
          shopId: shop?.id,
        },
      }}
      onClick={handleAction}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleAction();
        }
      }}
      tabIndex={isShowShopSidebar ? 0 : -1}
      className="hover:bg-primary-lightest focus:bg-primary-lightest flex cursor-pointer flex-row items-center rounded-md p-2 text-base font-medium transition-colors duration-150 ease-in-out focus:outline-none sm:border-b-0 sm:font-normal"
    >
      <span className="mr-3 flex h-6 w-6 min-w-6 items-center justify-center overflow-hidden rounded-full bg-gray-200 sm:mr-4">
        {shopLogo ? <Logo src={shopLogo} alt="shop's logo" size="sm" /> : null}
      </span>

      <span title={shop?.title || ''} className="truncate">
        {shop?.title || ''}
      </span>
    </Link>
  );
};

export const DropdownShopsList: FC<IDropdownShopsListProps> = ({
  shopsList,
  closeDropdownMenu,
  isShowShopSidebar,
}) => {
  return shopsList ? (
    <ul>
      {shopsList?.map((shop, idx) => (
        <StoreSelectorDropdownShopListItem
          key={shop?.id || idx}
          shop={shop}
          closeDropdownMenu={closeDropdownMenu}
          isShowShopSidebar={isShowShopSidebar}
        />
      ))}
    </ul>
  ) : null;
};
