import type { FC } from 'react';
import {
  FormInputBase,
  IFormInputBaseProps,
} from '../FormInputBase/FormInputBase';
import DatePicker from '../DatePickers/DatePicker/DatePicker';
import { IDatePickerProps } from '../DatePickers/DatePicker/date-picker-utils';
import { DateString } from '../Calendar';

type FakePartialEvent = {
  type: string;
  target?: { name: string; value: unknown };
};

interface FormDatePickProps
  extends IFormInputBaseProps,
    Pick<
      IDatePickerProps,
      'value' | 'onChange' | 'minDate' | 'maxDate' | 'onBlur' | 'clearable'
    > {
  disabled?: boolean;
  onDateSelection?: (e: FakePartialEvent) => void;
}

export const FormDatePicker: FC<FormDatePickProps> = (props) => {
  const {
    value,
    onChange,
    minDate,
    maxDate,
    disabled,
    onBlur,
    onDateSelection,
    name,
    clearable,
  } = props;

  const handleFormDateSelection = (date: DateString | null) => {
    const fakeEvent = {
      type: 'onChange',
      target: {
        name,
        value: date,
      },
    };

    onDateSelection?.(fakeEvent);
  };

  return (
    <FormInputBase {...props}>
      <DatePicker
        value={value}
        name={name}
        onChange={onChange}
        onDateSelection={handleFormDateSelection}
        onBlur={onBlur}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        clearable={clearable}
      />
    </FormInputBase>
  );
};
