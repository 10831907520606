import React, { useRef, FC, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { SearchIcon } from '@tapestry/shared/icons';
import { useRouter } from 'next/router';
import { ROUTE_PATHS, SHORTCUT } from '@tapestry/shared/constants';
import { useLocationContext } from '@tapestry/shared/client';
import { LocationPathname } from '@tapestry/types';
import { useKeyboardShortcut } from '@tapestry/shared/hooks';
import { mapAppletRoutesToThreadType } from '@tapestry/shared/utils';
import {
  getSearchPlaceholder,
  LETTERS_AND_NUMBERS_KEYS,
} from './NavbarSearchUtils';
import { useDebouncedCallback } from 'use-debounce';
import { useAppMediaQuery } from '@tapestry/weave';

// * specifies a list of routes where the search input is used on the page and hence the user does not need to be redirected to the search page
const IN_APPLET_SEARCH = [
  ROUTE_PATHS.search,
  ROUTE_PATHS.deals,
  ROUTE_PATHS.tasks,
  ROUTE_PATHS.lists,
];

interface INavbarSearchInputProps {
  placeholder?: string;
}

export const NavbarSearchInput: FC<
  React.PropsWithChildren<INavbarSearchInputProps>
> = ({ placeholder = 'Search...' }) => {
  /**
   * Hooks
   */
  const { pathname, push, query } = useRouter();
  const onSearchPage = pathname === ROUTE_PATHS.search;
  const appMediaQueries = useAppMediaQuery();
  const NavbarSearchInputRef = useRef<HTMLInputElement>(null);
  const [{ lastLocationBeforeSearch }, dispatchToLocationContext] =
    useLocationContext();

  /**
   * Functions
   */
  const currentAppletRelatedThreadObj = mapAppletRoutesToThreadType(
    pathname as LocationPathname
  );

  const dynamicInputPlaceholder = getSearchPlaceholder(
    currentAppletRelatedThreadObj?.label,
    currentAppletRelatedThreadObj?.placeholder,
    appMediaQueries
  );

  const handleUpdateAndPossibleRedirect = (inputVal: string) => {
    const currentAppletPath = IN_APPLET_SEARCH.find((path) =>
      pathname.startsWith(path)
    );

    if (currentAppletPath) {
      return push({
        pathname: currentAppletPath,
        query: { ...query, searchTerm: inputVal },
      });
    }

    return push({
      pathname: ROUTE_PATHS.search,
      query: {
        ...query,
        searchTerm: inputVal,
        activeThreadType: currentAppletRelatedThreadObj?.type,
      },
    });
  };

  const { callback: debounceUpdateURL } = useDebouncedCallback(
    function updateURL(inputVal: string) {
      const hasClearedInputOnSearchPage = onSearchPage && !inputVal;

      if (hasClearedInputOnSearchPage && lastLocationBeforeSearch) {
        push(lastLocationBeforeSearch);
      } else {
        handleUpdateAndPossibleRedirect(inputVal);
      }
    },
    300
  );

  const handleOnChange = (e) => {
    debounceUpdateURL(e.currentTarget.value);
  };

  const handleOnFocusRecordLastLocationBeforeSearchToGoBackTo = () => {
    if (onSearchPage) return;

    dispatchToLocationContext({
      type: 'UPDATE_LAST_LOCATION_BEFORE_SEARCH',
      payload: pathname,
    });
  };

  /**
   * useEffect
   */
  useKeyboardShortcut({
    [SHORTCUT.APP.FOCUS_SEARCH]: function focusSearchInput() {
      if (NavbarSearchInputRef?.current) {
        NavbarSearchInputRef.current.focus();
      }
    },
  });

  useEffect(() => {
    const handleFocusNavbarInput = (e: globalThis.KeyboardEvent) => {
      if (
        LETTERS_AND_NUMBERS_KEYS.includes(e.key) &&
        NavbarSearchInputRef?.current
      ) {
        NavbarSearchInputRef.current.focus();
      }
    };

    if (pathname === ROUTE_PATHS.menu) {
      document.addEventListener('keydown', handleFocusNavbarInput);
    }

    return () => {
      document.removeEventListener('keydown', handleFocusNavbarInput);
    };
  }, [pathname]);

  return (
    <div className="bg-primary-lightest relative rounded-full md:min-w-72">
      <label htmlFor="search-input" className="sr-only">
        search input
      </label>

      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <SearchIcon
          className="text-primary-light h-6 w-6"
          fillColor="currentColor"
        />
      </div>

      <input
        id="search-input"
        type="search"
        ref={NavbarSearchInputRef}
        placeholder={
          currentAppletRelatedThreadObj ? dynamicInputPlaceholder : placeholder
        }
        defaultValue={query.searchTerm ?? ''}
        onChange={handleOnChange}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            handleOnChange(e);
          }
        }}
        onFocus={handleOnFocusRecordLastLocationBeforeSearchToGoBackTo}
        className={twMerge(
          `placeholder-gray-text font-base  block w-full rounded-full bg-transparent py-2 pl-10 pr-4 text-xs leading-5 text-black transition duration-150 ease-in-out placeholder:text-xs focus:outline-none disabled:cursor-not-allowed sm:text-sm`
        )}
      />

      {!NavbarSearchInputRef.current?.value && (
        <span className="border-primary-light text-primary-light inset-y-0 right-0 my-1 mr-4 hidden items-center rounded-md border p-2 text-sm sm:mr-5 md:absolute md:flex ">
          <span className="sr-only">Press </span>
          <kbd className="font-sans">
            <abbr title="Command" className="no-underline">
              ⌘
            </abbr>
          </kbd>
          <span className="sr-only"> and </span>
          <kbd className="ml-1 font-sans">K</kbd>
          <span className="sr-only"> to search</span>
        </span>
      )}
    </div>
  );
};

export default NavbarSearchInput;
