import { IThreadV4Type } from '@tapestry/shared/graphql';
import { IThreadExtendedType, Twist } from '@tapestry/types';

export const getTwistByKey = (
  twistKey: Twist,
  thread: IThreadV4Type | IThreadExtendedType | null | undefined
) => {
  if (!thread) return null;

  const twist = thread?.twists?.find((twist) => twist.key === twistKey) || null;

  return twist;
};

export const getTwistID = (
  twistKey: Twist,
  thread: IThreadV4Type | null | undefined
) => {
  if (!thread) return null;

  const twist = thread?.twists?.find((twist) => twist.key === twistKey) || null;

  return twist?.id || null;
};

export const getTwistById = (
  twistId: string,
  thread: IThreadV4Type | null | undefined
) => {
  if (!thread) return null;

  const twist = thread?.twists?.find((twist) => twist.id === twistId) || null;

  return twist;
};
