import React, { FC, ReactNode } from 'react';

import { Checkbox as AriaCheckbox, CheckboxProps } from 'react-aria-components';

export interface ICheckboxProps extends CheckboxProps {
  render?: () => ReactNode;
}

/**
 * A Tapestry checkbox component
 */
export const Checkbox: FC<React.PropsWithChildren<ICheckboxProps>> = ({
  children,
  render,
  ...props
}) => {
  return (
    <AriaCheckbox
      {...props}
      className="group flex items-center text-base text-black forced-color-adjust-none"
    >
      {render ? (
        render
      ) : (
        <>
          {/* checkbox */}
          <div
            className="ring-primary group-data-[selected=true]:bg-primary group-data-[selected=true]:border-primary group-data-[indeterminate=true]:bg-primary group-data-[indeterminate=true]:border-primary group-data-[focus-visible]:border-primary ml-1 mr-2 flex size-4 items-center justify-center rounded-sm border-2 transition-all duration-200 group-data-[pressed=true]:ring-2"
            aria-hidden="true"
          >
            <svg
              viewBox="0 0 18 18"
              className="size-3 fill-none stroke-white stroke-[3px]"
            >
              <polyline points="1 9 7 14 15 4" />
            </svg>
          </div>
          {children}
        </>
      )}
    </AriaCheckbox>
  );
};

export default Checkbox;
