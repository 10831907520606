import { Env } from '@tapestry/types';
import { hasPermissions } from '@tapestry/shared/utils';
import React, {
  Dispatch,
  useContext,
  createContext,
  useReducer,
  ReactNode,
  FC,
  useEffect,
} from 'react';

type LocationContextState = {
  lastLocation: null | `/${string}` | string;
  lastLocationBeforeSearch: null | `/${string}` | string;
};

type LocationContextAction = {
  type: 'UPDATE_LAST_LOCATION' | 'UPDATE_LAST_LOCATION_BEFORE_SEARCH';
  payload: `/${string}` | string;
};

type LocationContextValue = [
  LocationContextState,
  Dispatch<LocationContextAction>
];

const initialState: LocationContextState = {
  lastLocation: null,
  lastLocationBeforeSearch: null,
};

const locationReducer = (
  state: LocationContextState,
  { type, payload }: LocationContextAction
) => {
  switch (type) {
    case 'UPDATE_LAST_LOCATION':
      return { ...state, lastLocation: payload };

    case 'UPDATE_LAST_LOCATION_BEFORE_SEARCH':
      return { ...state, lastLocationBeforeSearch: payload };

    default:
      return state;
  }
};

const LocationContext = createContext<LocationContextValue | undefined>(
  undefined
);

export const LocationContextProvider: FC<
  React.PropsWithChildren<{ children: ReactNode }>
> = ({ children }) => {
  const [state, dispatch] = useReducer(locationReducer, initialState);
  const value: LocationContextValue = [state, dispatch];

  useEffect(() => {
    if (hasPermissions([Env.Local])) {
      console.log('Location context state', state);
    }
  }, [state]);

  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationContext = (): LocationContextValue => {
  const context = useContext(LocationContext);

  if (context === undefined) {
    throw new Error(
      'useLocationContext must be used inside LocationContextProvider'
    );
  }

  return context;
};
