import React from 'react';
import { twMerge } from 'tailwind-merge';
import { TailwindBorderColorString } from '@tapestry/types';

interface IDivider {
  borderColor?: TailwindBorderColorString;
  className?: string;
  direction?: 'horizontal' | 'vertical';
}

/**
 * Divider
 *
 * A simple line divider
 *
 * @param borderColor - a Tailwind `border-color` class name
 */
export const Divider = ({
  borderColor,
  direction = 'horizontal',
  className = '',
}: IDivider) => {
  const directionBasedClassName =
    direction === 'vertical' ? 'w-px self-stretch border-l' : 'w-full border-b';

  return (
    <div
      role="separator"
      className={twMerge(
        directionBasedClassName,
        'rounded-sm',
        borderColor || 'border-gray-300',
        className
      )}
    />
  );
};
