import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FC, useMemo } from 'react';
import { DemoModeBanner } from './banners/DemoModeBanner';
import { ShopOfflineBanner } from './banners/ShopOfflineBanner';
import { UserNotRegisteredBanner } from './banners/UserNotRegisteredBanner';
import { useUIContext } from '@tapestry/shared/client';

/**
 * Manages displaying banners to ensure there's never two showing at the same time
 */
export const BannerManager: FC<{
  isPublicPage?: boolean;
  userIsNotRegistered?: boolean;
}> = ({ isPublicPage = false, userIsNotRegistered = false }) => {
  const {
    demoMode: hasDemoModeFlag,
    shopOfflineBanner: hasShopOfflineBannerFlag,
  } = useFlags();
  const [, dispatch] = useUIContext();

  const bannerComponent = useMemo(() => {
    if (hasDemoModeFlag) {
      dispatch({ type: 'TOGGLE_TOP_BANNERS', payload: true });

      return <DemoModeBanner />;
    }

    if (userIsNotRegistered) {
      dispatch({ type: 'TOGGLE_TOP_BANNERS', payload: true });

      return <UserNotRegisteredBanner />;
    }

    if (hasShopOfflineBannerFlag && !isPublicPage) {
      return <ShopOfflineBanner />;
    }

    dispatch({ type: 'TOGGLE_TOP_BANNERS', payload: false });

    return null;
  }, [
    hasDemoModeFlag,
    hasShopOfflineBannerFlag,
    isPublicPage,
    userIsNotRegistered,
    dispatch,
  ]);

  return bannerComponent;
};
