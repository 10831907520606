import React, { FC } from 'react';
import { getColors, getFont, getSpacing } from '../shared-styles/button-utils';
import { twMerge } from 'tailwind-merge';
import type { IButton } from './Button.types';

/**
 * Main Button component for the app
 *
 *   @param {any} children - use it like a normal html button
 *   @param {string} type - html button type: 'button' | 'submit' | 'reset'
 *   @param {boolean} fullWidth - will set
 *   @param {string} status - control colors for the button. One of 'primary' | 'secondary' | 'danger' | 'basic' | 'basic-inverted'
 *   @param {string} spacing - controls internal padding. 'small' | 'medium' | 'lg'
 *   @param {string} Text - controls text size
 *   @param {function} onClick - will be you for onKeyDown as well
 *   @param {string} className
 *   @param {boolean} disabled
 *   @param {boolean} loading
 *   @param {boolean} lessRound - for lower border radius
 *   @param {string} rounded - control border radius - tailwind class - default "rounded-lg"
 *   @param {string} overwriteColors - do overwrite all preset colors in certain case
 *   @param iconLeft - provide a Functional Component
 *   @param iconRight - provide a Functional Component
 *   @param {string} loadingLabel - a label to present when the loading is true
 *   @return {*}
 */
const Button: FC<React.PropsWithChildren<IButton>> = ({
  type = 'button',
  className,
  disabled = false,
  spacing = 'medium',
  onClick,
  children = null,
  fullWidth,
  loading,
  status = 'basic',
  overwriteColors,
  rounded = 'rounded-lg',
  iconLeft,
  iconRight,
  bold,
  loadingLabel = 'loading...',
}) => {
  const hasAnIcon = !!iconLeft || !!iconRight;
  const _spacing = getSpacing(spacing, hasAnIcon);
  const font = getFont(spacing, bold);
  const _colors = getColors(status);

  return (
    <button
      type={type}
      className={twMerge(
        overwriteColors ? overwriteColors : _colors,
        'disabled:border-button-disabled disabled:bg-button-disabled inline-flex items-center justify-center border-2 transition-colors duration-300 ease-out focus:outline-none disabled:cursor-not-allowed disabled:text-gray-600 group',
        loading && 'cursor-wait',
        rounded,
        fullWidth ? 'w-full' : '',
        font,
        _spacing,
        className
      )}
      disabled={disabled || loading}
      onClick={(e) => onClick && onClick(e)}
    >
      {iconLeft && (
        <span className="mr-3 flex h-6 w-auto items-center justify-center">
          {iconLeft}
        </span>
      )}
      {loading ? loadingLabel : children}
      {iconRight && (
        <span className="ml-3 flex h-6 w-auto items-center justify-center">
          {iconRight}
        </span>
      )}
    </button>
  );
};

export { Button };
