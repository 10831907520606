import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useBoundingClientOnResize } from '../use-element-location-on-resize/UseElementLocationOnResize';

let utilityPortalRoot: HTMLDivElement | null = null;

if (typeof window !== 'undefined') {
  utilityPortalRoot = document.querySelector('#portal');
}

interface IUsePortalConfigProp {
  float?: 'left' | 'right';
}

type IUsePortalReturn<RefType> = {
  Portal;
  ref: React.MutableRefObject<RefType | null>;
  recalculateBoundingBox: () => void;
};

/**
 * hook to render a element outside of it's DOM nesting. useful when some parent might have `overflow: hidden` or things as such
 * @return {
 *  ref,
 *  Portal
 * }
 *
 * @example
 * const { Portal, ref } = usePortal();
 * return (
 *  <button ref={ref}>click me</button>
 *  <Portal>
 *     <div>My thing to render</div>
 *  </Portal>
 * )
 */
export function usePortal<RefType = HTMLElement>(
  config?: IUsePortalConfigProp
): IUsePortalReturn<RefType> {
  const baseConfig = { float: 'left', ...config };

  const baseDivRef = useRef(
    typeof window !== 'undefined' ? document.createElement('div') : null
  );
  const elemToRenderNearRef = useRef<RefType | null>(null);

  const [boundingBox, recalculateBoundingBox] =
    useBoundingClientOnResize(elemToRenderNearRef);
  const [portalCoordinates, setPortalCoordinates] = useState({
    top: 0,
    left: 0,
  });

  const Portal = ({ children }) =>
    ReactDOM.createPortal(
      <div
        className="fixed"
        style={{
          zIndex: 9999,
          top: portalCoordinates.top,
          left: portalCoordinates.left,
        }}
      >
        {children || 'This is the portal created by `usePortal` hook'}
      </div>,
      baseDivRef.current as Element
    );

  useEffect(() => {
    if (!elemToRenderNearRef || !elemToRenderNearRef?.current) return;

    const calculateSiblingNodeLocation = () => {
      if (!boundingBox) return;

      const { height, x, y, width } = boundingBox;
      const left = baseConfig.float === 'right' ? x + width : x;

      setPortalCoordinates({ left, top: y + height });
    };

    calculateSiblingNodeLocation();
  }, [elemToRenderNearRef, boundingBox, baseConfig.float]);

  useEffect(() => {
    if (!utilityPortalRoot) return;

    const currentRef = baseDivRef.current as Node;

    utilityPortalRoot.appendChild(currentRef);

    return () => {
      utilityPortalRoot && utilityPortalRoot.removeChild(currentRef);
    };
  }, []);

  return { Portal, ref: elemToRenderNearRef, recalculateBoundingBox };
}
