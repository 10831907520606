import { useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { Maybe } from 'graphql/jsutils/Maybe';

/**
 * manages auth tokens
 *
 * if the URL contains a token, it will be set and override the localStorage
 */
export const useAuthToken = (): Maybe<string> => {
  // old version
  const [oldTokenQueryParam, setOldTokenParam] = useQueryParam(
    'token',
    StringParam
  );
  // New version
  const [tokenQueryParam, setTokenParam] = useQueryParam('utk', StringParam);
  let accessToken: string | null = null;

  if (typeof window !== 'undefined') {
    accessToken = localStorage.getItem('accessToken');
  }

  useEffect(
    function passURLTokenToLocalStorage() {
      const qpToken = tokenQueryParam || oldTokenQueryParam;

      if (qpToken) {
        localStorage.setItem('accessToken', qpToken);

        // * Remove presence in query param
        setTokenParam(undefined);
        setOldTokenParam(undefined);
      }
    },
    [tokenQueryParam, oldTokenQueryParam, setTokenParam, setOldTokenParam]
  );

  return tokenQueryParam || oldTokenQueryParam || accessToken;
};
