import React, { FC } from 'react';
import { useActiveShopScopeParams } from '@tapestry/shared/hooks';
import { useShopSelector } from '@tapestry/shared/graphql';
import { StoreOfflineBanner } from './StoreOfflineBanner';
import { GroupStoreOfflineBanner } from './GroupStoreOfflineBanner';
import { ErrorBoundary } from '@tapestry/shared/client';
import { useRouter } from 'next/router';
import { ROUTE_PATHS } from '@tapestry/shared/constants';

/**
 * a banner showing up if the shop has been offline fore more than a given time
 */
export const ShopOfflineBanner: FC = () => {
  const activeScope = useActiveShopScopeParams();
  const { data } = useShopSelector({ fetchPolicy: 'cache-only' });
  const { pathname } = useRouter();
  const isHome = pathname === ROUTE_PATHS.homepage;

  if (isHome) return null;

  if (activeScope.shopId && data) {
    return (
      <ErrorBoundary>
        <StoreOfflineBanner query={data} shopId={activeScope.shopId} />
      </ErrorBoundary>
    );
  }

  if (activeScope.groupId && data) {
    return (
      <ErrorBoundary>
        <GroupStoreOfflineBanner query={data} groupId={activeScope.groupId} />
      </ErrorBoundary>
    );
  }

  return null;
};
