import { Stack } from '@tapestry/weave';
import { FC } from 'react';

export const UserNotRegisteredBanner: FC = () => {
  return (
    <div className="bg-red px-4 py-2 text-center text-white font-bold">
      <Stack spacing="xsmall">
        <p>
          You are not registered as a user yet.
          <br />
          Sign up using this form to access the platform.
        </p>

        <p>If you think this is a mistake, Contact support</p>
      </Stack>
    </div>
  );
};
