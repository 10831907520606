import { CloseIcon } from '@tapestry/shared/icons';
import { FC, memo } from 'react';
import ReactTimePicker, { TimePickerProps } from 'react-time-picker';
import { twMerge } from 'tailwind-merge';

import './time-picker.css';

type extractedTimePickerProps = Pick<
  TimePickerProps,
  'onBlur' | 'value' | 'disabled' | 'name' | 'id'
>;

export type TimeString = `${string}:${string}`;

export interface ITimePicker extends extractedTimePickerProps {
  clearable?: boolean;
  hasBorders?: boolean;
  onChange: (time: TimeString | null) => void;
}

const TimePicker: FC<ITimePicker> = memo((props) => {
  const { clearable = true, hasBorders = true, onChange } = props;

  const handleOnChange = (time: string | null) => {
    onChange(time as TimeString | null);
  };

  return (
    <ReactTimePicker
      clearIcon={
        clearable ? <CloseIcon light fillColor="currentColor" /> : null
      }
      clockIcon={null} // disable clock panel
      disableClock={true} // disable clock panel
      hourPlaceholder="HH"
      minutePlaceholder="MM"
      maxDetail="minute"
      format="hh:mm a"
      className={twMerge(
        'h-full w-full rounded-md',
        hasBorders && 'border-gray-border border'
      )}
      {...props}
      onChange={handleOnChange}
    />
  );
});

export { TimePicker };
