export enum THREAD_TYPE {
  SHOP = 'shop', // default
  SPACE = 'space',
  DEPARTMENT = 'department',
  CATEGORY = 'category',
  PRODUCT = 'product',
  CUSTOMER = 'customer',
  SUPPLIER = 'supplier',
  COLLECTION = 'collection',
  TAG = 'tag',
  TASK = 'task',
  DEMOGRAPHIC = 'demographic',
  POSTCODE = 'postcode',
  STAFF = 'staff',
  LIST = 'list',
}

export const THREAD_TYPES = Object.values(THREAD_TYPE);
