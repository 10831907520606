import { FC } from 'react';
import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { IShopByThreadShopType, IThreadV4Type } from '@tapestry/shared/graphql';
import { useActiveGroup } from '@tapestry/shared/hooks';
import { DollarIcon, ImageIcon, WarehouseIcon } from '@tapestry/shared/icons';
import { IThreadExtendedType, THREAD_TYPE, Twist } from '@tapestry/types';
import {
  AnalyseButton,
  Currency,
  InfoPill,
  ListItemShell,
  ListItemTitle,
  Modal,
  ModalState,
  Number,
  Stack,
} from '@tapestry/weave';
import { getTwistByKey } from '@tapestry/shared/utils';
import { Maybe } from 'graphql/jsutils/Maybe';
import Link from 'next/link';

const ProductInShopsModalProduct: FC<{
  product: IProductInShopsModalProps['product'];
  activeGroupId: any;
  returnToPath: string;
  qpDateString: string;
  groupTotalQuantity: number;
  groupSellPrice: number;
}> = ({
  product,
  activeGroupId,
  returnToPath,
  qpDateString,
  groupTotalQuantity,
  groupSellPrice,
}) => {
  const sku = getTwistByKey(Twist.SKU, product)?.value ?? null;

  return (
    <div className={`flex overflow-hidden rounded-md bg-gray-100`}>
      <div className="h-auto w-16 flex-shrink-0 md:w-32">
        <div className="flex h-full items-center justify-center bg-gray-200">
          <ImageIcon className="h-8 w-8 md:h-12 md:w-12" fillColor="#ddd" />
        </div>
      </div>
      <div className="flex-grow p-4 text-left sm:flex sm:items-center sm:justify-between sm:pr-6">
        <div className="overflow-x-auto pr-4">
          <h6 className="font-bold md:text-lg truncate-2-lines">
            {product?.title || ''}
          </h6>

          <div className="text-gray-text text-sm">
            {sku ? <p className="mt-2 sm:hidden">SKU: {sku}</p> : null}

            <p className="sm:mt-1">
              product{' '}
              <span className="sm:span hidden">
                {sku ? ` - SKU: ${sku}` : ''}
              </span>
            </p>
          </div>
        </div>

        <div className="mt-2 flex items-center sm:justify-end space-x-4 sm:mt-0">
          {groupSellPrice ? (
            <InfoPill
              icon={DollarIcon}
              title="Current sell price"
              tooltipMessage="Current sell price"
              backgroundColor="bg-gray-200"
            >
              <Currency value={groupSellPrice} maximumFractionDigits={2} />
            </InfoPill>
          ) : null}

          <InfoPill
            icon={WarehouseIcon}
            title="Stock on hand"
            tooltipMessage="Stock on hand"
            backgroundColor="bg-orange-lightest"
          >
            <Number value={groupTotalQuantity} maximumFractionDigits={0} />
          </InfoPill>

          <Link
            title="View this product in Heartbeat"
            href={ROUTE_PATHS.build.heartbeat({
              threadType: THREAD_TYPE.PRODUCT,
              qp: `groupId=${activeGroupId}&productId=${product.id}&${qpDateString}${returnToPath}`,
            })}
            className="hidden sm:block"
          >
            <AnalyseButton />
          </Link>
        </div>

        <Link
          title="View this product in Heartbeat"
          href={ROUTE_PATHS.build.heartbeat({
            threadType: THREAD_TYPE.PRODUCT,
            qp: `groupId=${activeGroupId}&productId=${product.id}&${qpDateString}${returnToPath}`,
          })}
          className="sm:hidden mt-4 block"
        >
          <AnalyseButton />
        </Link>
      </div>
    </div>
  );
};

interface IProductInShopsModalProps {
  modalState: ModalState;
  shops: Maybe<IShopByThreadShopType[]>;
  product: IThreadV4Type | IThreadExtendedType;
  returnToPath: string;
  qpDateString: string;
  groupQuantity: number | null;
  groupSellPrice: number | null;
}

export const ProductInShopsModal: FC<IProductInShopsModalProps> = ({
  modalState,
  shops,
  product,
  returnToPath,
  qpDateString,
  groupQuantity,
  groupSellPrice,
}) => {
  const activeGroup = useActiveGroup();

  return (
    <Modal state={modalState}>
      <Modal.Dialog>
        <Modal.Content>
          <Modal.Title>
            Available in {shops?.length} shops across{' '}
            {activeGroup?.title || 'current group'}
          </Modal.Title>

          <Stack>
            <ProductInShopsModalProduct
              product={product}
              activeGroupId={activeGroup?.id}
              returnToPath={returnToPath}
              qpDateString={qpDateString}
              groupTotalQuantity={groupQuantity || 0}
              groupSellPrice={groupSellPrice || 0}
            />
            <Stack spacing={'xxsmall'}>
              {shops?.map((shop) => (
                <ListItemShell key={shop.id} asDiv noShadow>
                  <div className="flex items-center">
                    <img
                      src={shop.logo || ''}
                      alt="shop's logo"
                      className="min-w-12 hidden h-12 w-12 flex-shrink-0 rounded-full bg-white object-contain object-center sm:block"
                    />
                    <div className="flex w-full items-start justify-between sm:ml-4 sm:block sm:items-center">
                      <ListItemTitle className="sm:text-lg">
                        {shop?.title || ''}
                      </ListItemTitle>
                      <Link
                        title="View this product in Heartbeat"
                        href={ROUTE_PATHS.build.heartbeat({
                          threadType: THREAD_TYPE.PRODUCT,
                          qp: `shopId=${shop.id}&productId=${product.id}&${qpDateString}${returnToPath}`,
                        })}
                        className="sm:hidden"
                      >
                        <AnalyseButton variant="icon" />
                      </Link>
                    </div>
                  </div>
                  <div className="mt-2 flex items-center justify-between space-x-4 sm:mt-0 sm:justify-end">
                    <img
                      src={shop.logo || ''}
                      alt="shop's logo"
                      className="min-w-10 h-10 w-10 flex-shrink-0 rounded-full bg-white object-contain object-center sm:hidden"
                    />
                    <div className="flex items-center justify-end space-x-4 sm:mt-0">
                      {shop?.sellPrice ? (
                        <InfoPill
                          icon={DollarIcon}
                          title="Current sell price"
                          tooltipMessage="Current sell price"
                        >
                          <Currency
                            value={shop?.sellPrice}
                            maximumFractionDigits={2}
                          />
                        </InfoPill>
                      ) : null}
                      {shop.qty !== undefined && shop.qty !== null && (
                        <InfoPill
                          icon={WarehouseIcon}
                          title="Stock on hand"
                          tooltipMessage="Stock on hand"
                          backgroundColor="bg-orange-lightest"
                        >
                          <Number value={shop.qty} maximumFractionDigits={0} />
                        </InfoPill>
                      )}
                    </div>
                    <Link
                      title="View this product in Heartbeat"
                      href={ROUTE_PATHS.build.heartbeat({
                        threadType: THREAD_TYPE.PRODUCT,
                        qp: `shopId=${shop.id}&productId=${product.id}&${qpDateString}${returnToPath}`,
                      })}
                      className="hidden sm:block"
                    >
                      <AnalyseButton />
                    </Link>
                  </div>
                </ListItemShell>
              ))}
            </Stack>
          </Stack>
        </Modal.Content>
      </Modal.Dialog>
    </Modal>
  );
};
