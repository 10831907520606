/**
 * if the value of a key is an object, this will map it to it's id
 */
export const mapObjectFilterValueToids = <T extends object, T2 = T>(
  filters: T | undefined
): T2 | undefined => {
  if (!filters) return undefined;

  const newFilters = {} as T2;

  Object.keys(filters).forEach((key) => {
    if (Array.isArray(filters[key])) {
      newFilters[key] = filters[key].map((entry) => entry.id || entry);
    } else {
      newFilters[key] = filters[key];
    }
  });

  return newFilters;
};
