import { useShopSelector } from '@tapestry/shared/graphql';
import { StringParam, useQueryParam } from 'use-query-params';

/**
 * A utility to get the active shop object from the shopSelector
 */
export const useActiveShop = (id?: string | null) => {
  const [shopId] = useQueryParam('shopId', StringParam);
  const { data } = useShopSelector({ fetchPolicy: 'cache-only' });
  const shopsList = data?.shopSelector?.allShops;

  const matchingId = id || shopId;
  const activeShop = shopsList?.find((shop) => shop?.id === matchingId);

  return activeShop;
};
