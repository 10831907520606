import { useShopSelector } from '@tapestry/shared/graphql';
import { StringParam, useQueryParam } from 'use-query-params';

/**
 * A utility to get the active group object from the shopSelector
 */
export const useActiveGroup = (id?: string) => {
  const [groupId] = useQueryParam('groupId', StringParam);
  const { data } = useShopSelector({ fetchPolicy: 'cache-only' });
  const groupList = data?.shopSelector?.groups;

  const matchingId = id || groupId;
  const group = groupList?.find((group) => group?.id === matchingId);

  return group;
};
