// *******************************************************
// useBoundingClientOnResize
// -------------------------------------------------------
// Component Description
// a hook that returns an element location and height
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import { useEffect, useState, useCallback } from 'react';
import { Nullable } from '@tapestry/types';
import { useDebouncedCallback } from 'use-debounce';

type BoundingBox = {
  x: number;
  y: number;
  width: number;
  height: number;
  top: number;
  right: number;
  bottom: number;
  left: number;
};

// *******************************************
// Main Component
// -------------------------------------------
export const useBoundingClientOnResize = (
  componentRef: any
): [Nullable<BoundingBox>, () => void] => {
  const [boundingClient, setBoundingClient] = useState(null);

  const getAndSetBoundingBox = useCallback(() => {
    if (componentRef && componentRef?.current) {
      setBoundingClient(componentRef.current.getBoundingClientRect());
    }
  }, [componentRef, setBoundingClient]);

  const { callback: debounceGetAndSetBoundingBox } = useDebouncedCallback(
    getAndSetBoundingBox,
    100
  );

  useEffect(() => {
    debounceGetAndSetBoundingBox();

    const handleResize = () => {
      debounceGetAndSetBoundingBox();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [componentRef, debounceGetAndSetBoundingBox]);

  return [boundingClient, getAndSetBoundingBox];
};

export default useBoundingClientOnResize;
