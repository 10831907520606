import { capitaliseString } from '../capitalise-string';

export const getPageTitle = (
  appTitle: string,
  appletName: string,
  ...slugs: string[]
) => {
  const threadInfo = [appTitle, appletName, ...slugs]
    .filter(Boolean)
    .map(capitaliseString)
    .join(' - ');

  return ['tapestry.ai', threadInfo].filter(Boolean).join(' | ');
};
