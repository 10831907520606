// *******************************************************
// ModalActionButton
// -------------------------------------------------------
// A pair of buttons used at the bottom of modals
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React, { ReactElement } from 'react';
// *******************************************
// Component Imports
// -------------------------------------------
import { Button, useAppMediaQuery } from '@tapestry/weave';
import { twMerge } from 'tailwind-merge';

// *******************************************
// Local Interface
// -------------------------------------------
export interface IModalActionButtonProps {
  isLoading: boolean;
  primaryButtonText: string;
  secondaryButtonText?: string;
  onCancel?: VoidFunction | null;
  onPrimaryButtonClick?: () => void;
  primaryButtonStatus?: 'primary' | 'danger';
  /**
   * Pass in an object for more granularity of the disabling state
   */
  disabled?: boolean | { primary?: boolean; secondary?: boolean };
  secondaryButtonAs?: ReactElement;
  justify?: 'center' | 'end' | 'start' | 'between';
  flip?: boolean;
}

const getFlex = (
  justify: IModalActionButtonProps['justify'],
  flip: IModalActionButtonProps['flip']
) => {
  switch (justify) {
    case 'start':
      return flip ? 'flex-row-reverse justify-end' : 'justify-start';

    case 'center':
      return `justify-center ${flip ? 'flex-row-reverse' : ''}`;

    case 'end':
      return flip ? 'justify-end' : 'justify-start flex-row-reverse';

    case 'between':
      return 'justify-between';

    default:
      return 'justify-start flex-row-reverse';
  }
};

const getDisabled = (
  disabled: boolean | { primary?: boolean; secondary?: boolean },
  button: 'primary' | 'secondary'
) => {
  if (Object.prototype.toString.call(disabled) === '[object Object]') {
    return disabled[button] || false;
  }

  return disabled;
};

// *******************************************
// Main Component
// -------------------------------------------
export const ModalActionButton = ({
  isLoading,
  primaryButtonText,
  primaryButtonStatus = 'primary',
  onPrimaryButtonClick,
  secondaryButtonText,
  secondaryButtonAs,
  onCancel = null,
  disabled = false,
  justify = 'end',
  flip = false,
}: IModalActionButtonProps) => {
  const { isPhone } = useAppMediaQuery();
  const flexStyle = getFlex(justify, flip);

  return (
    <div
      className={twMerge(
        '-mb-4 mt-4 w-full space-y-4 border-t px-4 py-4 sm:flex sm:items-center sm:space-x-6 sm:space-y-0',
        flexStyle
      )}
    >
      <Button
        status={primaryButtonStatus}
        type={onPrimaryButtonClick ? 'button' : 'submit'}
        loading={isLoading}
        onClick={onPrimaryButtonClick}
        disabled={getDisabled(disabled, 'primary')}
        fullWidth={isPhone}
        className={flip || justify === 'end' ? 'sm:ml-6' : ''}
        rounded="rounded-full"
      >
        {primaryButtonText || 'Create Task'}
      </Button>

      {secondaryButtonAs ? (
        secondaryButtonAs
      ) : onCancel ? (
        <Button
          status="basic-inverted"
          fullWidth={isPhone}
          onClick={onCancel}
          disabled={getDisabled(disabled, 'secondary')}
          rounded="rounded-full"
        >
          {secondaryButtonText || 'Cancel'}
        </Button>
      ) : null}
    </div>
  );
};

export default ModalActionButton;
