import {
  IUserProfile,
  UpdateUserProfileHookResult,
  useGetProfileLazyQuery,
  useUpdateUserProfile,
} from '@tapestry/shared/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ApolloError } from '@apollo/client';
import uuid from 'react-uuid';
import { useAuthToken, useUIContext } from '@tapestry/shared/client';
import { useEffect, useMemo, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { setUser as setSentryUser } from '@sentry/nextjs';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { LDClient } from 'launchdarkly-js-client-sdk';

// TODO[high]: This is a temporary solution to handle the case where the user's token
// is invalid. We should be able to handle this in the Apollo client for all API request
// not just only when trying to get a user profile, but the BE API is not returning
// correct/consistent error codes across all queries/mutations.
// so it's imposible to handle it that way.

export const clearBrowserStorage = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('userId');
  localStorage.removeItem('token_type');
  localStorage.removeItem('expiresIn');
};

function updateLDContext(
  LDClient: LDClient | undefined,
  profile: Maybe<IUserProfile>
) {
  const initialLDContext = LDClient?.getContext();

  const betaUser = {
    key: 'beta-user',
    name: typeof profile?.beta === 'boolean' ? String(profile?.beta) : 'false',
  };

  const userType = { key: 'user-type', name: profile?.user_type || 'user' };

  LDClient?.identify({
    kind: 'multi',
    ...initialLDContext,
    userType,
    betaUser,
  });
}

/**
 * Hook abstraction of grapql query and mutation for profile
 *
 */
export const useProfile = (): [
  Maybe<IUserProfile>,
  UpdateUserProfileHookResult[0],
  {
    error: ApolloError | undefined;
    loading: boolean;
  }
] => {
  const token = useAuthToken();
  const _token = useMemo(() => token, [token]);
  const LDClient = useLDClient();
  const [, dispatchUI] = useUIContext();

  const hasNeverHadProfile = useRef(true);

  const [
    fetchProfile,
    { data, previousData, loading: loadingGetProfile, error: getProfileError },
  ] = useGetProfileLazyQuery({
    onCompleted: ({ profile }) => {
      // * Set userId in GTM dataLayer so that we can filter internal traffic from analytics
      TagManager.dataLayer({
        dataLayer: { userId: profile?.id },
      });

      // * Set user in sentry for bug reporting
      setSentryUser({
        id: profile?.id || undefined,
        email: profile?.email || undefined,
      });

      // * Sets user in launchDarkly when the profile is retrieved the first time so that we can choose flag variations on `user_type` and `beta` properties
      if (hasNeverHadProfile.current) {
        updateLDContext(LDClient, profile);
        hasNeverHadProfile.current = false;
      }

      // TODO: This is a temporary solution to hide the splash screen
      dispatchUI({
        type: 'HIDE_SPLASH_SCREEN',
      });
    },
    onError: (error: unknown) => {
      if (
        error instanceof ApolloError &&
        error.graphQLErrors[0]?.path?.[0] === 'profile' &&
        typeof window !== 'undefined'
      ) {
        // Only clear storage in case of invalid token,
        // do not clear the apollo cache for a faster load after login
        clearBrowserStorage();
        setSentryUser(null);

        window.location.href = '/login?code=invalid_token';
      }
    },
  });

  useEffect(() => {
    if (_token) {
      fetchProfile();

      // TODO: This is a temporary solution to show the splash screen
      // it should be removed upon the FE rearchitecture
      if (!previousData) {
        dispatchUI({
          type: 'SHOW_SPLASH_SCREEN',
        });
      }
    }
  }, [_token, dispatchUI, fetchProfile, previousData]);

  const profile = data?.profile;

  const [updateAPIProfile, { error: updateError, loading: isLoadingUpdate }] =
    useUpdateUserProfile({
      optimisticResponse: (vars) => {
        return {
          updateUserProfile: {
            ...profile,
            id: profile?.id || uuid(),
            ...vars,
          },
        };
      },
    });

  return [
    profile,
    updateAPIProfile,
    {
      loading: loadingGetProfile || isLoadingUpdate,
      error: getProfileError || updateError,
    },
  ];
};
