import { Transition, TransitionChild } from '@headlessui/react';
import React, { FunctionComponent } from 'react';
import { useOverlay, usePreventScroll, useModal } from '@react-aria/overlays';
import { AriaDialogProps } from 'react-aria';
import { useDialog } from '@react-aria/dialog';
import { getMaxWidth } from '../modal-overlay-utils';
import { twMerge } from 'tailwind-merge';

interface IModalPanelProps extends AriaDialogProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  maxWidth?: 'md' | 'lg' | 'xl';
}

export const ModalOverlay: FunctionComponent<
  React.PropsWithChildren<IModalPanelProps>
> = (props) => {
  const { children, className, isOpen, title, maxWidth = '' } = props;

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const modalRef = React.useRef(null);
  const { overlayProps } = useOverlay(props, modalRef);

  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll();
  const { modalProps } = useModal();

  // Get props for the dialog and its title
  const { dialogProps, titleProps } = useDialog(props, modalRef);

  const _maxWith = getMaxWidth(maxWidth);

  return (
    <Transition as="div" appear show={isOpen || false}>
      <TransitionChild
        as="div"
        enter="transition ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="z-modal fixed inset-0 top-0 flex items-center justify-center overflow-y-scroll p-4"
      >
        {/* The overlay */}
        <div className="fixed inset-0 bg-black opacity-25" />

        <div className="z-10 h-full w-full">
          {/* The panel */}
          <TransitionChild
            enter="transition ease-out duration-300"
            enterFrom="opacity-0 -translate-y-32"
            enterTo="opacity-100 -translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 -translate-y-0"
            leaveTo="opacity-0 -translate-y-32"
            className={twMerge('mx-auto pb-32 md:px-4', _maxWith, className)}
            {...dialogProps}
          >
            <div
              className="w-full"
              {...overlayProps}
              {...modalProps}
              ref={modalRef}
            >
              <h3 {...titleProps} className="sr-only">
                {title || ''}
              </h3>

              {children}
            </div>
          </TransitionChild>
        </div>
      </TransitionChild>
    </Transition>
  );
};

export default ModalOverlay;
