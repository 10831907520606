import * as React from 'react';

interface ILazyLoaderProps {
  delay: number;
  component: React.ReactNode;
}

const LazyLoader: React.FC<React.PropsWithChildren<ILazyLoaderProps>> = ({
  delay,
  component,
}) => {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  if (!show) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{component}</>;
};

export { LazyLoader };
