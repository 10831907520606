import { FC, HTMLProps } from 'react';
import { useDesktopMenuContext } from './DropdownDesktopContext';
import { useMobileMenuContext } from './DropdownMobileContext';

interface MenuItemProps extends HTMLProps<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
}

export const MenuItem: FC<MenuItemProps> = ({
  children,
  className,
  ...buttonProps
}) => {
  const { closeMenu } = useDesktopMenuContext();
  const { close } = useMobileMenuContext();

  const handleAction = (e) => {
    if (buttonProps?.onClick) buttonProps.onClick(e);
    if (buttonProps?.onKeyDown) buttonProps.onKeyDown(e);

    close(e);
    closeMenu();
  };

  return (
    <button
      {...buttonProps}
      type="button"
      className={className}
      onClick={handleAction}
      onKeyDown={handleAction}
    >
      {children}
    </button>
  );
};
