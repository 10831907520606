// The size of the items depends if they have a `subLabel` prop of not.
// Trying with a value that could work for both scenario
// Actual size without sublabel: 36px
// Actual size with sublabel: 54px
export const MENU_ITEM_HEIGHT = 42;

export const getOverlayStyle = (y, height: number) => {
  const overlayStyle = {
    opacity: y.to([0, height], [0.4, 0], 'clamp'),
    display: y.to((value) => (value < height ? 'block' : 'none')),
  };

  return overlayStyle;
};

export type Position = 'top-right' | 'top-center' | 'top-left';

export const getDropdownPosition = (position: Position | undefined) => {
  switch (position) {
    case 'top-right':
      return 'origin-top-right right-0';

    case 'top-left':
      return 'origin-top-left left-0';

    default:
      return 'origin-top-right right-0';
  }
};
