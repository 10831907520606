import * as React from 'react';
import { SlideOverContext } from './slide-over-context';

const useSlideOver = <
  T extends React.ComponentType<React.PropsWithChildren<any>>
>(
  SlidOverComponent: T
) => {
  const { openSlider, closeSlider } = React.useContext(SlideOverContext);

  function handleOpen(
    ...arg: React.ComponentProps<T> extends { [key: string]: any }
      ? [props: React.ComponentProps<T>]
      : []
  ) {
    const componentProps = arg[0] || ({} as React.ComponentProps<T>);
    const element = <SlidOverComponent {...componentProps} />;

    openSlider(element);
  }

  return { open: handleOpen, close: closeSlider };
};

export { useSlideOver };
