import { useState } from 'react';
import { Modal, ModalState, TextInput } from '@tapestry/weave';
import { Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { dasherize } from '@tapestry/shared/utils';
import { Nullable } from '@tapestry/types';
import { useToast } from '@tapestry/shared/client';
import { IThreadV4Type, useDeleteThread } from '@tapestry/shared/graphql';
export interface IDeleteThreadModalProps {
  deleteModalState: ModalState;
  hide: (deleted?: { deleted: boolean }) => void;
  thread: Nullable<IThreadV4Type>;
  onDeleteComplete?: VoidFunction;
}

type FormData = {
  threadName: string;
};

// *******************************************
// Main Component
// -------------------------------------------
export const DeleteThreadModal = ({
  deleteModalState,
  hide,
  onDeleteComplete,
  thread,
}: IDeleteThreadModalProps) => {
  const { addToast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState<
    string | null
  >(null);
  const [handleDeleteThread, { loading }] = useDeleteThread({
    variables: {
      uid: thread?.id || '',
    },
    onCompleted: () => {
      addToast({
        type: 'taskDeleted',
        content: 'Successfully deleted',
      });

      if (onDeleteComplete) {
        onDeleteComplete();
      }

      hide({ deleted: true });
    },
    onError: () => {
      addToast({
        type: 'error',
        content: `Error deleting ${thread?.threadType}`,
      });
      setShouldShowErrorMessage(
        'A Error has occurred whilst trying to delete this task'
      );
    },
    // refetchQueries: ['getThreads'],
  });

  /**
   * Const
   */
  const deletionTextValue = `delete-${dasherize(thread?.title).toLowerCase()}`;

  const onSubmit = ({ threadName }: FormData) => {
    if (threadName === deletionTextValue && thread?.id) {
      setShouldShowErrorMessage(null);
      handleDeleteThread();
    } else {
      setShouldShowErrorMessage(
        'The input value does not match the task title'
      );
    }
  };

  return (
    <Modal state={deleteModalState}>
      <Modal.Dialog>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Content>
            <Modal.Title>Delete {thread?.threadType}</Modal.Title>
            <Modal.Description>
              You are about to delete this {thread?.threadType}. This cannot be
              undone. All related information to the {thread?.threadType} will
              be deleted including historical data related to the{' '}
              {thread?.threadType}.
              <br />
              Confirm by typing{' '}
              <span className="text-red">{deletionTextValue}</span> below
            </Modal.Description>

            <TextInput
              name="threadName"
              register={register}
              errors={errors}
              placeholder={`${deletionTextValue}`}
            />
            {shouldShowErrorMessage && (
              <Transition
                appear
                show
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="text-red -mb-4 mt-2 text-sm"
                as="p"
              >
                {shouldShowErrorMessage || 'sorry, an error has happened'}
              </Transition>
            )}
          </Modal.Content>

          <Modal.Footer>
            <Modal.Button status="danger" type="submit" loading={loading}>
              Delete {thread?.threadType || ''} permanently
            </Modal.Button>

            <Modal.Button onClick={() => hide()} loading={loading}>
              Cancel
            </Modal.Button>
          </Modal.Footer>
        </form>
      </Modal.Dialog>
    </Modal>
  );
};

export default DeleteThreadModal;
