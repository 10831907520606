import { welcomeScreenBanner } from '@tapestry/shared/icons';
import { Annoucement } from '@tapestry/types';

export const RETAIL_ONBOARDING_ANNOUNCEMENT: Pick<
  Annoucement,
  'header' | 'subHeader' | 'buttonLabel' | 'backgroundImage'
> = {
  header: 'Welcome to Retail+',
  subHeader:
    'Retail+ is at the heart of everything happening in store. Providing powerful analytics and other great tools that help make work easier.',
  buttonLabel: 'Take the tour',
  backgroundImage: welcomeScreenBanner as unknown as string,
};
