import {
  SupplyLogoIcon,
  CollectiveLogoIcon,
  ShoppingBagIcon,
  ShopIcon,
  RetailLogoIcon,
  RPlusIcon,
  SPlusIcon,
  CartIcon,
} from '@tapestry/shared/icons';
import { AppName, ITapestryAppConfigurations } from '@tapestry/types';
import { Applet } from './applets';

export const BANNER_HEIGHT = 42;
export const NAVBAR_HEIGHT = 61;

export const TAPESTRY_APP_CONFIGS: {
  [index in AppName]: ITapestryAppConfigurations;
} = {
  retail: {
    domains: {
      development: 'localhost:4200',
      staging: 'staging.retail.tapestry.dev',
      production: 'retail.tapestry.ai',
      'pre-release': 'retail.tapestry.ai',
      test: 'staging.retail.tapestry.dev',
      marketing: 'https://www.tapestry.ai/product/retail',
    },
    defaultApplet: Applet.Home,
    slug: 'retail',
    LDAppKey: 'retail-1', //DO NOT MODIFY
    title: 'Retail+',
    icon: ShopIcon,
    brandIcons: {
      short: RPlusIcon,
      long: RetailLogoIcon,
    },
    color: '#ED7725',
    order: 1,
  },
  supply: {
    domains: {
      development: 'localhost:4201',
      staging: 'staging.supply.tapestry.dev',
      production: 'supply.tapestry.ai',
      'pre-release': 'supply.tapestry.ai',
      test: 'supply.tapestry.ai',
      marketing: 'https://www.tapestry.ai/product/supplier',
    },
    defaultApplet: Applet.Heartbeat,
    slug: 'supply',
    LDAppKey: 'supply-2', //DO NOT MODIFY
    title: 'Supply+',
    icon: CartIcon,
    brandIcons: {
      short: SPlusIcon,
      long: SupplyLogoIcon,
    },
    color: 'rgb(14,115,187)',
    order: 2,
  },
  collectives: {
    domains: {
      development: 'localhost:4202',
      staging: 'collective.tapestry.ai',
      production: 'collective.tapestry.ai',
      'pre-release': 'collective.tapestry.ai',
      test: 'collective.tapestry.ai',
      marketing: 'https://www.tapestry.ai/product/collectives',
    },
    defaultApplet: Applet.Heartbeat,
    title: 'Collectives',
    slug: 'collectives',
    LDAppKey: 'collective-3', //DO NOT MODIFY
    icon: CollectiveLogoIcon,
    brandIcons: {
      short: CollectiveLogoIcon,
      long: CollectiveLogoIcon,
    },
    color: '#C33C8E',
    order: 3,
  },
  market: {
    domains: {
      development: 'localhost:4203',
      staging: 'market.tapestry.ai',
      production: 'market.tapestry.ai',
      'pre-release': 'market.tapestry.ai',
      test: 'market.tapestry.ai',
      marketing: '',
    },
    defaultApplet: Applet.Heartbeat,
    title: 'Market',
    slug: 'market',
    LDAppKey: 'market-4', //DO NOT MODIFY
    icon: ShoppingBagIcon,
    brandIcons: {
      short: ShoppingBagIcon,
      long: ShoppingBagIcon,
    },
    color: '#A9B313',
    order: 3,
    hidden: true,
  },
};
