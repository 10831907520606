import { AppProps } from 'next/app';
import TagManager from 'react-gtm-module';
import Head from 'next/head';
import {
  NextTapestryProviders,
  withTapestryLDProvider,
} from '@tapestry/shared/client';
import '../styles/index.css';
import { MainPrivateLayout } from '@tapestry/shared/components';
import { useEffect } from 'react';
import { invariant } from 'ts-invariant';
import Script from 'next/script';
import { RetailPageProps } from '@tapestry/types';
import { TapestrySplashScreen } from '@tapestry/shared/splash-screen';
import { TAPESTRY_APP_CONFIGS } from '@tapestry/shared/constants';

const splashScreenProps = TAPESTRY_APP_CONFIGS['retail'];

const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      await navigator.serviceWorker.register('/sw.js', {
        scope: '/',
      });
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
};

invariant(
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  'NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID is not define in the environment file'
);
const GTM_ARGS = {
  gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  auth: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_AUTH_RETAIL,
  preview: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_PREVIEW_RETAIL,
};

const isProduction = process.env.NEXT_PUBLIC_APP_ENV === 'production';
const isStaging = process.env.NEXT_PUBLIC_APP_ENV === 'staging';

if (!isProduction) {
  console.table({
    NEXT_PUBLIC_APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
    NODE_ENV: process.env.NODE_ENV,
  });
}

function CustomApp({ Component, pageProps }: AppProps<RetailPageProps>) {
  const { public: publicPage } = pageProps;

  useEffect(() => {
    TagManager.initialize(GTM_ARGS);
    registerServiceWorker();
  }, []);

  return (
    <>
      <Head>
        <title>Retail+</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>

      {isProduction && (
        <>
          {/* Microsoft Clarity */}
          <Script id="ms-clarity" strategy="afterInteractive">
            {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "hq77fv9opl");`}
          </Script>
        </>
      )}

      {isStaging && (
        <Script
          id="bugherd"
          strategy="afterInteractive"
          type="text/javascript"
          src="https://www.bugherd.com/sidebarv2.js?apikey=ffmghtyt2ztfxok6lnnkrw"
        />
      )}

      <NextTapestryProviders {...pageProps}>
        <TapestrySplashScreen {...splashScreenProps} />

        {publicPage ? (
          <Component {...pageProps} />
        ) : (
          <MainPrivateLayout {...pageProps}>
            <Component {...pageProps} />
          </MainPrivateLayout>
        )}
      </NextTapestryProviders>
    </>
  );
}

export default withTapestryLDProvider(CustomApp);
