import { useEffect, useRef } from 'react';
import { useLocationContext } from '@tapestry/shared/client';
import { useRouter } from 'next/router';

/**
 * Saves current location to `locationContext`
 */
export const useSaveCurrentLocationToContext = (): void => {
  const [, dispatchToLocationContext] = useLocationContext();
  const { pathname } = useRouter();
  const currentLocation = useRef(pathname);

  // * might want to include the `search` param as well in the future
  useEffect(() => {
    dispatchToLocationContext({
      type: 'UPDATE_LAST_LOCATION',
      payload: currentLocation.current,
    });

    currentLocation.current = pathname;
  }, [pathname, dispatchToLocationContext]);
};
