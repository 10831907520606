import { getCurrentAppInfo } from '@tapestry/shared/utils';
import { ProviderConfig, withLDProvider } from 'launchdarkly-react-client-sdk';

// anonymise users
const anonymousUserContext = {
  key: 'anonymous',
  anonymous: true,
};

const userAgent = {
  key: 'user-agent',
  name:
    typeof window !== 'undefined'
      ? window.navigator.userAgent.toLowerCase()
      : 'N/A',
};

// Sets the current app context
const { appInfo } = getCurrentAppInfo();
const appContext = {
  key: appInfo.LDAppKey,
  name: appInfo.slug,
};

/**
 * A Launch Darkly default configuration to be used with our apps
 *
 * @example
 * import { withTapestryLDProvider } from '@tapestry/shared/client';
 *
 * const CustomApp = () => {
 *  return <App/>
 * }
 *
 * export default withTapestryLDProvider(CustomApp);
 */
export const withTapestryLDProvider = (
  app,
  providerConfig?: ProviderConfig
) => {
  return withLDProvider({
    clientSideID: process.env['NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID'] || '',
    context: {
      kind: 'multi',
      user: anonymousUserContext,
      app: appContext,
      userAgent,
    },

    // Stores last known falg value in storage allowing the next start up to bootstrap with those value until it gets the live ones from LD servers
    options: { bootstrap: 'localStorage' },
    ...providerConfig,
  })(app);
};
