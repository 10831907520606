// *******************************************************
// PermissionGate
// -------------------------------------------------------
import { hasPermissions } from '@tapestry/shared/utils';
import { useDevMode } from '@tapestry/shared/hooks';

/**
 * show/hide component(s) given an array of scopes/env
 *
 * @example
 * <PermissionGate scope={[Env.local, Env.staging]}>
 *  <MyComponent />
 * </PermissionGate>
 */
export const PermissionGate = ({ scopes, children }) => {
  /**
   * Hooks
   */
  const [isDevMode] = useDevMode();

  /**
   * Const
   */
  const permissionGranted = hasPermissions(scopes, isDevMode);

  if (!permissionGranted) return null;

  return children;
};
