import { IIconProp } from '@tapestry/shared/icons';

export enum Scopes {
  devMode = 'devMode',
}

export enum Env {
  Local = 'development',
  Staging = 'staging',
  PreRelease = 'pre-release',
  Production = 'production',
  Test = 'test',
}

export type AppName = 'supply' | 'retail' | 'collectives' | 'market';

export interface ITapestryAppConfigurations {
  domains: { [index in Env | 'marketing']: string };
  defaultApplet: string; // Using Applet enum from shared/constants causing circular dependency
  title: string;
  slug: AppName;
  LDAppKey: string; //DO NOT MODIFY - Launch Darkly App Key - Certain permissions are based on this key
  icon: React.FC<IIconProp>;
  brandIcons: {
    short: React.FC<IIconProp>;
    long: React.FC<IIconProp>;
  };
  color: string;
  order: number;
  hidden?: boolean;
}
