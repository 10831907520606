// *******************************************************
// Inline
// -------------------------------------------------------
// Component Description
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import { ResponsiveBreakpoints } from '@tapestry/types';
import { resolveResponsiveProps } from '@tapestry/shared/utils';
import React, { FC } from 'react';
// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Constant Imports
// -------------------------------------------

// *******************************************
// Hook Imports
// -------------------------------------------

// *******************************************
// Interface Imports
// -------------------------------------------

// *******************************************
// Local Interface
// -------------------------------------------
interface IInlineProps {
  spacing?: ResponsiveBreakpoints | ResponsiveBreakpoints[];
  collapseBelow?: Exclude<ResponsiveBreakpoints, 'sm'>;
  maxWidth?: ResponsiveBreakpoints;
}

// *******************************************
// Action / Utils / Functions Imports
// -------------------------------------------

const getCollapseBelowBreakpoint = (collapseBelow: ResponsiveBreakpoints) => {
  switch (collapseBelow) {
    case 'md':
      return 'block md:flex md:flex-row md:flex-wrap';
    case 'lg':
      return 'block lg:flex lg:flex-row lg:flex-wrap';
    case 'xl':
      return 'block xl:flex xl:flex-row xl:flex-wrap';

    default:
      return 'flex flex-row flex-wrap';
  }
};

const getSpacing = (
  spacing: ResponsiveBreakpoints | ResponsiveBreakpoints[]
) => {
  const sizing = {
    // xsmall: 'pb-2',
    sm: 'space-x-2',
    md: 'space-x-3',
    // large: 'pb-12',
  };

  return resolveResponsiveProps(sizing, spacing);
};

const getMaxWidth = (maxWidth: IInlineProps['maxWidth']) => {
  switch (maxWidth) {
    case 'xl':
      return 'max-w-screen-xl mx-auto';

    default:
      return '';
  }
};

/**
 * Inline is used when laying out component in a inline way
 *
 * You can pass a break to collapse below if you want to change the alignment on mobiel/tablets/etc
 *
 * Accepts responsive props
 *
 * @param {*} { children, spacing, collapseBelow }
 * @return {*}
 */
export const Inline: FC<React.PropsWithChildren<IInlineProps>> = ({
  children,
  spacing = 'md',
  collapseBelow = 'md',
  maxWidth,
}) => {
  const _maxWidth = getMaxWidth(maxWidth);

  const display = getCollapseBelowBreakpoint(collapseBelow);
  const _spacing = getSpacing(spacing);

  return (
    <div className={`w-full ${_spacing} ${display} ${_maxWidth}`}>
      {children}
    </div>
  );
};

export default Inline;
