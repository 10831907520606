import React, { FunctionComponent } from 'react';
import { Button } from '@tapestry/weave';
import isEmpty from 'lodash/isEmpty';
import { useAppMediaQuery } from '@tapestry/weave';
import TaskListItemSkeleton from '../TaskListItemSkeleton/TaskListItemSkeleton';

interface IListWidgetProps {
  list: any[];
  isLoading: boolean;
  emptyMessage?: string;
  initialItemCount?: number;
  children: any;
  onButtonClick: () => void;
  showButtonCondition: boolean;
  isMore: boolean;
}

export const ListWidget: FunctionComponent<
  React.PropsWithChildren<IListWidgetProps>
> = ({
  list,
  isLoading,
  emptyMessage,
  children,
  onButtonClick,
  showButtonCondition,
  isMore,
}) => {
  const { isPhone } = useAppMediaQuery();

  if (isLoading) return <TaskListItemSkeleton count={3} />;

  return (
    <div>
      {isEmpty(list) ? (
        <div className="bg-gray-light rounded-lg p-10">
          <p className="text-gray-700">
            {emptyMessage || 'There are no tasks available'}
          </p>
        </div>
      ) : (
        <div className="text-center">
          <ul className="mb-5">{children}</ul>
          {showButtonCondition ? (
            <Button
              loading={isLoading}
              fullWidth={isPhone}
              status="basic"
              onClick={onButtonClick}
            >
              {isMore ? 'Show Less' : 'Show More'}
            </Button>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ListWidget;
