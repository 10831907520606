import { IShopSelector, IThreadV4Type } from '@tapestry/shared/graphql';
import { FC } from 'react';
import {
  calcShopOffline,
  calcMinutesSinceLastSynced,
  getReadableDuration,
} from './ShopOfflineBannerUtils';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { Modal, ModalState, Pill, Stack } from '@tapestry/weave';
import { Twist } from '@tapestry/types';
import { getTwistByKey } from '@tapestry/shared/utils';
import { useUIContext } from '@tapestry/shared/client';

interface IStoreOfflineModalProps {
  offlineShops: IThreadV4Type[];
  modalState: ModalState;
}

const ByLastSyncedAscending = (shopA: IThreadV4Type, shopB: IThreadV4Type) => {
  const lastSyncedA = calcMinutesSinceLastSynced(shopA);
  const lastSyncedB = calcMinutesSinceLastSynced(shopB);

  if (lastSyncedA === null) return 1;
  if (lastSyncedB === null) return -1;

  return lastSyncedA - lastSyncedB;
};

const StoreOfflineModal: FC<IStoreOfflineModalProps> = ({
  modalState,
  offlineShops,
}) => {
  return (
    <Modal state={modalState}>
      <Modal.Dialog>
        <Modal.Content>
          <Modal.Title>Currently Offline Stores</Modal.Title>

          <Stack spacing={'xxsmall'}>
            {offlineShops?.sort(ByLastSyncedAscending)?.map((shop) => {
              const lastSynced = calcMinutesSinceLastSynced(shop);
              const readableDurationSincSync = getReadableDuration(lastSynced);

              const shopLogo = getTwistByKey(Twist.Logo, shop)?.value;

              return (
                <div
                  key={shop?.id}
                  className="border-gray-border flex flex-row items-center justify-between rounded-md border p-2 text-lg font-normal sm:p-5"
                >
                  <div className="flex flex-row items-center">
                    <span className="min-w-6 mr-4 flex h-6 w-6 items-center justify-center overflow-hidden rounded-full bg-gray-200">
                      {shopLogo ? (
                        <img
                          src={shopLogo}
                          alt="shop's logo"
                          className="bg-gray-400"
                        />
                      ) : null}
                    </span>

                    <span title={shop?.title || ''} className="truncate">
                      {shop?.title || ''}
                    </span>
                  </div>

                  <p className="text-base">
                    Last Synced:{' '}
                    <time className="text-gray-text">
                      {readableDurationSincSync || 'unknown'}
                    </time>{' '}
                    ago
                  </p>
                </div>
              );
            })}
          </Stack>
        </Modal.Content>

        <Modal.Footer>
          <Modal.Button onClick={modalState.close}>Close</Modal.Button>
        </Modal.Footer>
      </Modal.Dialog>
    </Modal>
  );
};

export const GroupStoreOfflineBanner: FC<{
  groupId: string;
  query: IShopSelector;
}> = ({ groupId, query }) => {
  const [{ hasTopBannersShowing }, dispatch] = useUIContext();
  const storeOfflineModalState = useOverlayTriggerState({});
  const activeGroup = query.shopSelector?.groups.find(
    (group) => group.id === groupId
  );

  // filter by shop offline
  const offlineShops =
    activeGroup?.shops?.filter((shop) => {
      const lastSynced = calcMinutesSinceLastSynced(shop);
      const shopIsOffline = calcShopOffline(lastSynced);

      return shopIsOffline;
    }) || [];
  const hasOfflineShops = offlineShops.length > 0;

  // NOTE: MAX - this is the way I found I had to do it to not trigger a infinite loop. Do you have an idea of how to do this better? it's pretty ugly
  if (hasOfflineShops && !hasTopBannersShowing) {
    dispatch({
      type: 'TOGGLE_TOP_BANNERS',
      payload: true,
    });
  }

  if (!hasOfflineShops && hasTopBannersShowing) {
    dispatch({
      type: 'TOGGLE_TOP_BANNERS',
      payload: false,
    });
  }

  return hasOfflineShops ? (
    <>
      <div
        className={`flex flex-row flex-nowrap items-center justify-center  px-4 py-2 text-center text-white ${
          offlineShops.length < 3 ? 'bg-orange-tangerine-dark' : 'bg-red-dark'
        }`}
      >
        <p>Group has {offlineShops.length} offline shops</p>

        <button
          className="ml-2"
          title="Show offline stores"
          onClick={storeOfflineModalState.open}
          onKeyUp={({ key }) => {
            if (key === 'Enter') {
              storeOfflineModalState.open();
            }
          }}
        >
          <Pill
            size="xsmall"
            textStyle="capitalize"
            label={'learn more'}
            bgColor="bg-orange-dark"
          />
        </button>
      </div>

      {storeOfflineModalState.isOpen && (
        <StoreOfflineModal
          modalState={storeOfflineModalState}
          offlineShops={offlineShops}
        />
      )}
    </>
  ) : null;
};
