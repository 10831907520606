declare global {
  interface Window {
    dataLayer: any[];
  }
}

/**
 * Utility/wrapper function to track events.
 *
 * Currently using Google Tag Manager (13/11/2023)
 *
 * @example
 * trackEvent({
 *   event: 'DateSelectionEvent',
 *   category: 'hearbeat_date_selection',
 *   action: 'next_week',
 *   label: 'next week',
 * });
 *
 * For more about Tapestry s methodology
 * @see https://tapestry-ai.atlassian.net/wiki/spaces/TAP/pages/707198977/FE+Event+tagging
 */
export const trackEvent = ({
  event,
  category,
  action,
  label,
  value,
}: {
  event: string;
  category: string;
  action: string;
  label?: string;
  value?: number | string;
}) => {
  window?.dataLayer?.push({
    event,
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    value: value,
  });
};
