/**
 * Use to swap key names in an array with new values. For example when parsing for api variable format
 * @example
 * const newfilters = ObjectSwapKeys(filters, ['myOldKey'], {
    'my_api_var_name': mapped(filters.myOldKey),
  });
  * @params object - the object you want to operate on
  * @params keys - the key or list of keys to remove
  * @params newEntries - an object of new entries
 */
export const ObjectSwapKeys = (
  object: Record<string, any>,
  oldKey: string | string[],
  newEntries: Record<string, any>
) => {
  // delete the keys
  if (Array.isArray(oldKey)) {
    oldKey.forEach((key) => {
      delete object[key];
    });
  } else {
    delete object[oldKey];
  }

  // add new
  return { ...object, ...newEntries };
};
