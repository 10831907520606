import { OnboardingSlide } from '@tapestry/types';
import HeartbeatScreen1 from './HeartbeatScreen1.jpg';
import HeartbeatScreen2 from './HeartbeatScreen2.jpg';
import HeartbeatScreen3 from './HeartbeatScreen3.jpg';
import HeartbeatScreen4 from './HeartbeatScreen4.jpg';

export const HEARTBEAT_ONBOARDING_SLIDES: OnboardingSlide[] = [
  {
    header: 'Metrics in your pocket',
    text: 'Analyze and understand the metrics important to you and your shop. Learn more about Basket value or Gross margin and so much more coming soon.',
    image: HeartbeatScreen1 as unknown as string,
  },
  {
    header: 'Filter to find what you are looking for',
    text: 'Filter and find the information relevant to you. Drill down into the performance of a particular department or period of time and compare results from previous years to ensure things are tracking in the right direction.',
    image: HeartbeatScreen2 as unknown as string,
  },
  {
    header: 'Scan and receive instant product insights',
    text: 'Scan a product while on the shop floor and instantly bring up the previous 30 days sales insights. Dive deeper into a complete analysis if needed or keep on scanning.',
    image: HeartbeatScreen3 as unknown as string,
  },
  {
    header: 'Work smarter',
    text: 'Hank will do the hard work for you by providing recommendations to help drive impact and change in your store. Simply press “Add Task” to turn a recommendation into a Task in Tasks',
    image: HeartbeatScreen4 as unknown as string,
  },
];
