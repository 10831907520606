// *******************************************************
// FileUploader
// -------------------------------------------------------
// Component Description
// Simple file uploader,
// returns the file url
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import { useReducer } from 'react';
import { useAuthToken } from '@tapestry/shared/client';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Constant Imports
// -------------------------------------------
const initialUploadingState = {
  uploading: false,
  error: null,
};

// *******************************************
// Action / Utils / Functions Imports
// -------------------------------------------
const uploadingReducer = (state: UploaderState, action: ActionType) => {
  switch (action.type) {
    case 'UPLOADING_START':
      return { ...state, uploading: true, error: null };
    case 'UPLOADING_SUCCESS':
      return { ...initialUploadingState };
    case 'UPLOADING_FAILED':
      return { ...state, uploading: false, error: action.error };
    default:
      throw new Error('Invalid type provided to uploadingReducer.');
  }
};

// *******************************************
// Local Interface
// -------------------------------------------
type UploaderState = {
  uploading: boolean;
  error: string | null | undefined;
};

type ActionType = {
  type: 'UPLOADING_START' | 'UPLOADING_SUCCESS' | 'UPLOADING_FAILED';
  error?: string;
};

type FileUploaderProps = {
  onUploaded: (fileUrl: string) => void;
  buttonLabel?: string;
};

// *******************************************
// Main Component
// -------------------------------------------
export const FileUploader = ({
  onUploaded,
  buttonLabel,
}: FileUploaderProps) => {
  const accessToken = useAuthToken();
  const [{ uploading, error: localError }, dispatch] = useReducer(
    uploadingReducer,
    initialUploadingState
  );

  const uploadFileToServer = (file: File) => {
    if (file) {
      dispatch({ type: 'UPLOADING_START' });

      const formData = new FormData();
      formData.append('file', file, file.name.replace(/[^a-zA-Z0-9-_.]/g, ''));

      fetch(`${process.env.NEXT_PUBLIC_API_REST_URL}/api/upload`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      })
        .then((res) => {
          if (!res.ok) throw new Error('File could not be uploaded');

          return res.json();
        })
        .then((data) => {
          onUploaded(data.url);
          dispatch({ type: 'UPLOADING_SUCCESS' });
        })
        .catch((err) => {
          dispatch({
            type: 'UPLOADING_FAILED',
            error: err?.response?.data?.message || err.message,
          });
        });
    }
  };

  return (
    <label
      htmlFor="blockFile"
      className={uploading ? 'cursor-wait' : 'cursor-pointer'}
    >
      <input
        type="file"
        id="blockFile"
        className="hidden"
        accept=".png,.jpg,.jpeg,.gif"
        onChange={(e) => {
          if (!e.target.files?.length) return;
          uploadFileToServer(e?.target?.files?.[0]);
        }}
      />

      {uploading ? (
        <p className={`text-gray-text`}>Uploading...</p>
      ) : (
        <p className="text-gray-text active:text-gray-800">
          {buttonLabel || 'Upload file'}
        </p>
      )}

      {localError !== null ? (
        <div className="text-red inline-flex items-center">
          <i className="fas fa-exclamation-triangle fa-w-16" />
          <p className="ml-2">Upload failed ({localError})</p>
        </div>
      ) : null}
    </label>
  );
};

export default FileUploader;
