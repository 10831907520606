import { trackEvent } from '@tapestry/shared/utils';
import { cloneElement, FC } from 'react';
import { twMerge } from 'tailwind-merge';

const VARIANT_STYLES = {
  // * FAB
  primary:
    'size-14 bg-gray-100 border-gray-300 focus:bg-gray-300 hover:bg-gray-300 p-4 text-blue',
  // * small FAB - MUI has it at size 10
  secondary:
    'size-12 bg-blue border-blue p-3 hover:bg-blue-dark focus:bg-blue-dark',
  // * small FAB - MUI has it at size 10
  tertiary:
    'size-12 border-white bg-gray-100 border-gray-300 focus:bg-gray-300 hover:bg-gray-300 p-3',
} as const;

type Variant = keyof typeof VARIANT_STYLES;
interface IWidgetBarButtonProps {
  title: string;
  onPress: () => void;
  variant?: Variant;
  icon: React.ReactElement;
  /**
   * used for event tracking
   */
  action?: string;
}

// * Note: when implementing extended FABs, the scroll behaviour should be that the extended FABs can collapse into a FAB on scroll and expand on reaching the bottom of the view.
export const WidgetBarButton: FC<IWidgetBarButtonProps> = ({
  title,
  onPress,
  variant = 'tertiary',
  icon,
  action = null,
}) => {
  const styles = VARIANT_STYLES[variant];

  const Icon = cloneElement(icon, { className: 'size-6' });

  const handleOnPress = () => {
    trackEvent({
      event: 'ButtonClickEvent',
      category: 'button_clicked',
      action: `FAB-${action || title}`,
      label: title,
    });

    onPress();
  };

  return (
    <button
      type="button"
      title={title}
      className={twMerge(
        styles,
        'grow-on-hover ring-blue ease z-50 flex items-center justify-center rounded-full border shadow-md transition-all duration-200 focus:outline-none focus:ring-2'
      )}
      onClick={handleOnPress}
      onKeyUp={({ key }) => {
        if (key === 'Enter') {
          handleOnPress();
        }
      }}
    >
      <p className="sr-only">{title}</p>
      {Icon}
    </button>
  );
};
