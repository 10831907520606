import * as React from 'react';
import { ROUTE_PATHS } from '@tapestry/shared/constants';
import {
  DepartmentsIcon,
  FolderIcon,
  ManufacturerIcon,
  ProductIcon,
  SpacesIcon,
  SupplierIcon,
  IIconProp,
  UsersIcon,
  LocationCircleIcon,
} from '@tapestry/shared/icons';
import { LocationPathname, ObjectValues, THREAD_TYPE } from '@tapestry/types';

export const mapThreadTypeToThreadIcon = (
  type: THREAD_TYPE
): React.FC<IIconProp> => {
  const iconPerTypes = {
    [THREAD_TYPE.SHOP]: null,
    [THREAD_TYPE.DEPARTMENT]: DepartmentsIcon,
    [THREAD_TYPE.SPACE]: SpacesIcon,
    [THREAD_TYPE.CATEGORY]: FolderIcon,
    [THREAD_TYPE.SUPPLIER]: SupplierIcon,
    [THREAD_TYPE.COLLECTION]: ManufacturerIcon,
    [THREAD_TYPE.PRODUCT]: ProductIcon,
    [THREAD_TYPE.DEMOGRAPHIC]: UsersIcon,
    [THREAD_TYPE.POSTCODE]: LocationCircleIcon,
  };

  return iconPerTypes[type] ?? FolderIcon;
};

const routesToThreadTypeMapper: {
  [k: string]: { type: THREAD_TYPE; label: string; placeholder?: string };
} = {
  [ROUTE_PATHS.products]: { type: THREAD_TYPE.PRODUCT, label: 'products' },
  [ROUTE_PATHS.departments]: {
    type: THREAD_TYPE.DEPARTMENT,
    label: 'departments',
  },
  [ROUTE_PATHS.spaces]: {
    type: THREAD_TYPE.SPACE,
    label: 'spaces',
  },
  [ROUTE_PATHS.categories]: {
    type: THREAD_TYPE.CATEGORY,
    label: 'categories',
  },
  [ROUTE_PATHS.manufacturers]: {
    type: THREAD_TYPE.COLLECTION,
    label: 'collections',
  },
  [ROUTE_PATHS.suppliers]: {
    type: THREAD_TYPE.SUPPLIER,
    label: 'suppliers',
  },
  [ROUTE_PATHS.tasks]: {
    type: THREAD_TYPE.TASK,
    label: 'tasks',
    placeholder: 'Search tasks name...',
  },
} as const;

export const mapAppletRoutesToThreadType = (
  pathname: LocationPathname
): ObjectValues<typeof routesToThreadTypeMapper> | null => {
  return routesToThreadTypeMapper[pathname] ?? null;
};

export const mapThreadTypeToRoute = (threadType: THREAD_TYPE): string => {
  if (!threadType) return '';

  const mapperList = Object.entries(routesToThreadTypeMapper);
  const matchingEntry = mapperList.find(
    (kvpair) => kvpair[1].type === threadType
  );

  return matchingEntry?.[0] ?? '';
};
