import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * Floating area to add widget to any screen
 */
export const WidgetBar: FC<
  React.PropsWithChildren<{ hide?: `${string}:hidden` }>
> = ({ children, hide = '' }) => {
  return (
    <div
      className={twMerge(
        'fixed bottom-0 right-0 z-10 m-4 flex flex-col items-center space-y-4',
        hide
      )}
    >
      {children}
    </div>
  );
};

export default WidgetBar;
