import React, { FC } from 'react';
interface IContentProps {
  children: React.ReactNode;
}

export const Content: FC<IContentProps> = ({ children }) => {
  return (
    <main className="px-4 sm:px-8 pb-8 space-y-2 sm:space-y-4">{children}</main>
  );
};
