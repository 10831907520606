import React, { ReactElement } from 'react';
import { ApolloError } from '@apollo/client';
import { SimpleThreadListItem } from '../../SimpleThreadListItem/SimpleThreadListItem';
import { IThreadV4Type } from '@tapestry/shared/graphql';
import { useAppMediaQuery } from '@tapestry/weave';
import Skeleton from 'react-loading-skeleton';
import { Button } from '@tapestry/weave';
import isEmpty from 'lodash/isEmpty';
import { useSlideOver } from '@tapestry/shared/client';
import { Inbox } from '@poc/ui';
import { useFlags } from 'launchdarkly-react-client-sdk';

const ListItemLoadingState = () => {
  const { isPhone } = useAppMediaQuery();

  return (
    <>
      {[1, 2, 3, 4].map((item) => (
        <li
          className={`flex h-32 list-none overflow-hidden rounded-md bg-white md:h-24`}
          key={item}
        >
          <div className="h-full w-16 bg-gray-200 md:w-20" />
          <div className="flex-grow p-4 text-left sm:flex sm:items-center sm:justify-between">
            <div>
              <h6 className="flex w-full items-start justify-between">
                <span className="w-full text-base font-bold md:text-lg">
                  <Skeleton height="1.25rem" width={isPhone ? '75%' : 280} />
                </span>
                <div className="sm:hidden">
                  <Skeleton width={30} height="1.5rem" />
                </div>
              </h6>
            </div>

            <div className="mt-4 flex items-end justify-between sm:mt-0 sm:items-center sm:space-x-2">
              <div className="flex flex-wrap items-center">
                <Skeleton height="1rem" width={63} className="mr-2" />
                <Skeleton height="1rem" width={63} />
              </div>

              <Skeleton circle height="2rem" width="2rem" />

              <div className="hidden sm:block">
                <Skeleton width={35} height="1.5rem" />
              </div>
            </div>
          </div>
        </li>
      ))}
    </>
  );
};

interface ISimpleThreadsList {
  listData: IThreadV4Type[];
  isLoading?: boolean;
  error?: ApolloError | boolean;
  showMax?: number | undefined;
  onShowMore?: () => void;
  showMoreButtonLabel?: string;
  hideShowMoreButton?: boolean;
}

/**
 * SimpleThreadsList
 * Displays a list of threads using {@link SimpleThreadListItem}
 */
export const SimpleThreadsList = ({
  listData = [],
  isLoading = false,
  error = false,
  showMax = undefined,
  onShowMore,
  showMoreButtonLabel = 'Show More',
  hideShowMoreButton = false,
}: ISimpleThreadsList): ReactElement => {
  const { isPhone } = useAppMediaQuery();

  const { hearbeatInbox: hasHeartbeatInboxFlag } = useFlags();
  const { open: openInbox, close: closeInbox } = useSlideOver(Inbox);

  const getMessageButtonClickHandler = React.useCallback(
    (product: any) => {
      if (!hasHeartbeatInboxFlag) {
        return;
      }

      return () => {
        openInbox({
          onClose: closeInbox,
          messageThreadId: product?.id,
          messageThreadTitle: product?.title || '',
        });
      };
    },
    [closeInbox, hasHeartbeatInboxFlag, openInbox]
  );

  return (
    <>
      <ul className="space-y-1">
        {isLoading && <ListItemLoadingState />}

        {error && (
          <div className="text-gray-text">
            <p className="text-2xl font-bold tracking-wider">
              Sorry an error has occured
            </p>
            <p className="mb-8">Please try again in a moment.</p>
          </div>
        )}

        {isEmpty(listData) && !isLoading && !error && (
          <div className="text-gray-text mt-4 sm:mt-6">
            <h2 className="text-2xl font-bold">No threads found</h2>
          </div>
        )}

        {listData?.slice(0, showMax)?.map((item) => (
          <SimpleThreadListItem
            key={item?.id}
            item={item}
            isLoading={isLoading}
            onMessageButtonClick={getMessageButtonClickHandler(item)}
          />
        ))}
      </ul>

      {showMax && listData && !hideShowMoreButton ? (
        <div className="text-center">
          <Button
            loading={isLoading ?? false}
            fullWidth={isPhone ? true : false}
            status="basic"
            onClick={onShowMore}
            className="mt-4"
          >
            {showMoreButtonLabel}
          </Button>
        </div>
      ) : null}
    </>
  );
};
