import { useEffect } from 'react';
import { BooleanParam, useQueryParam } from '@tapestry/shared/utils';
import useDevMode from '../use-dev-mode/use-dev-mode';

export const useWatchForURLDevMode = () => {
  const [devModeQP] = useQueryParam('dev', BooleanParam);
  const [_, setDevMode] = useDevMode();

  useEffect(() => {
    if (devModeQP === null || devModeQP === undefined) return;
    setDevMode(devModeQP);
  }, [devModeQP, setDevMode]);
};

export default useWatchForURLDevMode;
