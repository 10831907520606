import { InMemoryCacheConfig } from '@apollo/client';
import {
  IOrganisationTag,
  IThreadSimpleType,
  IUser,
} from '@tapestry/shared/graphql';
import { mergeArrayByField } from './merge-array-by-field';

export const CACHE_CONFIG: InMemoryCacheConfig = {
  typePolicies: {
    DataValueId: {
      // * id can sometime be null
      keyFields: ['id', 'name', 'value'],
    },
    // * caches TwistType as part of the parent object (`thread`) instead of it's own disctind cache entry. Because a given twist as the same ID across diferent thread, this would cause mismatch of twists and thread.
    TwistType: {
      keyFields: false,
    },
    ThreadV4Type: {
      // * makes it a bit easier to look thread up in the cache
      keyFields: ['threadType', 'id'],
    },
    ShopByThreadShopType: {
      // disabling normalization for this type because it's not a unique entity
      keyFields: false,
    },
    CustomHeartbeatListType: {
      fields: {
        listItems: {
          merge(_, incoming: IThreadSimpleType[]) {
            return incoming;
          },
        },
      },
    },
    InfiniteThreadSimpleType: {
      keyFields: [],
      fields: {
        items: {
          merge: mergeArrayByField<IThreadSimpleType>('id'),
        },
      },
    },
    InfiniteUserSimpleType: {
      keyFields: [],
      fields: {
        items: {
          merge: mergeArrayByField<IUser>('id'),
        },
      },
    },
    InfiniteTagsSimpleType: {
      keyFields: [],
      fields: {
        items: {
          merge: mergeArrayByField<IOrganisationTag>('id'),
        },
      },
    },
  },
};
